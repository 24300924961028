<template>
    <div class="lunbo faguang">
        <el-carousel height="200px" arrow="always">
            <el-carousel-item v-for="(recom,idx) in dataList" :key="idx" :style="{backgroundImage:'url('+files.myblogImageUrl+recom.articleCover+')'}">
                <!-- <img src="../../assets/logo/R-C.jpg" alt=""> -->
                <!-- <h3 style="color: black;" class="small">{{ item }}</h3> -->
				<div class="lunbo-zi">
					<span class="lunbo-tuijian">推荐</span>
					<span v-if="createTimeifNew(recom.createTime)" class="lunbo-recently">最近</span>
					<!-- <span class="lunbo-recently">最近</span> -->
					
					<div>
						<span class="lunbo-zi-title"  @click="toArticleIndex(recom.id,recom.articleText,recom.articleTitle)">
							{{ recom.articleTitle }}							
						</span>
						<span>
							<i  style="font-size: 12px;" class="iconfont icon-rili1"> {{recom.createTime}}</i>								
						</span>
					</div>
					
					
				</div>
								
            </el-carousel-item>
        </el-carousel>
    </div>

</template>

<script>
import { getRecommendArticle } from '../../../api/article'

export default {
    name:'LunBoOne',

    data(){
		return{
			// bgImage:require("../../../assets/logo/meixi.jpg"),
			dataList:[],
		}
	},
	methods:{
      getRecommendList(){
        getRecommendArticle().then((res)=>{
			this.dataList=res.data
          	// console.log(res.data)
		})
      },

	  createTimeifNew(aTime){
		var diffValue=new Date(aTime)
            // console.log("时间撮",diffValue)
            var minute = 60*1000;
            var hour = minute * 60;
            var day = hour * 24;
            var nowTime = (new Date()).getTime(); //获取当前时间戳 
            // console.log("new ",nowTime)                  
            var ShiJianCha = nowTime - diffValue;    
            var dayC = ShiJianCha / day;
			if(dayC>2){
                return false;
            }
            return true;  
	  	},

		toArticleIndex(id,text,isTitle){
			console.log(id)
			this.$router.push({
                path:'/article',
				query:{
					id:id,
					title:isTitle,
					B:this.$Base64.encode(text),
					t:Date.now()
				}, 
            })
		}
    },
    created(){
      this.getRecommendList()
    }
}
</script>

<style>
.lunbo{
	height: 200px;
	width: 100%;
	/* background-color: rgb(45, 128, 201); */
}
/* 背景 */
.lunbo .el-carousel__item{
	background-repeat: no-repeat;
	background-size: cover!important;
    background-position: center!important;
}

.lunbo .lunbo-zi{
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #5e5e5e2a;
	display:flex;
	justify-content: center;
    align-items: center;
	color: #fff;
}

.lunbo .lunbo-zi::before{
	content: "";
	/* border-radius: 10px; */
	position: absolute;
	z-index: -1;  
	width:100%;
	height: 100%;
	background-color: var(--blog-gonxiang-tm);
	background-size: 100% 100%  ;         
	background-position: center;
	background-size: cover;
	opacity: 1; 
}

.lunbo .lunbo-zi>div{
	padding-left: 50px;
	padding-right: 50px;
	/* background-color: #747474; */
	display: grid;
}
.lunbo .lunbo-zi>div span{
	padding: 5px;
	text-align: center;
}

.lunbo-zi-title{
	font-size: 28px;
	display: -webkit-box;
    -webkit-box-orient: vertical;
	line-height:45px;
    -webkit-line-clamp:1;
    /* max-height: 5rem; */
    overflow: hidden;
	letter-spacing:2px;
	cursor: pointer;
}
.lunbo .lunbo-zi:hover .lunbo-zi-title{
	transition: .5s;
	color:var(--app-allColor);
}
.lunbo .el-carousel{
	position: relative;
	border-radius: 10px;
}
.lunbo .el-carousel__indicators {
	display: flex;
	/* background-color: #646464; */
	position:absolute;
	/* width: 120px; */
	top: 90%;
	/* right: 10%; */

}
.lunbo .el-carousel__indicator{
	padding: 0;
	height: 15px;
	width: 15px;
	/* background-color: aqua; */
}
.lunbo .el-carousel__indicators--horizontal{
	padding:0;
}

.lunbo .el-carousel__indicator--horizontal{
	margin: 0 3px;
}

.lunbo .el-carousel__indicator--horizontal button{
	height: 15px;
	width: 15px;
	background:#747474;
	/* background: var(--app-allColor); */
	border-radius: 15px;
	z-index: 1;
	padding: 0;
	margin: 0;
}
.lunbo .el-carousel__indicator--horizontal.is-active{
	height: 15px;
	width: 15px;
	padding: 0 5px  0 5px;
	/* background: var(--app-allColor); */
	background: #747474;
	border-radius: 5px;
	transition: all 200ms ease 0ms;
}

.el-carousel__item h3 {
    /* color: #475669; */
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.lunbo-tuijian{
	text-align: center;
	top: 0;
	left: 10%;
	letter-spacing: 1.5px;
	position: absolute;
	padding: 4px 10px;
	color: #fff;
	font-size: 10px;
	border-radius:0 0 5px 5px;
	/* background-color:var(--app-allColor); */
	background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
	/* background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 0%, rgba(253,92,46,1) 48%, rgba(252,176,69,1) 100%); */
}
.lunbo-recently{
	text-align: center;
	top: 0;
	right: 10%;
	letter-spacing: 1.5px;
	position: absolute;
	padding: 4px 10px;
	color: #fff;
	font-size: 10px;
	border-radius:0 0 5px 5px;
	/* background-color:var(--app-allColor); */
	background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 0%, rgba(253,92,46,1) 48%, rgba(252,176,69,1) 100%);
}
</style>