<template>
    <div class="liuyan-wall">
        <!-- <div class="lyw-bj"></div> -->

        <gongxiang-top :togonggongTop="{page_title:'留言墙',page_bg:webSetting.webLybgi,msg:''}"></gongxiang-top>

        <bian-li-tie :parentdata="noteList"></bian-li-tie>
        <!-- <div class="wall-height">     
            <div v-for="(item,index) in noteList" :key="index" class="note-one"
                :style="{backgroundColor :`${item.note_bgc}`}">
                <div class="note-top">
                    <div class="nt-zuo">
                        <div class="zuo-bt">
                            <span>{{ item.note_title }}</span>
                        </div>
                        <div class="zuo-sj">
                            <span>{{item.note_time}}</span>
                        </div>
                    </div>
                    <div style="flex: 1;">
                    </div>
                    <div class="nt-you">
                        <div class="you-one">
                            <div class="you-bianji">
                                <img src="../../assets/logo/pencil.png" alt="">
                            </div>
                            <div class="you-sc">
                                <img src="../../assets/logo/clear.png" alt="">
                            </div>
                        </div>
                        <div class="you-two">
                            <span> ip：广东</span>
                        </div>
                       
                    </div>
                </div>
                <div class="note-text">
                    <div class="text-zi">
                        <span> {{ item.note_text }}</span>
                    </div>
                </div>
            </div>

        </div> -->

        <!-- <write-liu-yan></write-liu-yan> -->
        <div class="liuyan-but"  @click="openWrite">
            <i class="iconfont icon-yongyan"></i>
        </div>
       
        <el-dialog
            :visible.sync="centerDialogVisible"
            :show-close="false"
         
            :append-to-body="true"
            center>
            <div class="write-liuyan"  :style="{backgroundColor :`${liuyan.bgcolor}`}">
                <div class="wly-top">
                   
                </div>
                <div class="wly-body" :style="{'--zi-color':liuyan.fontcolor}">
                    <!-- <textarea :style="{color :`${liuyan.fontcolor}`}"
                        placeholder="写下你的留言吧。。。"
                        v-model="liuyan.messageContent">
                    </textarea> -->
                    <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="liuyan.messageContent">
                    </el-input>
                </div>
                <div class="wly-footer">
                    <div class="wlyf-one">
                        <el-row>
                            <el-col :span="12"><div class="grid-content bg-purple">
                                <span style="display: flex;">
                                    <img v-if="liuyan.messageQq" :src="'https://q1.qlogo.cn/headimg_dl?dst_uin='+liuyan.messageQq+'&spec=100'">
                                    <img v-else src="../../assets//logo/head.png" alt="">
                                    
                                    <el-input size="mini" v-model="liuyan.messageQq" placeholder="输入qq回车即可获取头像昵称" @keyup.enter.native="getQQinf"></el-input>                                                         
                                </span>
                            </div></el-col>
                            <el-col :span="12"><div class="grid-content bg-purple-light">
                                
                                <div style="font-size: 12px;font-weight: 300;text-align: center;">
                                    归属地：
                                    <span v-if="!liuyan.ipSource" class="el-icon-loading"></span>
                                    <span v-else>
                                        {{ liuyan.ipSource }}
                                    </span>
                                </div> 
                            </div></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><div class="grid-content bg-purple">
                                <span style="display: flex;">
                                    <el-checkbox v-model="checked">匿名</el-checkbox>
                                    <el-input size="mini" v-if="!checked"   placeholder="请输入内容" v-model="liuyan.nickname" :disabled="false"></el-input>  
                                    <!-- <el-input size="mini" v-else v-model="liuyan.nickname"  placeholder="留言者(建议不用真名)"></el-input>  -->
                                </span>
                            </div></el-col>
                            <el-col :span="12"><div class="grid-content bg-purple-light">
                                <div style="display: flex;align-items: center;justify-content: center;">
                                        <!-- <div>
                                            <span style="line-height: 20px;font-size: 14px;">背景颜色:</span>
                                            <el-color-picker v-model="liuyan.bgcolor" show-alpha></el-color-picker>
                                        </div> -->
                                        <!-- <div style="display: flex;"> -->
                                            <!-- <span style="line-height: 20px;font-size: 14px;">背景颜色</span> -->
                                            <el-select v-model="liuyan.bgcolor" placeholder="请选择颜色" size="mini" >
                                                <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                                </el-option>
                                            </el-select>
                                        <!-- </div> -->
                                    
                                        <!-- <div style="margin-left: 5px;">
                                            <span style="line-height: 20px;font-size: 14px;">字体颜色:</span>
                                            <el-color-picker v-model="liuyan.fontcolor"></el-color-picker>
                                        </div>  -->
                                </div>
                            </div></el-col>
                        </el-row>
                    </div>    
                    <div style="padding: 5px; display: flex;justify-content: center;align-items: center;">
                        <el-button size="mini" @click="centerDialogVisible = false">取 消</el-button>
                        <el-button  size="mini"  @click="writeLiuyan">确 定</el-button>
                    </div>
                </div>
                
                
            </div>
           
        </el-dialog>
    </div>
    
</template>

<script>
// import { clamp } from '@vueuse/shared';
import GongxiangTop from './gonxiangtop/GongxiangTop.vue'
import BianLiTie from './liuyanwall/BianLiTie.vue'
import {addMessage, getMessageIp, getMessageList} from '@/api/message'
import {mapGetters} from 'vuex';
import axios from 'axios'

export default {
  components: { BianLiTie, GongxiangTop },
    name:'LiuYanWall',
    computed:{
        ...mapGetters([
            'webSetting'
        ]),
    },
    data(){
        return{
            noteList:'',
            
            centerDialogVisible: false,           
            liuyan:{               
                messageQq:"",
                ipSource:'',
                messageContent:'',
                bgcolor:'#91D2FC',
                nickname:"",
                fontcolor:'#636363'
            },
            checked: false,

            options: [{
                value: '#FBF7C0',
                label: '浅黄'
                }, {
                value: '#DCFAF0',
                label: '浅绿'
                }, {
                value: '#DAF3FE',
                label: '浅蓝'               
                }, {
                value: '#FFE9FF',
                label: '浅紫'
                }, {
                value: '#FCEA76',
                label: '黄色'
                }, {
                value: '#83E5D0',
                label: '青色'
                }, {
                value: '#91D2FC',
                label: '蓝色'
                }, {
                value: '#EBA2F8',
                label: '紫色'
                }],
        }
    },
 
    methods: {  
        liuyanList(){
            getMessageList().then((res)=>{
                this.noteList=res.data
                // console.log(res.data)
            })
        },
        openWrite(){
            this.centerDialogVisible = true
            getMessageIp().then((res)=>{
                // console.log("oip",res.data.cityCode)
                this.liuyan.ipSource=res.data.address
        })
        },
        writeLiuyan(){
            if(this.checked) {
                 this.liuyan.nickname='匿名'
            }    
            if(!this.liuyan.messageContent){
                return  this.$message({
                    message:"内容不能为空",
                    type: 'warning',
                });
            }  
         
            if(!this.liuyan.messageQq){
                return this.$message({
                    message: 'qq不能为空',
                    type: 'warning'
                });
            } 
            // console.log('留言',this.liuyan)
            addMessage(this.liuyan).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });                    
                    this.liuyanList()
                    this.centerDialogVisible = false
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                }
            })
        },
        getQQinf(){
            axios({         
                url:`https://api.qjqq.cn/api/qqinfo?qq=${this.liuyan.messageQq}`,
                method: 'GET',
                data: {
                    output:'jsonp' 
                },
                dataType:"jsonp",
            }).then(
                res =>{
                    // console.log("qq信息：",res.data)
                    this.liuyan.nickname=res.data.name
                    
                // this.qqInform=res.data 
                } 
            )
        }
    },
    created(){
        this.liuyanList()
    }
}
</script>

<style>
.liuyan-wall{
    /* padding: 40px; */
    /* width: 100%; */
    /* display: block; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* padding-left: 10px;
    padding-right: 10px; */
    /* height: 100%; */
    margin: 0 auto;
    /* padding-top: 60px; */
    z-index: 6;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-image: repeating-linear-gradient(90deg,var(--blogBgColorA),var(--blogBgColorA) 2px,var(--blogBgColorB) 2px,var(--blogBgColorB) 4px); */

    position: relative;
    /* margin-left: -5%; */
    /* min-height: 100vh; */
    /* width: calc(110% ); */
    /* position:absolute; */
    /* position: relative; */
    /* background-color: rgb(255, 255, 255); */
    /* padding-top: 80px; */

}

/* .lyw-bj{  
    top: -40vh;
    position: absolute;
    height: 40vh;
    width:100%;
    background-color: black;
} */
.el-popper[x-placement^=bottom] .popper__arrow {
    display: none;
}
.write-liuyan .wly-body textarea{
    background: #ffffff00;
    height: 230px;
    box-shadow: none;
    /* padding: 10px; */
    color: var(--zi-color);
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
}
.write-liuyan .wly-body textarea::placeholder {  
    color: var(--zi-color);
}
.el-scrollbar__wrap::-webkit-scrollbar{
    width: 0px;
    /* border-radius: 5px; */
    background-color: rgba(43, 43, 43, 0); 
}

.liuyan-but{
    position: fixed;
    bottom: 219px;
    right: 10px;
    z-index: 99;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	border: 1px solid  #7474748e;
	margin-top: 10px;
	background-color: rgba(0, 0, 0, 0);
	color: rgba(22, 22, 22, 0.418);
	cursor: pointer;
	background: var( --card-bg);
	color: var(--font-color);
	padding: 5px;
    transition: .3s;
	border-radius: 25px;
}
.liuyan-but:hover{
    transition: .3s;
    /* width: 50px; */
    background-color: var(--app-allColor);
    color:#fff;
}
/* 饿了么对话框自定义 */
.el-dialog__wrapper .el-dialog--center  {
    background-color: #2c2c2c00;
}
.el-dialog__wrapper .el-dialog--center .el-dialog__body {
    padding: 0px;
    background-color: #fcfcfc48;
}
.el-dialog__wrapper  .el-dialog__header {
    display: none;
}
.el-checkbox{
    padding: 5px;
}

.write-liuyan{
    padding: 10px;
    /* min-height: 350px; */
    min-width:  calc(100% - 20px);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);  
    /* border: 1px solid #bfbfbf; */
    /* background-color: LightGoldenRodYellow; */
    color: rgb(56, 56, 56);
    text-decoration: none;
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    -ms-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
    /* background-color: aqua; */
}
.write-liuyan .wly-footer{
    /* height: 120px; */
    /* width: calc(100% - 20px); */
    width: 100%;
    background-color: rgba(255, 255, 255, 0.479);
}
.write-liuyan .wly-footer .wlyf-one{
    /* height: 80px; */
    width: 100%;
    /* background-color: aqua; */
    color: #4e4e4e;

}

.wlyf-one img{
    padding: 0 5px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}
.grid-content{
    padding: 5px;
}


</style>