<template>
    <div class="LoadingTwo-box">
        <div style="color:var(--font-1-color);">加载中</div>
		<div class="LoadingTwo"></div>
	</div>
</template>

<script>
export default {
    name:'LoadingTwo',
}
</script>

<style>
.LoadingTwo-box{
    height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.LoadingTwo {
	margin: 20px;
    background: var(--app-allColor);
    position: relative;
    -webkit-animation: LoadingTwodh 1s ease-in-out infinite;
    animation: LoadingTwodh 1s ease-in-out infinite;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    width: .25em;
    height: .5em;
}

.LoadingTwo:after,
.LoadingTwo:before {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
    -webkit-animation: inherit;
    animation: inherit;
}

.LoadingTwo:before {
    right: .5em;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.LoadingTwo:after {
    left: .5em;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

@-webkit-keyframes LoadingTwodh {
    0%,
    100% {
        box-shadow: 0 0 0 var(--app-allColor), 0 0 0 var(--app-allColor);
    }
    50% {
        box-shadow: 0 -.25em 0 var(--app-allColor), 0 .25em 0 var(--app-allColor);
    }
}

@keyframes LoadingTwodh {
    0%,
    100% {
        box-shadow: 0 0 0 var(--app-allColor), 0 0 0 var(--app-allColor);
    }
    50% {
        box-shadow: 0 -.25em 0 var(--app-allColor), 0 .25em 0 var(--app-allColor);
    }
}
</style>