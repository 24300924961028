<template>
    <div>
        <div class="set-bg" id="setbgId" @click="setBgDialogVisible=true">
            <span class="iconfont icon-tupian1">
			</span> 
        </div>
        <el-dialog
            title="提示"
            top="10vh"
            :visible.sync="setBgDialogVisible"
            :append-to-body="true">
            <div class="setbg-zi  faguang" id="faguang" style="background: var(--app-bgColor);">
                <div class="setbg-zi-top">
                    <div class="szt-title">
                        <span>壁纸</span>  

                        <button @click="bghuifu()"><i class="el-icon-refresh-right" ></i>恢复</button>                     
                    </div>
                    <div class="szt-zs">
                        <div class="el-upload__tip" slot="tip">
                            点击对应图片即可设置为背景壁纸，有效期为一天！<br>
                            注:设置背景后有些背景颜色会与字体的颜色较为接近,导致影响看不清。可以右上角恢复原主题
                        </div>
                        <!-- <span>点击对应图片即可设置为背景壁纸，有效期为一天！</span> -->
                    </div>
                </div>
                <div class="setbg-zi-body">
                    <div>
                        <div v-for="(img,idx) in bgImageList" :key="idx" style="display: grid;margin: 5px;">
                            <!-- <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
                                <div slot="content">
                                    <img :src="img"  alt="" style="width:250px;"> 
                                </div>
                                <img :src="img" alt="">
                            </el-tooltip> -->
                            <img v-lazy="qiNiuLink+img.xcImages" alt="" @click="setBgImage(img.xcImages)">
                            
                            <span style="font-size: 12px;">{{ img.xcText }}</span>
                        </div>
                        
                    </div>
                   
                </div>
                <div v-if="bgImageList.length>0" 
                    style="padding: 20px 10px 10px 0;left:10px;margin:10px">
                        <el-pagination
                            background
                            @current-change="handleCurrentChange"
                            :current-page="pageNum"
                            :page-size="pageSize"
                            :pager-count="11"
                            layout="prev, pager, next"
                            :total="total">
                        </el-pagination>
                    </div>
            </div>
            <!-- <span>需要注意的是内容是默认不居中的</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setBgDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="setBgDialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>
    </div>
    
</template>

<script>
import {mapGetters} from 'vuex';
// import { getAllXiangCeByType } from "@/api/xiangce"
import request from '../utils/requests';
export default {
    name:'SetBg',
    data(){
        return{
            setBgDialogVisible:false,

            bgImageList:[
                // require("../assets/image/1.jpg"),
                // require("../assets/image/2.jpg"),
                // require("../assets/image/3.jpg"),
                // require("../assets/image/5.jpg"),
                // require("../assets/image/10.jpg"),
                // require("../assets/image/7.jpg"),
            ],

            total:0,	
            pageNum:1,
            pageSize:12
        }
    },
    computed:{
      ...mapGetters([
          'blogBg'
      ]),
    }, 
    methods:{
        // 移除壁纸
        removebg(){
            localStorage.removeItem("blogBg")
            document.getElementById("beijin").style.background="var(--app-bgColor)"
            // document.getElementById("beijin").style.backgroundImage="repeating-linear-gradient(90deg,var(--blogBgColorA),var(--blogBgColorA) 2px,var(--blogBgColorB) 2px,var(--blogBgColorB) 4px)" 
            // document.getElementById("faguang").style.background="var(--card-bg)";
        },
        // 设置壁纸
        successbg(){          
            document.getElementById("beijin").style.backgroundImage="url("+this.qiNiuLink+this.blogBg.value+")" 
        },

        setBgImage(row){
            // console.log(row) 
            // 设置壁纸和时间        
            this.$store.commit('setBlogBg',{expiry: Date.now() + 720* 60 * 1000,value:row})
            
            if(this.blogBg==null){
                this.$message({
                    message:"设置壁纸失败",
                    type: 'error',
                    duration:1000,
                });
            }else{
                this.successbg()
                this.$message({
                    message:"设置壁纸成功，有效期一天，过期自动恢复原主题",
                    type: 'success',
                    customClass: "focus-message",
                    duration:3000,                   
                })
            }
                     
        },

        bghuifu(){  
        
            this.removebg()
            clearInterval(this.timer);
            this.$message({
                message:"已恢复原主题！",
                type: 'success',
                duration:1000,
            }); 
            // sessionStorage.removeItem("blogBg")
            // document.getElementById("beijin").style.background="var(--app-bgColor)"
            // sessionStorage.removeItem("blogBg")
            // document.getElementById("blog-boby").style.background="var(--app-bgColor)"
            // document.getElementById("danban").style.background="var(--app-bgColor)"                         
        },

        jiance(){
            // if(this.$store.state.skin=='dark'){
            //         document.getElementById("blog-boby").style.background="rgba(53, 53, 53, 0.39)"
            // }else{
            //         document.getElementById("blog-boby").style.background="transparent"
            // } 
            this.$nextTick(() => {
            if(Date.now()>=this.blogBg.expiry){
                // console.log("超过了,自动删除")
                this.removebg()
                clearInterval(this.timer);
                this.timer = null
                this.$message({
                    message:"切回原主题成功！",
                    type: 'success',
                    duration:1000,
                }); 

            }else{              
                // console.log("有壁纸")
                this.successbg() 
                }
            })
        },
        
        handleCurrentChange(val) {
			// console.log(`当前页: ${val}`,"每页几个：",this.pageSize);

            request.get('/xc/list/2',{params:{pageNum:val,pageSize:this.pageSize}}).then((res)=>{
                this.total=res.data.total
                this.bgImageList=res.data.list
               
            })
		},
        
    },

    mounted(){
        if(this.blogBg!=null){
            this.successbg()
            this.timer = setInterval(this.jiance, 2000); // 注意: 第一个参数为方法名的时候不要加括号;
        }

        // 获取壁纸
        
        request.get('/xc/list/2',{params:{pageNum:this.pageNum,pageSize:this.pageSize}}).then((res)=>{
            this.total=res.data.total
            this.bgImageList=res.data.list
            
        })
    },

    created(){
        
    },
    watch:{
        blogBg(b){        
            // console.log("bianhua",b)
            if(this.timer!=null){
                console.log(this.timer)
            }else{
                this.timer = setInterval(this.jiance, 2000); // 注意: 第一个参数为方法名的时候不要加括号;
            }
            // this.timer = setInterval(this.jiance, 2000); // 注意: 第一个参数为方法名的时候不要加括号;
        }
    }
}
</script>

<style>
.set-bg{
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	border: 1px solid  #7474748e;
    margin-top: 10px;
	cursor: pointer;
	padding: 5px;
	background: var( --card-bg);
	color: var(--font-color);
	border-radius: 50%;
    transition: .3s;
    z-index: 99;
    position: fixed;
	bottom: 75px;
	right: 10px;
}
.set-bg span{
    font-size: 1.3rem;
}

.set-bg:hover{
	background-color: var(--app-allColor);
    color: #fff;
    transition: .3s;
    /* background-color:rgba(151, 151, 151, 0.425); */
	/* color:var(--app-divbgcColor); */
    /* background-color: #ffffff94;
    border: 1px solid  var(--app-allColor);
    color:  var(--app-allColor); */
}
.setbg-zi .el-pagination.is-background .btn-next, 
.setbg-zi .el-pagination.is-background .btn-prev, 
.setbg-zi .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    /* background-color: #252529; */
	background-color: var(--card-tm-bg);
    border-radius: 0;
	color: var(--font-color);
    min-width: 30px;
    /* border-radius: 2px; */
	border: 1px solid #7474748e;
}

.setbg-zi .el-pagination.is-background .el-pager li {
	transition: .3s;
	background-color: #ffffff00;
	min-width: 20px;
	padding: 0 2px;
	border: none;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    /* background-color: #409EFF; */
	background-color: #ffffff00 !important;
    color: var(--app-allColor) !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
	transition: .3s;
	color: var(--app-allColor) !important;
}
.setbg-zi{

    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* background-color: rgb(101, 155, 203); */
}
.el-dialog__wrapper .el-dialog  {
    background-color: #2c2c2c00;
}
.el-dialog .el-dialog__body {
    padding: 0;
}

.setbg-zi-top{
    /* height: 50px; */
    width: 100%;
    border-bottom: 2px dashed var(--font-color);
}  
.setbg-zi-top .szt-title {
    position: relative;
    padding-top: 10px;
    padding-left: 10px;
    /* margin-left: 10px; */
}
.setbg-zi-top .szt-title span{
    font-size: 18px;
    font-weight: 600;
}

.setbg-zi-top .szt-title button{   
    /* font-size: 15px; */
    position: absolute;
    right: 10px;
    background: hsla(0, 0%, 0%, 0);
    color: var(--font-color);
    border-right: none;
    border-bottom: none;
    border-top: none;
    border-left: 1px solid #DCDFE6;
    font-size: 12px;
    border-radius: 0;
    padding: 5px;
    cursor: pointer;
    margin: 2px;
    transition: .3s;
}
.setbg-zi-top .szt-title button:hover{
    transition: .3s;
    background: hsla(0, 0%, 0%, 0);
    color: var(--app-allColor);
}

.setbg-zi-top .szt-zs{
    /* padding: 10px; */
    padding-bottom: 10px;
    /* margin-left: 10px; */
}
.setbg-zi-top .szt-zs .el-upload__tip{
    padding:0 10px;
    font-weight: 300;
    font-family:"Times New Roman",Times,serif;
    font-size: 10px;
}

.setbg-zi-body {
    padding: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    width: calc(100%-20px);
    transition: all 400ms ease 0ms;
}
.setbg-zi-body>div {
    /* padding: 10px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-height: 450px;
    overflow-y: auto;
    width: 100%;
    transition: .3s;
}
.setbg-zi-body>div>div {
    height: 120px;
}
.setbg-zi-body img{
    /* height: 200px; */
    /* cursor: zoom-in; */
    object-fit: cover;
    transition: all 400ms ease 0ms;
  
    height: 100px;
    width:160px;
    border: 1px solid #ffffff00;
    border-radius: 5px;
    /* width: 180px; */
}
.setbg-zi-body img:hover{
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid var(--app-allColor);
}

.el-message {
    top: 0px !important;
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    background-color: var(--card-bg) !important;
}
.el-message .el-message__content {
    /* background-color: rgba(255, 255, 255, 0.182) !important; */   
}
.focus-message {
    /* background-color: rgba(255, 255, 255, 0.182) !important;
    border: none !important;
    border-radius: 0px;
    top: 0;   */
}
.focus-message .el-icon-info::before {
    color: white !important;
  }
.focus-message .el-message__content {
    /* color: black !important; */
  }



@media (max-width:1100px) {
    .setbg-zi-body img{
        width: 200px;
    }
}
@media (max-width:700px) {
    .setbg-zi-body img{
        height: 100px;
        width:160px
    }
}
@media (max-width:400px) {
    .setbg-zi-body img{
        width:240px
    }
}
</style>