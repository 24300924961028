<template>
    <div class="headinform faguang" id="faguang">
        <div class="hf-txname">
			
			<div style="padding-top: 10px;font-size: 15px;margin-bottom: 10px;font-weight: 500;">
				{{getTimeState()}}
			</div>
			<div style="display: flex; text-align: center; justify-content: center;">
				<div >
					<img class="myheader" :src="user.avatar">
				</div>
	
				
				<div class="my-nickname">
					{{ user.nickname }}
				</div>					
			</div>

            <div class="mygexingqm">
                {{ user.intro }}
            </div>
			<el-divider>
				<i class="iconfont icon-chuizi" style="padding: 0;font-size: 25px;border-radius: 50% !important;"></i>
			</el-divider>

            <div style="display: flex; align-items: center;justify-content:center;">
                <div class="h-wz" @click="$router.push({ path:'/category'})">
					分类
					<sup>{{num.c}}</sup>
                </div>
				<el-divider direction="vertical"></el-divider>
                <div class="h-wz" @click="$router.push({ path:'/tag'})">
					标签
					<sup>{{num.t}}</sup>
                </div>
				<el-divider direction="vertical"></el-divider>
                <div class="h-wz" @click="$router.push({ path:'/pictures'})" style="border: none;">
					相册
					<sup>{{num.xc}}</sup>
                </div>
            </div>
            <div class="cards-lx" style="display: flex; align-items: center;justify-content:center;">
                <!-- <div class="sj-icon"> -->
				<a href="https://github.com/ljf6522">
					<i class="iconfont icon-github" style="color: black;"></i>
                </a>
				<!-- </div>
                <div class="sj-icon"> -->
				<a href="https://gitee.com/dashboard/projects">
					<i class="iconfont icon-gitee-fill-round" style="color:  #C71D23;"></i>
                </a>
				<!-- </div>
                <div class="sj-icon"> -->
				<a href="">
					<i class="iconfont icon-bilibili" style="color:#FB7299;"></i>
				</a>
				<!-- </div>
                <div class="sj-icon"> -->
				<a href="tencent://message/?uin=2415648320&Site=&Menu=yes">
					<i class="iconfont icon-QQ-circle-fill" style="color: deepskyblue;"></i>
				</a>
				<!-- </div> -->
            </div>

        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { blogIndex } from '../../../api/webSetting';
import router from '@/router';
export default {
    name:"HeadAndSJ",
	computed:{
		...mapGetters([
			'user'
		]),
    },
	data(){
		return{
			num:''
		}
	},
	methods:{
		getTimeState(){
			// 获取当前时间
			let timeNow = new Date();
			// 获取当前小时
			let hours = timeNow.getHours();
			// 设置默认文字
			let state= ``;
			// 判断当前时间段
			if (hours > 4 && hours <= 10) {
				state = `😀 早上好！`;
			} else if (hours > 10 && hours <= 13) {
				state= `🌞 中午好！`;
			} else if (hours > 13 && hours <= 18) {
				state= `🧍‍♂️ 下午好！ `;
			} else if (hours > 18 && hours < 24) {
				state= `🌜 晚上好！`;
			}else if (hours >= 0 && hours <= 4) {
				state= `🛌 夜深了！注意休息~`;
			}
			return state;
		},

		getGeNum(){
			blogIndex().then((res)=>{
				this.num=res.data
			})
		}
	},
	mounted(){
		this.getGeNum()
	},
	created(){

	}
}
</script>

<style>
.headinform{
	border-bottom:1px solid #7474748e;
	height: 330px;
	width: 100%;
	/* border-radius: 10px 10px 10px 10px; */
	background-size: auto 100%;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover!important;
    background-position: center!important;
	/* background-color:	#00c3ff; */
	/* background-image:url(../../../assets/logo/R-C.jpg); */
	/* color: #000000; */
}
.hf-txname{
	border-radius: 10px 10px 10px 10px;
	/* display: grid; */
	text-align: center;
	/* background-color: #000000; */
	/* width: 100%; */
	height:100%;
	/* background-color: #fff; */
	/* background-image: linear-gradient(to top, var(--app-divbgcColor) 60%, rgba(255, 0, 0, 0)); */
	/* background-image:linear-gradient(0deg, rgba(43,43,43,1) 0%, rgba(43,43,43,1) 27%, rgba(43,43,43,0.9794292717086834) 42%, rgba(43,43,43,0.8589810924369747) 67%, rgba(43,43,43,0.38559173669467783) 93%); */
	/* background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 27%, rgba(255,255,255,0.9794292717086834) 42%, rgba(255,255,255,0.8589810924369747) 67%, rgba(255,255,255,0.38559173669467783) 93%); */
	/* background-image: linear-gradient(0deg, var(--bgijianbianA) 0%,var(--bgijianbianB) 27%, var(--bgijianbianC) 42%,var(--bgijianbianE) 93%); */
}

.headinform .el-divider__text {
	padding: 1px;
	border-radius: 50% !important;
}
.headinform .hf-txname .myheader{
	border-radius: 50%;
	object-fit: cover;
	margin-top: 10px;
	height: 100px;
	width: 100px;
	border: 4px solid #E0E3ED;
	
}
.headinform .hf-txname .myheader:hover {
	/* box-shadow: 0 0 18px #000000; */
	animation: rotate 0.5s linear;	
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	25% {
		-webkit-transform: rotate(90deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
	}

	75% {
		-webkit-transform: rotate(270deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}		
}
.headinform .my-nickname{
	padding-left: 20px;
	padding-top: 40px;
	line-height: 50px;
	font-size: 30px;
	font-weight: 600;
	color: var(--font-color);
	/* background-color: #dfd3d3; */
}
.headinform .mygexingqm{
	padding: 5px;
	margin: 10px;
	line-height: 28px;
	font-weight: 300;
    /* max-height: 5rem; */
    font-size: 0.9rem;
	/* padding: 10px; */
	display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;
    max-height: 5rem;
    overflow: hidden;
	/* padding-bottom: 20px; */
	/* border-bottom: 1px dashed  var(--font-color); */
}
.headinform .el-divider__text {
    background-color:  var(--bgijianbianA);
	color: var(--font-color);
}
.h-wz{
	text-align: center;
	padding-left: 10px;
	/* padding-right: 8px; */
	width: 60px;
	/* height: 30px; */
	/* background-color: rgb(175, 175, 175); */
	/* border-right: 1px solid  var(--font-color); */
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	transition: .3s;
}
.h-wz:hover{
	transition: .3s;
    color: var(--app-allColor);
}
.headinform .el-divider--vertical{
	display:inline-block;
	/* width: 1px; */
	height:20px;	
	margin:0 8px;
	vertical-align:middle;
	/* position:relative; */
}

.h-wz sup{
	font-size: 0.5rem;
	padding: 2px;
}


.cards-lx {
	transform: translate(-50%,125%);
	/* width: 200px; */
	border-radius: 10px !important;
	border: none;
	/* background-color: var(--app-divbgcColor); */
	background-color: var(--card-tm-bg);
	box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
	color: #fff;
	font-size: 1rem;
	/* padding: .5rem 1rem; */
	padding: 10px;
	position: absolute;
	left: 50%;
	bottom: 0;
	opacity: 0;
	transition: 0.3s ease-out;
	animation-fill-mode: forwards;
}
.cards-lx .iconfont:hover{
	cursor: pointer;
  	background-color: rgba(172, 172, 172, 0.603);
}
/*Hover*/
/* .hf-txname:hover {
} */
			
.hf-txname:hover .cards-lx {
	transform: translate(-50%,30%);
	opacity: 1;
}

.cards-lx .iconfont{
	/* padding: 0 10px; */
	padding: 5px;
	font-size: 30px;
	border-radius: 50%;
    margin: 5px;
	/* background-color: rgb(255, 116, 116); */
}

</style>