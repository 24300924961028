<template>
    <div class="blog-header">
        <nav class="" ref="fixedBox">
            <div class="nav_group">
                <!-- 抽屉 -->
                <left-chou-ti></left-chou-ti>   

                <div class="logo" @click="toHome"> 
                    <div style="font-size: 30px;">像梦</div>
                    <div style="font-size: 14px;">xiangmeng</div>
                    <!-- <img src="../../assets//logo/mylogo.png"> -->
                </div>
                
                <header-mulu></header-mulu>
            
                <div class="kong" style="flex: 1;"></div>

                
                <search-win></search-win>
            </div>
            
        </nav>
    </div>
</template>

<script>
import LeftChouTi from '@/components/header/header-cp/LeftChouTi.vue';
import HeaderMulu from '@/components/header/header-cp/HeaderMulu.vue';
import SearchWin from '@/components/header/header-cp/SearchWin.vue';
export default {
  components: { LeftChouTi, HeaderMulu, SearchWin },
    name:'BlogHeader',
    data() {
        return {

        }
    },
    methods: {
        toHome(){
            this.$router.push({
                path:'/',
            })
        }
    // btn(obj,index){
    //             this.$router.push({
    //                         path:obj.first_route,
    //                     //     query:{
    //                     //         // search:input,
    //                     //         t:Date.now()
    //                     // }, 
    //         })
    //     console.log("route:",obj.first_route)
    //    this.findIndex = index
    //     }
    },

    mounted(){
         //获取顶部固定块
        let search = this.$refs.fixedBox
        document.onscroll = () => {
            //获取滚动条位置
            let s1 = document.documentElement.scrollTop;
            let s2 = document.body.scrollTop;
            let scroll = s1 == 0 ? s2 : s1;
            // console.log("s1:",s1 ,"\n","s2:",s2)
            //获取视口宽度
            let width = document.documentElement.clientWidth;

            //判断滚动条超过视口宽度的12%时，搜索块变固定定位
            if (scroll > width * 0.0) {
            // search.style.position = "fixed";
            // search.style.background ="var(--headertoumingc)";
            search.style.backdropFilter='blur(5px)';    
            // search.style.border='1px solid black'

            } else {
            // search.style.position = "static";
            // search.style.background ='var(--app-bgColor)';
            search.style.background ='var(--card-tm-bg)';
            // search.style.position = "fixed";
            // search.style.display='flex'
            }          
        }; 
    },
    // watch: {       
    //     winWidth(val) {
    //         if(!this.timer){
    //         // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
    //         this.winWidth = val
    //         this.timer = true
    //         let that = this
          
    //         setTimeout(function(){
    //             // 打印screenWidth变化的值        
    //             console.log(that.winWidth)
    //             that.timer = false
    //         },400)
    //     }     
    //     }
    // } ,
}

</script>

<style>
@import "@/assets/css/font2/font.css";

.blog-header{
    width: 100%;
    position: relative;
    z-index: 10;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* display: flex;  */
    /* color: var(--header-text-color); */ 
}
nav{
    display: flex;   
    -webkit-align-items: center;
    align-items: center; 
    justify-content: center;
    transition: all 400ms ease 0ms;
    /* text-shadow: 0 0.2rem 0.3rem rgb(0, 0, 0); */
    position: fixed;
    /* flex-wrap: nowrap; */
    text-align: center;
    /* background-color: var(--app-bgColor); */
    background: var(--card-tm-bg);
    height: 55px;
    width: 100%;
    /* border-bottom :1px solid rgba(255,255,255,0.5);   */
    border-bottom: 0.5px solid #e0e3ed67;
    /* box-shadow: 0 1px 5px 1px rgb(0, 0, 0); */
}
nav .nav_group{
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1250px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

nav .nav_group .logo{
    padding-left: 30px;
    /* display: flex; */
    font-family :  'MyFont2';
    /* margin-left: 20px; */
    color: var(--font-color); 
    /* font-weight: 800; */
    /* letter-spacing: -1px; */
}
nav .nav_group .logo>div:hover{
    cursor: pointer;
}
nav .nav_group .logo>div{
    /* background-image: var(--logo-bgi); */
    background-repeat: no-repeat;
	background-size: cover!important;
    background-position: center!important;
    width: 80px;

}
nav .nav_group .logo img{
    height: 50px;
    /* width: 80px; */
}
nav .nav_group .search-box
{
    text-align: center;
}
nav .nav_group .search-box span{
    margin-left: 20px;
    margin-right: 20px;
    /* padding: 20px; */
    /* background-color:aqua; */

}
nav .nav_group .search-box span i,
nav .nav_group .menu-icon span i{
    font-size: 18px;
}

nav .nav_group .menu-icon
{
    /* width: 100px; */
    text-align: center;
    margin: 0 20px;
    /* font-size: 18px; */
    display: none;
   
}

nav .nav_group .search-box span,
nav .nav_group .menu-icon span{
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    display: flex;
    border: 1px solid rgba(64, 131, 239, 0);
    color:var(--font-color);
    transition: .3s;
}


nav .nav_group .search-box span:hover,
nav .nav_group .menu-icon span:hover{
    transition: .3s;
    transition: 0.3s;
    /* background-color: var(--app-allColor); */
    background-color: #ffffffc4;
    border: 1px solid  var(--app-allColor);
    color:  var(--app-allColor);
    /* color:var(--app-bgColor); */
}

@media (max-width:880px) {
    nav{
        padding: 0;
        margin: 0;
    }
    nav .nav_group .logo {
        /* flex: 1; */
        padding: 0;
        margin: 0;
        text-align: center;
    }
    nav .nav_group .nav-items{
        display: none;
    }
    nav .nav_group .kong{
        display: none;
    }
    nav .nav_group .menu-icon{
        display: block;
    }
}

.el-drawer {
    min-width: 350px;
    max-width: 350px;
}


</style>