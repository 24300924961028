<template>
	<div >
		<transition 
			enter-active-class="animate__animated animate__bounceInLeft"
            leave-active-class="animate__animated animate__bounceOutLeft">
			<div ref="dragArea" 
			id="drag-area" 
			class="drag-area"
			v-show="isMusic"
			@click.stop>
				<div class="music-content">
					<div class="benefits">
						<div class="basic-marquee basic-marquee-1">
							{{musicIndex.mName}}
						</div>
					</div>
				</div>

				<div class="music-jindu">
	
					<div class="curTime" style="font-size:10px; color:var(--font-color);margin-right:3px">{{curTime}}</div>
					
					<div class="progress" @click="clickProgress($event)" ref="progress">
						<div class="line" :style="{width: `${precent}`}"></div>
						<div class="dot" :style="{left: `${precent}`}" @touchmove='dotMove' ></div>
					</div>

					<div class="allTime" style="font-size:10px; color:var(--font-color);margin-left:3px">{{allTime}}</div>
				</div>

				<div class="musicBianji" style="display: flex;">

					<!-- <button>
						<span class="iconfont icon-shangyigeshangyiqu" @click="toP"></span>
					</button> -->
					<button @touchend.stop.prevent="musicPlay()" @click="musicPlay()">
						<span :class="isPlayIcon"></span>
					</button>
					<!-- <button>
						<span class="iconfont icon-xiayigexiayiqu" @click="toN"></span>
					</button> -->
				</div>

				<el-collapse>
					<el-collapse-item>
						<template slot="title">
							<i class="iconfont icon-liebiao2">
								<span style="font-size: 14px;padding: 5px 0;"></span>	
							</i>
						</template>   
						<div class="musicList-zi" v-for="(m,i) in musicList" :key="i" @touchend.stop.prevent="setMusic(m)" @click="setMusic(m)">
							<i class="iconfont icon-zhuanjiguangpan"></i>&nbsp;
							<div style=" position: relative;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                letter-spacing: 1px;
                                -webkit-line-clamp:1;
                                overflow: hidden;">{{ m.mName }}</div>
						</div>
					</el-collapse-item>
				</el-collapse>

<!-- :src="musicUrl"

				@canplay="getDuration"
				 @ended="ended"  -->
				<audio 
					@canplay="getDuration"
					@timeupdate="updateTime" 
					ref="audio"
					id="resource" 
					:src="musicIndex.url" 				
					duration
					loop >
				</audio>
			</div>
		</transition>
		

		<div title="深色模式" class="isMusic" id="isMusic" @click="musicShow" :class="{activeMusic:isMusic}">
			<div style=" 
			 bottom: 0px;
			 display: inline-block;
			 vertical-align: baseline;
			 font-size: 0;">
				<span :class="ifActive2()">
				</span> 
				<span :class="ifActive()">
				</span> 
				<span :class="ifActive2()">
				</span>
			</div>
			
		</div>
	</div>
    
</template>

<script>
export default {
    name:'MusicXuanFu.vue',
    data(){
        return{
			musicList:[
				{
					url:require("@/assets/music/luoy.mp3"),
					mName:"落英（凡人修仙传插曲）-银临"
				},{
					url:require("@/assets/music/yuandian.mp3"),
					mName:"原点（不良人）-西单女孩"
				}
				
				
			],
			musicIndex:'',

			curTime: '00:00', // 当前播放时间，格式化之后的
      		allTime: '00:00', // 当前音频总时长，格式化之后的
			duration: 0, // 音频总时长，单位秒
			currentTime: 0, // 音频当前播放时间， 单位秒
			precent: '0%', // 当前播放进度百分比


			isMusic:false,
			isPlayIcon:"iconfont icon-bofang",
			isTiao:true,
			
			musicwinWidth:document.body.clientWidth,
        }
    },
	methods:{
		// 打开关闭音乐窗口按钮
		musicShow(){
			if(!this.isMusic){
				this.isMusic=true
				// console.log(this.isMusic)
			}else{
				this.isMusic=false
				// console.log(this.isMusic)
			}
		},

		// 判断当前音乐是否暂停,从而图标变化
		ifActive(){
			if(this.isTiao){
				return "iconfont icon-yinle1"
			}else{
				return "tiao-dong"
			}
		},
		ifActive2(){
			if(this.isTiao){
				return ""
			}else{
				return "tiao-dong"
			}
		},

		// 音乐暂停播放按钮,默认暂停
		musicPlay(){
			let a=this.$refs.audio
			if(this.isPlayIcon=="iconfont icon-zanting"){
				a.pause();
				this.isPlayIcon ="iconfont icon-bofang"
				this.isTiao=true
			}else{	
				a.play();
				this.isPlayIcon ="iconfont icon-zanting"
				this.isTiao=false

			}			
		},

		// 上一首
		toP(){},
		// 下一首
		toN(){},

		creatMusic(){
			this.musicIndex=this.musicList[0]
		},

		setMusic(m){
			this.musicIndex=m
			setTimeout(() => {
				this.$refs.audio.play()
				this.isPlayIcon ="iconfont icon-zanting"
				this.isTiao=false
			},1000)		
		},

		formatTime (time) {
			if (time === 0) {
				this.curTime = '00:00'
				return
			}
			const mins = Math.floor(time / 60) < 10 ? `0${Math.floor(time / 60)}` : Math.floor(time / 60)
			const sec = Math.floor(time % 60) < 10 ? `0${Math.floor(time % 60)}` : Math.floor(time % 60)
			return `${mins}:${sec}`
		},
		getDuration () { // canplay时获取音频总时长
			this.duration = this.$refs.audio.duration
			this.allTime = this.formatTime(this.$refs.audio.duration)
			// console.log("音频长度",this.allTime)

		},

		updateProgress (currentTime, duration) { // 更新进度条
			this.precent =  `${((currentTime / duration) * 100).toFixed(5)}%`
		},
		updateTime (e) { // timeupdate时获取当前播放时间
			const { currentTime } = e.target
			this.currentTime = currentTime
			this.curTime = this.formatTime(currentTime)
			this.updateProgress(this.currentTime, this.duration)
			// console.log(this.curTime)
		},

		dotMove (e) {
			// 移动的距离
			let moveX = e.touches[0].pageX - 83
			// 进度条的宽度
			const progressWidth = this.$refs.progress.offsetWidth
			if (moveX >= progressWidth) moveX = progressWidth
			this.$refs.audio.currentTime = ((moveX / progressWidth) * this.duration)
			this.updateProgress(((moveX / progressWidth) * this.duration), this.duration)
		},

 		clickProgress (event) { // 点击进度条时 更新音频时间和进度条
			const e = event || window.event
			const position = e.clientX - e.currentTarget.offsetLeft // 当前点击的位置
			const progressWidth = this.$refs.progress.offsetWidth // 进度条总宽度
			this.$refs.audio.currentTime = ((position / progressWidth) * this.duration)
			this.updateProgress(((position / progressWidth) * this.duration), this.duration)
		},

		// 随着右边宽度变化窗口移动
		widthXiangying(){
			// console.log("宽")
            // this.musicwinWidth=document.body.clientWidth 

			// let dragArea = this.$refs.dragArea;
			// let w=this.musicwinWidth - dragArea.getBoundingClientRect().width
			// if(dragArea.getBoundingClientRect().left >=w){
			// 	dragArea.style.left = w + "px";
			// }

			// console.log("宽")
			this.musicwinWidth = document.body.clientWidth;

			let dragArea = this.$refs.dragArea;
			let dragAreaWidth = dragArea.getBoundingClientRect().width;
			let dragAreaLeft = dragArea.getBoundingClientRect().left;
			let w = this.musicwinWidth - dragAreaWidth;

			if (dragAreaLeft >= w) {
				dragArea.style.left = w + "px";
			}

		}
	},
    mounted() {
		/**
		 * 监听悬浮拖拽区域
		 */
		// this.$nextTick(() => {
		// 	// 获取DOM元素
		// 	let dragArea = this.$refs.dragArea;
		// 	// 缓存 clientX clientY 的对象: 用于判断是点击事件还是移动事件
		// 	let clientOffset = {};
		// 	// 绑定鼠标按下事件
		// 	dragArea.addEventListener("mousedown", (event) => {
		// 		let offsetX = dragArea.getBoundingClientRect().left; // 获取当前的x轴距离
		// 		let offsetY = dragArea.getBoundingClientRect().top; // 获取当前的y轴距离
		// 		let innerX = event.clientX - offsetX; // 获取鼠标在方块内的x轴距
		// 		let innerY = event.clientY - offsetY; // 获取鼠标在方块内的y轴距
		// 		// console.log(offsetX, offsetY, innerX, innerY);
		// 		// 缓存 clientX clientY
		// 		clientOffset.clientX = event.clientX;
		// 		clientOffset.clientY = event.clientY;
		// 		// 鼠标移动的时候不停的修改div的left和top值
		// 		document.onmousemove = function(event) {
		// 			dragArea.style.left = event.clientX - innerX + "px";
		// 			dragArea.style.top = event.clientY - innerY + "px";
		// 			// dragArea 距离顶部的距离
		// 			let dragAreaTop = window.innerHeight - dragArea.getBoundingClientRect().height;
		// 			// dragArea 距离左部的距离
		// 			let dragAreaLeft = window.innerWidth - dragArea.getBoundingClientRect().width;
		// 			// 边界判断处理
		// 			// 1、设置左右不能动
		// 			// dragArea.style.left = dragAreaLeft + "px";

		// 			// 超出左
		// 			if (dragArea.getBoundingClientRect().left <= 0) {
		// 				dragArea.style.left = "0px";
		// 			}

		// 			// 超出右
		// 			if (dragArea.getBoundingClientRect().left >= dragAreaLeft) {
		// 				dragArea.style.left = dragAreaLeft + "px";
		// 			}

		// 			// 2、超出顶部处理
		// 			if (dragArea.getBoundingClientRect().top <= 0) {
		// 				dragArea.style.top = "0px";
		// 			}
		// 			// 3、超出底部处理
		// 			if (dragArea.getBoundingClientRect().top >= dragAreaTop) {
		// 				dragArea.style.top = dragAreaTop + "px";
		// 			}
		// 		};
		// 		// 鼠标抬起时，清除绑定在文档上的mousemove和mouseup事件；否则鼠标抬起后还可以继续拖拽方块
		// 		document.onmouseup = function() {
		// 			document.onmousemove = null;
		// 			document.onmouseup = null;
		// 		};
		// 	}, false);
		// 	// 绑定鼠标松开事件
		// 	// dragArea.addEventListener('mouseup', (event) => {
		// 	// 	let clientX = event.clientX;
		// 	// 	let clientY = event.clientY;
		// 	// 	if (clientX === clientOffset.clientX && clientY === clientOffset.clientY) {
		// 	// 		console.log('click 事件');
		// 	// 	} else {
		// 	// 		console.log('drag 事件/鼠标松开');
		// 	// 	}
		// 	// })
		// }),
		
		// window.addEventListener("resize", () => {   
		// 	this.widthXiangying()                     
        // });

		// //点击任意位置关闭区域弹窗
		// document.addEventListener('click',(e) => {
		// 	const isM=document.getElementById('isMusic')
        // //获取弹窗对象
        //     const userCon = document.getElementById('drag-area');
        //     //判断弹窗对象中是否包含点击对象
        //     if(userCon && !userCon.contains(e.target)&&!isM.contains(e.target)) {
        //         //如果包含则跳转回之前的页面
        //         this.isMusic = false;
        //     }
    
        // })

		/**
 * 监听悬浮拖拽区域
 */
		this.$nextTick(() => {
			// 获取DOM元素
			let dragArea = this.$refs.dragArea;
			// 缓存 clientX clientY 的对象: 用于判断是点击事件还是移动事件
			let clientOffset = {};
			
			// 获取鼠标按下/触摸开始事件的通用处理函数
			const onDragStart = (event) => {
				event.preventDefault();
				let isTouchEvent = event.type.startsWith('touch');
				let startX = isTouchEvent ? event.touches[0].clientX : event.clientX;
				let startY = isTouchEvent ? event.touches[0].clientY : event.clientY;
				
				let offsetX = dragArea.getBoundingClientRect().left; // 获取当前的x轴距离
				let offsetY = dragArea.getBoundingClientRect().top; // 获取当前的y轴距离
				let innerX = startX - offsetX; // 获取鼠标在方块内的x轴距
				let innerY = startY - offsetY; // 获取鼠标在方块内的y轴距
				
				// 缓存 clientX clientY
				clientOffset.clientX = startX;
				clientOffset.clientY = startY;

				// 鼠标/触摸移动事件的通用处理函数
				const onDragMove = (event) => {
					let moveX = isTouchEvent ? event.touches[0].clientX : event.clientX;
					let moveY = isTouchEvent ? event.touches[0].clientY : event.clientY;

					dragArea.style.left = moveX - innerX + "px";
					dragArea.style.top = moveY - innerY + "px";

					// dragArea 距离顶部的距离
					let dragAreaTop = window.innerHeight - dragArea.getBoundingClientRect().height;
					// dragArea 距离左部的距离
					let dragAreaLeft = window.innerWidth - dragArea.getBoundingClientRect().width;
					
					// 边界判断处理
					// 超出左
					if (dragArea.getBoundingClientRect().left <= 0) {
						dragArea.style.left = "0px";
					}

					// 超出右
					if (dragArea.getBoundingClientRect().left >= dragAreaLeft) {
						dragArea.style.left = dragAreaLeft + "px";
					}

					// 超出顶部处理
					if (dragArea.getBoundingClientRect().top <= 0) {
						dragArea.style.top = "0px";
					}
					// 超出底部处理
					if (dragArea.getBoundingClientRect().top >= dragAreaTop) {
						dragArea.style.top = dragAreaTop + "px";
					}
				};

				// 鼠标/触摸抬起事件的通用处理函数
				const onDragEnd = () => {
					document.removeEventListener('mousemove', onDragMove);
					document.removeEventListener('mouseup', onDragEnd);
					document.removeEventListener('touchmove', onDragMove);
					document.removeEventListener('touchend', onDragEnd);
				};

				// 绑定鼠标/触摸移动和抬起事件
				document.addEventListener('mousemove', onDragMove);
				document.addEventListener('mouseup', onDragEnd);
				document.addEventListener('touchmove', onDragMove);
				document.addEventListener('touchend', onDragEnd);
			};

			// 绑定鼠标按下事件
			dragArea.addEventListener('mousedown', onDragStart, false);
			// 绑定触摸开始事件
			dragArea.addEventListener('touchstart', onDragStart, false);
		});

		window.addEventListener("resize", () => {   
			this.widthXiangying()                     
		});

		//点击任意位置关闭区域弹窗
		document.addEventListener('click', (e) => {
			const isM = document.getElementById('isMusic');
			//获取弹窗对象
			const userCon = document.getElementById('drag-area');
			//判断弹窗对象中是否包含点击对象
			if (userCon && !userCon.contains(e.target) && !isM.contains(e.target)) {
				//如果包含则跳转回之前的页面
				this.isMusic = false;
			}
		});


	},
	created(){
		this.creatMusic()
	},
	destroyed() {
		// 组件销毁后解绑事件
		// window.onresize = null;
	},
	beforeDestroy() { // 在组件生命周期结束的时候销毁。
		window.removeEventListener('resize',() => {   
			this.widthXiangying()                     
		}, false)
	},

}
</script>

<style>

.tiao-dong:nth-child(1){
	background-color:var(--font-color);
	display: inline-block;		 
	width:5px;
	height: 20px;
	-webkit-animation: .8s .1s living linear infinite backwards normal;
	animation: .8s .1s living linear infinite backwards normal;
	-webkit-animation-delay: -1.1s;
}
.tiao-dong:nth-child(2){
	background-color:var(--font-color);
	margin-left: 3px;
	display: inline-block;		 
	width:5px;
	height: 20px;
	-webkit-animation: .8s .3s living linear infinite backwards normal;
	animation: .8s .3s living linear infinite backwards normal;
	-webkit-animation-delay: -1.3s;
}
.tiao-dong:nth-child(3){
	background-color:var(--font-color);
	margin-left: 3px;
	display: inline-block;		 
	width:5px;
	height: 20px;
	-webkit-animation: .8s .6s living linear infinite backwards normal;
	animation: .8s .6s living linear infinite backwards normal;
	-webkit-animation-delay: -1.6s;
}
@-webkit-keyframes living{
	0%{-webkit-transform:scaleY(1);transform:scaleY(1);-webkit-transform-origin:0 100%;transform-origin:0 100%}
	50%{-webkit-transform:scaleY(.3);transform:scaleY(.3);-webkit-transform-origin:0 100%;transform-origin:0 100%}
	100%{-webkit-transform:scaleY(1);transform:scaleY(1);-webkit-transform-origin:0 100%;transform-origin:0 100%}
}
@keyframes living{
	0%{-webkit-transform:scaleY(1);-ms-transform:scaleY(1);transform:scaleY(1);-webkit-transform-origin:0 100%;-ms-transform-origin:0 100%;transform-origin:0 100%}
	50%{-webkit-transform:scaleY(.3);-ms-transform:scaleY(.3);transform:scaleY(.3);-webkit-transform-origin:0 100%;-ms-transform-origin:0 100%;transform-origin:0 100%}
	100%{-webkit-transform:scaleY(1);-ms-transform:scaleY(1);transform:scaleY(1);-webkit-transform-origin:0 100%;-ms-transform-origin:0 100%;transform-origin:0 100%}
}

.music-jindu{
	display: flex;
	padding: 5px;
}
.music-jindu .progress{
	cursor: pointer;
	height: 2px;
	top: 5px;
	background-color: var(--font-color);
	width: 70%;
	position: relative;
}
.music-jindu .line{
	position: absolute;
	left: 0;
	top: 0;
	height: 2px;
	background-color: var(--app-allColor);
	transition: width .1s;
}
.music-jindu .dot{
	width: 6px;
    height: 6px;
    border-radius: 50% !important;
    position: absolute;
    top: -2px;
	background-color: var(--app-allColor);
	transition: left .1s;
}

.drag-area .el-collapse-item__header {
    /* color: #fff ; */
    color: var(--font-color);
    font-size: 13px;
    height: 15px;
    padding: 5px 0 5px 10px;
    background-color: #00000000;
    border: none;
}
.drag-area .el-collapse-item__wrap {
    background-color:rgba(163, 163, 163, 0);
}
.drag-area .el-collapse-item__content {
    padding-bottom: 0;
    background-color:rgba(163, 163, 163, 0);
}
.musicList-zi{
	cursor: pointer;
	transition: .3s;
	display: flex;
	color: var(--font-color);
	align-items: center;
	padding:0 10px
}
.musicList-zi:hover {
	transition: .3s;
	color: var(--app-allColor);
}
.drag-area {
    position: -webkit-sticky;
	position: sticky;			
	box-sizing: border-box;
    /* display: flex; */
	padding: 10px 5px;
    position: fixed;
    /* right: 20px;
    top: 80%; */
	width: 150px;
    left: 0;
    top: 56px;
    z-index: 99;
    /* padding: 13px; */
    /* width: fit-content; */
    opacity: 1;
	background: var(--app-bgColor);
    /* background-color: rgba(255, 39, 39, 0.966); */
    border-radius: 8px;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.195);
	background:var(--card-tm-bg);
    backdrop-filter: blur(5px);
    cursor: move;
    user-select: none;
    text-align: center;
}
.musicBianji{
	width: 100%;
	justify-content: center;
    align-items: center;
    text-align: center;
}
.musicBianji button{
	cursor: pointer;
	color:var(--font-color);
	border-radius: 50%;
	margin: 3px;
	display: flex;
	height: 30px;
	width: 30px;
    justify-content: center;
    align-items: center;
	/* border: 1px solid  #7474748e; */
	border: none;
	transition: .3s;
	background-color: #fdfdfd00;
	/* background-color: var(--card-tm-bg); */
}
.musicBianji button span{
	font-size: 24px;
}
.musicBianji button:hover{
	transition: .3s;
	color: var(--app-allColor);
}
.music-content {
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 15px;
	left: 10px;
	width: calc(100% - 20px);
	height: 30px;
	position: relative;
	overflow: hidden;
	border-radius: 14px;
}
.basic-marquee-1 {
	animation: marquee 8s linear infinite;
}
.benefits {
	color: var(--font-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 15px;
	min-width: 300px;
	height: 100%;
	white-space: nowrap;
	overflow: hidden;
	position: absolute;
	inset: 0;
}
@keyframes marquee {
	0% {
	transform: translateX(10%);
	}
	100% {
	transform: translateX(-50%);
	}
}

.isMusic{
	height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	border: 1px solid  #7474748e;
	cursor: pointer;
	padding: 5px;
	position: fixed;
	bottom: 120px;
	right: 10px;
	background: var( --card-bg);
	transition: 0.3s;
	border-radius: 50%;
	color: var(--font-color);
	z-index: 99;
}
.isMusic span{
	font-size: 1.3rem;
}
.isMusic:hover{
	transition: 0.3s;
	background-color: var(--app-allColor);
	color: #fff;
}
.activeMusic{
	transition: 0.3s;
	color:var(--app-allColor);
}
</style>