<template>
  <div class="blog-boby" id="blog-boby">
    <!-- <BeiJing></BeiJing> -->
      <div class="gonxiangbody">
        <router-view :key="$route.query.t"></router-view>
      </div>       
          <!-- <my-home></my-home> -->
          <!-- <article-index></article-index> -->   
  </div>
  
</template>

<script>
import GongxiangTop from '@/components/body/gonxiangtop/GongxiangTop.vue';
import BeiJing from '@/components/BeiJing.vue';
// import ArticleIndex from '@/pages/article/ArticleIndex.vue'
// import MyHome from '../../components/body/MyHome.vue'
export default {
  name:'BlogBody',
  components: {
    GongxiangTop
    // ArticleIndex,
    //  MyHome 
    ,
    BeiJing
},
  data() {
    return {
      ctx: {},
    };
  },
  mounted() {
  },
  methods: {
    initCanvas() {
      const canvas = document.querySelector("#canvas");
      this.ctx = canvas.getContext("2d");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      init(this.ctx);
    },
  },

}
</script>

<style>
.blog-boby{
  position: relative;
  width: 100%;
  transition: .3s ;
  flex: 1 auto;
  /* background: var(--card-tm-bg); */
  /* background-color: var(--blog-body-bg); */
  /* padding: 30px 15px; */
  /* max-width: 1250px; */
  /* width: 100%;
  /* background:var(--bgijianbianE); */
  /* background-color:rgba(127, 255, 212, 0.527); */
  /* z-index: 0; */
  /* z-index: -9; */ 
}

.gonxiangbody{
  
  padding-bottom: 10px;
  transition: all .3s ease-in-out 0s;
  /* display: flex; */
  /* width: 1180px; */
  /* border:1px solid black ; */
  /* position: relative; */
  /* min-height:70vh; */
  margin: 0 auto;
  /* padding: 10px; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 1250px;
}


/* @media (max-width:880px) {	
  .danban{
      height: 350px;
    }
} */
</style>