<template>
    <div  v-if="dataList.length!=0" >
        <div class="faguang wzhang"  v-for="(article,idx) in dataList" :key="idx">
            <div class="wz-zuo">
                <div class="wzz-top">
                    <span v-if="article.isTop==1" style="color: chocolate;">
						<i class="iconfont icon-tudingguding"></i> 置顶
					</span>
                    <span>
						<i class="iconfont icon-rili1"></i>
						{{article.createTime}}
					</span>
                    <div v-if="createTimeifNew(article.createTime)" class="top-new">最新</div>
                </div>
                <div class="wzz-body">
					<div>
						<div class="wzzb-bt">
							<span @click="toArticleIndex(article.id,article.articleText,article.articleTitle)">{{ article.articleTitle }}</span>
						</div>
						<div class="wzzb-text">
							<div id="v-html" style="letter-spacing: 0.5px;" v-html="imagetoIcon(article.articleText)"></div>
						</div>
					</div>
                    
                </div>
                <div class="wzz-footer">
                    <!-- <span>
						<i class="iconfont icon-liebiao1">分类</i>
					</span> -->
					<div style="margin-left: 5px;">
						<span v-for="tag in article.lTags" :key="tag.id" :style="{background :`${tag.tagColor}`}">
							<!-- <i class="iconfont icon-jiagebiaoqian"></i> -->
							{{ tag.tagName }}
						</span>
					</div>
                    
                </div>

            </div>
            <div class="wz-you">
                <!-- <img src="../../../assets/logo/R-C.jpg"> -->
				<img v-lazy="files.myblogImageUrl+article.articleCover" 
>
				
				<div class="wz-you-shan">L</div>
				<div v-if="article.lCategory" class="wz-you-flei" title="分类">
					{{ article.lCategory.categoryName }}
				</div>
				
            </div>
		
        </div>

		
		<div style="padding: 20px 10px 10px 0;">
			<el-pagination
				background
				@current-change="handleCurrentChange"
				:current-page="pageNum"
				:page-size="pageSize"
				layout="prev, pager, next"
				:total="total">
			</el-pagination>
		</div>
    </div>
	
	<div v-else>
		<loading-two></loading-two>
	</div>
</template>

<script>
import { gethomeArticle } from '@/api/article';
import LoadingTwo from '@/pages/errorOrNull/LoadingTwo.vue';

export default {
  	components: { LoadingTwo },
    name:'WenZhang',
    data(){
        return{
			// htmlData:'<h1>Ajax&nbsp;异步设置内容&nbsp;HTML</h1><p>linear ：动画从头到尾的速度是相同的。ease ：默认。动画以低速开始，然后加快，在结束前变慢。ease-in ：动画以低速开始。ease-out ：动画以低速结束。ease-in-out ：动画以低速开始和结束。cubic-bezier(n,n,n,n) ：在 cubic-bezier 函数中自己的值。可能的值是从 0 到 1 的数值。step-start ：在变化过程中，都是以下一帧的显示效果来填充间隔动画step-end ：在变化过程中，都是以上一帧的显示效果来填充间隔动画好像是家的电视讲话精神负担换句话说给大家噶见到过</p><h2>灌灌灌灌灌</h2><h1>第二</h1><h2>小时</h2><p>linear ：动画从头到尾的速度是相同的。ease ：默认。动画以低速开始，然后加快，在结束前变慢。ease-in ：动画以低速开始。ease-out ：动画以低速结束。ease-in-out ：动画以低速开始和结束。cubic-bezier(n,n,n,n) ：在 cubic-bezier 函数中自己的值。可能的值是从 0 到 1 的数值。step-start ：在变化过程中，都是以下一帧的显示效果来填充间隔动画step-end ：在变化过程中，都是以上一帧的显示效果来填充间隔动画好像是家的电视讲话精神负担换句话说给大家噶见到过</p><h1>第三大</h1>',
			// htmlData:'<p>linear ：动画从头到尾的速度是相同的。ease ：默认。动画以低速开始，然后加快，在结束前变慢。ease-in ：动画以低速开始。ease-out ：动画以低速结束。ease-in-out ：动画以低速开始和结束。cubic-bezier(n,n,n,n) ：在 cubic-bezier 函数中自己的值。可能的值是从 0 到 1 的数值。step-start ：在变化过程中，都是以下一帧的显示效果来填充间隔动画step-end ：在变化过程中，都是以上一帧的显示效果来填充间隔动画好像是家的电视讲话精神负担换句话说给大家噶见到过</p><h2>灌灌灌灌灌</h2><h1>第二</h1><h2>小时</h2><p>linear ：动画从头到尾的速度是相同的。ease ：默认。动画以低速开始，然后加快，在结束前变慢。ease-in ：动画以低速开始。ease-out ：动画以低速结束。ease-in-out ：动画以低速开始和结束。cubic-bezier(n,n,n,n) ：在 cubic-bezier 函数中自己的值。可能的值是从 0 到 1 的数值。step-start ：在变化过程中，都是以下一帧的显示效果来填充间隔动画step-end ：在变化过程中，都是以上一帧的显示效果来填充间隔动画好像是家的电视讲话精神负担换句话说给大家噶见到过</p><h1>第三大</h1>',  
			dataList:[],
			total:0,	
			pageNum:1,
			pageSize:10
		}
    },
	methods:{
		getArticleList(){
			gethomeArticle(this.pageNum,this.pageSize).then((res)=>{
				this.total=res.data.total
				this.dataList=res.data.list
			})
		},

		imagetoIcon(articleText){
			return articleText.replace(/\<.+?\>/g, "")				
			// return articleText.replace(
			// 	/\<img.+?\>/g,"<i class= '" +'el-icon-picture'+"'></i>"
            // ).replace(/\<video.+?\>/g,"<i class= '" +'el-icon-video-camera-solid'+"'></i>");
			// console.log("tubian图标：",s)			
		},

		createTimeifNew(aTime){
			var diffValue=new Date(aTime)
            // console.log("时间撮",diffValue)
            var minute = 60*1000;
            var hour = minute * 60;
            var day = hour * 24;
            var nowTime = (new Date()).getTime(); //获取当前时间戳 
            // console.log("new ",nowTime)                  
            var ShiJianCha = nowTime - diffValue;    
            var dayC = ShiJianCha / day;
			if(dayC>2){
                return false;
            }
            return true;  
	  	},
		
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`,"每页几个：",this.pageSize);
			gethomeArticle(val,this.pageSize).then((res)=>{
				this.dataList=res.data.list
				// console.log(res.data.list)
			})
		},

		toArticleIndex(id,text,isTitle){
			this.$router.push({
                path:'/article',
				query:{
					id:id,
					title:isTitle,
					B:this.$Base64.encode(text),
					t:Date.now()
				}, 
            })
		}
	},
	mounted(){
		this.getArticleList()
	},
	created(){
		
	}
}
</script>

<style>
.my-home .el-pagination.is-background .btn-next, 
.my-home .el-pagination.is-background .el-pager li,
.my-home .el-pagination.is-background .btn-prev {
    margin: 0 5px;
    /* background-color: #252529; */
	background-color: var(--card-tm-bg);
	color: var(--font-color);
    min-width: 30px;
	border-radius: 0 !important;
    /* border-radius: 2px; */
	border: 1px solid #7474748e;
}
.my-home .el-pagination.is-background .el-pager li {
	transition: .3s;
	background-color: #ffffff00;
	min-width: 20px;
	padding: 0 2px;
	border: none;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    /* background-color: #409EFF; */
	background-color: #ffffff00 !important;
    color: var(--app-allColor) !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
	transition: .3s;
	color: var(--app-allColor) !important;
}

.wzhang{
	margin-top: 20px;
	height: 220px;
	/* width: 100%; */
	display: flex;
	position: relative;
	/* border-bottom:1px solid #85858563; */
	border-bottom:1px solid #7474748e;;

}
/* .wzhang:hover{
	transform: translateY(-5px);
} */
.wzhang:first-child{
	margin-top: 0px;
}

.wzhang:nth-child(2n+1) .wz-zuo {
	width: 60%;
	height: 100%;
	border-radius: 10px 0 0 10px;
	position: absolute;
	/* background-color: #646464; */
}
.wzhang:nth-child(2n) .wz-zuo {
	width: 60%;
	height: 100%;
	border-radius: 0 10px 10px 0;
	position: absolute;
	right: 0;
	/* background-color: #646464; */
}

.wzhang .wz-zuo .wzz-top{
	display: flex;
	height: 35px;
	width: 100%;
	align-items: center;
	/* background-color: #646464; */
	position: relative;
}
.wzhang .wz-zuo .wzz-top span{
	margin-top: 5px;
	padding: 0 10px;
	/* font-size: 13px; */
	font-size: 12px;
}
.wzhang .wz-zuo .wzz-top .top-new{
	/* margin-top: -1px; */
	font-size: 12px;
	right:10%;
	letter-spacing: 1.5px;
	/* width: 30px; */
	padding:4px 12px;
	/* line-height: 8px; */
	position: absolute;
	/* background-color: rgb(248, 93, 21); */
	/* background-image: linear-gradient(to right, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%); */
	background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 0%, rgba(253,92,46,1) 48%, rgba(252,176,69,1) 100%);
	text-align: center;
	color: white;
	border-radius:0 0 5px 5px !important;
}
.wzhang .wz-zuo .wzz-body{
	height: 150px;
	/* width: 100%; */
	display:flex;
	/* justify-content: center;
    align-items: center; */
	/* background-color: #fff; */
}
.wzhang .wz-zuo .wzz-body .wzzb-bt {
	/* padding-top: 10px; */
	margin-top: 15px;
	padding-left: 10px;
	padding-right: 20px;
	font-size: 28px;
	/* font-weight: 600; */
	display: -webkit-box;
    -webkit-box-orient: vertical;
	/* line-height:26px; */
	letter-spacing: 1px;
    -webkit-line-clamp:1;
    /* max-height: 5rem; */
    overflow: hidden;
	position: relative;
}
.wzhang .wz-zuo .wzz-body .wzzb-bt span{
	cursor: pointer;
}

.wzhang .wz-zuo .wzz-body .wzzb-text{
	color: var(--font-1-color);
}
/* .wzhang .wz-zuo .wzz-body .wzzb-text h1,
.wzhang .wz-zuo .wzz-body .wzzb-text h2,
.wzhang .wz-zuo .wzz-body .wzzb-text h3,
.wzhang .wz-zuo .wzz-body .wzzb-text p{
    max-height: 42px;
	font-size: 13px;
	font-weight: 400;
} */

.wzhang .wz-zuo .wzz-body .wzzb-text div{
	width: calc(100% - 60px);
	display: -webkit-box;
    -webkit-box-orient: vertical;
	line-height:30px;
    -webkit-line-clamp:2;
    margin: 10px 20px 20px 20px;
    overflow: hidden;
	position: absolute;
	word-break: break-word;
}
.wzhang .wz-zuo .wzz-footer{
	position: relative;
	height: 30px;
	width: 100%;
	/* background-color: #7e7e7e; */
}
.wzhang .wz-zuo .wzz-footer>div{
	position: absolute;

}
.wzhang .wz-zuo .wzz-footer span{
	/* float: right; */
	font-size: 11px;
	margin:0 5px;
	color: #fff;
	padding: 2px 5px 3px;
	border-radius: 3px;
	/* font-weight: 600; */
	/* padding-right: 10px; */
}
.wzhang .wz-zuo .wzz-footer span .iconfont{
	font-size: 10px;
	padding-right: 2px;
}

.wzhang:nth-child(2n+1) .wz-you {
	width: 40%;
	height: 100%;
	/* background-color: #646464; */
	/* position: relative; */
	overflow: hidden;
	border-radius: 0 10px 0px 0 !important; 
	position: absolute;
	right: 0;
}
.wzhang:nth-child(2n) .wz-you {
	width: 40%;
	height: 100%;
	/* background-color: #646464; */
	/* position: relative; */
	overflow: hidden;
	border-radius: 10px 0 0 0px !important;
	position: absolute;
}

.wzhang .wz-you img {
	transition: filter 375ms ease-in .2s, transform .6s;
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	/* border-radius:100% 0% 68% 32% / 0% 100% 0% 100%  ; */
	/* border-radius: 0 10px 10px;	 */
	/* background-color: #FF0000; */
}
.wzhang .wz-you::before{
	content: "";
	/* border-radius: 10px; */
	position: absolute;
	z-index: 1;  
	width:100%;
	height: 100%;
	background-color: var(--blog-gonxiang-tm);
	background-size: 100% 100%  ;         
	background-position: center;
	background-size: cover;
	opacity: 1; 
}
.wzhang .wz-you .wz-you-shan{
	width: 100%;
	height: 100%;
	position: absolute;
	/* position: relative; */
	font-size: 5em;
	font-weight: 900;
	font-style: italic;
	color: rgba(255, 255, 255, 0.205);
	/* background: #25ccf7; */
	/* background: linear-gradient(120deg, #1ae50400 30%, #f0f0f0 39%, #f0f0f0 40%, #e5e5e500 48%); */
	/* background-size: 220% 100%;
	background-position: 100% 0; */
	/* background: linear-gradient(120deg, #e5e5e500 30%, #f0f0f0 38%, #f0f0f000 39%, #970d0d00 48%); */
	background-size: 200% 100%;
	background-position: 100% 0;
}
.wzhang .wz-you .wz-you-shan::before {
	content: "";
	z-index: 1;
	height: 0px;
	width: 0px;
	position: absolute;
	top:calc(100%/2 - 30px);
	left: calc(100%/2 - 30px);
	border-radius: 50%;
	border: 30px solid rgba(255, 255, 255, 0);
	transition: all 1s ease;
	filter: blur(0rem);
}

.wzhang:hover .wz-you .wz-you-shan::before {
	width: 600px;
	height: 600px;
	top:calc(100%/2 - 340px);
	left:calc(100%/2 - 340px);
	border: 40px solid rgba(255, 255, 255, 0.247);
	filter: blur(.5rem);
}

.wzhang .wz-you .wz-you-flei{
	/* background-color: #38383852; */
	text-align: center;
	color: #ffffff;
	border-radius: 5px !important;
	background:#3a3a3a3b;
    backdrop-filter: blur(10px);
	right: 10px;
	bottom: 10px;
	z-index: 2;
	font-size: 12px;
	padding: 5px 10px;
	/* width: 50px; */
	letter-spacing: 1.5px;
	position: absolute;

	transform: translateY(50px);
	transition: 0.3s ease-out;
	/* animation-fill-mode: forwards; */
	opacity: 0;
}
.wzhang .wz-you .wz-you-flei:hover{
	cursor: pointer;
	transition: 0.3s;
    /* background:var(--card-bg); */
	background-color:var(--app-allColor);;
    color:#fff;
}
.wzhang:hover .wz-you .wz-you-flei{
	transform: translateY(0px);
	opacity: 1;
} 
.wzhang:hover .wz-zuo .wzz-body .wzzb-bt{
	/* text-shadow: 0 0 1px #25ccf7; */
	transition: .5s;
	color:var(--app-allColor);
}

.wzhang:hover .wz-you img {
	/* 	transform: scale(1.1);
	transition: 0.6s; */
	/* transition: linear 0.5s; */
	cursor: pointer;
	transform: scale(1.1);
	/* animation: rotate 1.5s linear ;	 */
}
.wzhang:hover  .wz-you .wz-you-shan{
	animation: load89234 1s linear;
}

@keyframes load89234 {
    100% {
      background-position: -100% 0;
    }
  }

@media (max-width:620px) {		
	.wzhang{			
		flex-direction: column;
		height: 330px;
		border-bottom: none;
		/* height: -moz-fit-content */
	}

	/* .wzhang .wz-zuo .wzz-top .top-new{
		right: 0;
		border-radius:0 10px  0  10px;
	} */
	.wzhang:nth-child(2n+1) .wz-zuo,
	.wzhang:nth-child(2n) .wz-zuo
	{				
		width: 100%;				
		height: 180px;					
		border-radius: 10px 10px 0 0;				
	}		
	.wzhang .wz-zuo .wzz-body{
		height: 110px;
	}
	.wzhang .wz-zuo .wzz-body .wzzb-bt {
		margin-top: 5px;
	}
	.wzhang .wz-zuo .wzz-body .wzzb-text  div{
		margin: 5px 20px;
	}
	.wzhang:nth-child(2n+1) .wz-you,
	.wzhang:nth-child(2n) .wz-you{					
		width: 100%;					
		height: 150px;		
		bottom: 0;			
		border-radius:0 0 10px 10px !important;	
		border-radius: 0;
	}
}
/* @media (max-width:450px) {	
	.wzhang .wz-zuo .wzz-body .wzzb-text  div{
		max-width: 300px;
		min-width: 200px;
	}
} */
</style>