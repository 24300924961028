import request from "@/utils/requests";
// 首页文章
export const gethomeArticle=(num,size) =>request(
    {url:`/article/list?pageNum=${num}&pageSize=${size}`,
    method:'get',
})
// 推荐文章
export const getRecommendArticle=() =>request(
    {url:`/article/recommend`,
    method:'get',
    // data:param
})
// 获取对应文章的评论
export const getArticleIndex=(id) =>request(
    {url:`/article/getArticle?id=${id}`,
    method:'get',
    // data:param
})

// 评论
// 获取对应文章的评论
export const getCommentList=(articleId) =>request(
    {url:`/comment/cmsByaId?articleId=${articleId}`,
    method:'get',
    // data:param
})

// 发表评论
export const addComment=(param) =>request(
    {url:'/comment/setComment',
    method:'post',
    data:param
})

// 获取最新评论
export const getRecentCommentList=() =>request(
    {url:`/comment/recentComments`,
    method:'get',
    // data:param
})


// 搜索文章
export const searchArticleAll=(search) =>request(
    {url:`/article/getSearch?search=${search}`,
    method:'get',
    // data:param
})

// 阅读量+1
export const addReading=(id) =>request(
    {url:`/article/read/${id}`,
    method:'get',
    // data:param
})

