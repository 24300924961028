<template>
    <div>
		<div @click="topFunction()" id="myBtn" title="回顶部">
			<span class="iconfont icon-huidingbu1"></span>
		</div>
    </div>
</template>

<script>
export default {
    name:'ToTopBut',
	data(){
		return{
		
		}
	},
	
    methods:{
	    // topFunction() {
		// 	let myTimer=-1
		// 	// document.body.scrollTop = 0;
		// 	// document.documentElement.scrollTop = 0;
		// 	//1、获取当前滚动了多少距离
		// 	//2、启动定时器，以一定时间减少响应的滚动距离
		// 	var scrollTo = document.documentElement.scrollTop || document.body.scrollTop
		// 	if(myTimer == -1){
		// 		myTimer = setInterval(() => {
		// 			scrollTo -= 60
		// 			if(scrollTo<=0){
		// 				scrollTo = 0
		// 				window.clearInterval(myTimer)
		// 				myTimer = -1
		// 			}				
		// 			window.scrollTo(0,scrollTo) //这是值  是指离开网页顶部的距离   
		// 		}, 10);
					
		// 	}
		// },

		topFunction(){
			let myTimer = -1;
			
			if (myTimer !== -1) return; // 防止多次点击启动多个定时器

			let scrollTo = document.documentElement.scrollTop || document.body.scrollTop;

			function scrollStep() {
				scrollTo -= 60;

				if (scrollTo <= 0) {
				scrollTo = 0;
				window.scrollTo(0, 0);
				myTimer = -1;
				} else {
				window.scrollTo(0, scrollTo);
				myTimer = requestAnimationFrame(scrollStep);
				}
			}
			myTimer = requestAnimationFrame(scrollStep);
			
		}
		
	},
	mounted() {

  	},
}
</script>

<style>
#myBtn {
	height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	border: 1px solid  #7474748e;
	margin-top: 10px;
	background-color: rgba(0, 0, 0, 0);
	color: rgba(22, 22, 22, 0.418);
	cursor: pointer;
	background: var( --card-bg);
	color: var(--font-color);
	padding: 5px;
    transition: .3s;
	border-radius: 50%;
	/* opacity: 0;
	visibility: hidden; */
	/* visibility: visible; */
	/* padding: 5px; */
}
#myBtn span{
	font-size: 1.5rem;
}
#myBtn:hover {
	/* background-color: #555; */
	background-color: var(--app-allColor);
    color:#fff;
    transition: .3s;
    /* background-color: #ffffff94;
    border: 1px solid  var(--app-allColor);
    color:  var(--app-allColor); */
}

</style>