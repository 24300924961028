<template>
    <div class="friend-link">
        <gongxiang-top :togonggongTop="{page_title:'友链',page_bg:webSetting.webFriendbg,msg:''}"></gongxiang-top>
        
        <div class="friend-link-zi">
            
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import GongxiangTop from './gonxiangtop/GongxiangTop.vue'

export default {
    name:'FriendLink',
    computed:{
        ...mapGetters([
            'webSetting'
        ]),
    },
    components: {
        GongxiangTop
    },
}
</script>

<style>
.friend-link{
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    /* display: flex;   */
    /* border-radius: 10px 10px 10px 10px; */
	/* margin-top: 10px;	 */
	position: relative;
	color: var(--font-color);
	width: 100%;
}
.friend-link-zi{
    height: 200px;
    width: calc(100% - 20px);
    margin: 10px;
    background-color: aqua;
}
</style>