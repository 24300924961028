<template>
    <div class="wzfl-index">
        <gongxiang-top :togonggongTop="{page_title:'文章分类',page_bg:'',msg:'',bqflImage:toImg}"></gongxiang-top>

        <div style="width: 100%;">
            <div class="wenz-fl faguang" id="blog-card">   
                <!-- <div class="wzfl-one">
                    <h1>文章分类</h1><sup>53</sup>
                </div> -->
                <div class="wzfl-two">
                    <!-- <transition
                    v-for="(idx) in 7"  :key="idx" >
                        <div class="wzfl-zi faguang">
                            <div>
                                <span><i class="iconfont icon-liebiao1"></i> 分类</span>
                                <span> 8 篇</span>  
                            </div>                       
                        </div>            
                    </transition> -->
                    <transition
                    v-for="(f,idx) in feliList"  :key="idx">
                        <div class="wzfl-zi"   @click="getflName(f.categoryName,f.categoryImage)">                
                                <span><i class="iconfont icon-fenlei"></i> {{ f.categoryName }}</span>
                                <sup>{{ f.lArticles.length }}</sup>                                              
                        </div> 
                    </transition>
                    <div class="fl-bg">
                        <img :src="setBgImage" alt="">
                    </div>
                </div>     
            </div>

            <fen-lei-and-biaoq v-if="articleList.length>0" :toArticleList="articleList"></fen-lei-and-biaoq>
            <loading-two v-else-if="isLoading"></loading-two>           
            <is-null v-else></is-null>
        </div>
        
    </div>
</template>

<script>
import 'animate.css'
import FenLeiAndBiaoq from './FenLeiAndBiaoq.vue'
import GongxiangTop from './gonxiangtop/GongxiangTop.vue'
import { getCategoryAll } from '@/api/tagAndCategory';
import { mapState } from "vuex";
import LoadingTwo from '@/pages/errorOrNull/LoadingTwo.vue';
import IsNull from '@/pages/errorOrNull/IsNull.vue';

export default {
    components: { FenLeiAndBiaoq, GongxiangTop, LoadingTwo, IsNull },
    computed: {//加载数据过程loading
	    ...mapState(["isLoading"])
	},
    name:'WenZhangFlei',
    data(){
        return{
            feliList:'',
            articleList:'',
            setBgImage:'',

            toImg:require("@/assets/image/3333.jpg")
            // toImg:'http://qny.xiangmeng.xyz/image2c59726afddad798141899699b89174a.png'

        }
    },
    methods:{
        getflName(cName,cImage){
            this.setBgImage=this.files.myblogImageUrl+cImage
            getCategoryAll(cName).then(res=>{
                this.articleList=res.data[0].lArticles
            })
        }
    },
    mounted(){
        if(this.$route.query.search){
            this.getflName(this.$route.query.search)
        }else{
            getCategoryAll('').then(res=>{
                this.feliList=res.data
                this.articleList=res.data[0].lArticles
            })
        }
        getCategoryAll('').then(res=>{
            this.feliList=res.data

        })
    }
}
</script>

<style>
.wzfl-index{
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* margin-top: 20px; */ 
    border-radius: 10px 10px 10px 10px;
	/* margin-top: 10px;	 */
	position: relative;
	color: var(--font-color);
	width: 100%;
    /* background-color: var(--app-divbgcColor); */
}
.fl-bg{
    /* right: 0; */
    height: 100px;
    width: 100px;
    /* top: -50px; */
    z-index: -1;
    position: absolute;
    opacity: 0.5;
    /* :style="{'--color':b.tagColor}" */
    background-image: var(--bgImage);
    background-image: url();
}
.fl-bg::before{
	content: "";
	position: absolute;
	z-index: 1;  
	width:100%;
	height: 100%;
  
	/* background-color: var(--blog-gonxiang-tm); */
	background-size: 100% 100%  ;         
	background-position: center;
	background-size: cover;
	opacity: 0.8; 
}
.fl-bg img{
    height: 100%; 
}
.wenz-fl{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    padding: 10px;
}

.wzfl-one{
    display: flex;
    /* text-align: center; */
    /* justify-content: center; */
    /* align-items: center; */
    /* margin: 0 10px; */
    /* height: 100px; */
    margin-bottom: 10px;
    color: var(--font-color);
    /* width: 25%; */
    /* background-color: rgb(78, 78, 78); */
}
.wzfl-one h1{
    font-size: 25px;
    /* padding-top: 20px; */
}
.wzfl-one sup{
    /* padding: 10px; */
    font-size: 15px;
    font-weight: 600;
}
.wzfl-two{
    position: relative;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    display: flex;
    flex-wrap: wrap;
    /* padding: 20px; */
    /* width: 100%; */
    /* background-color: aqua; */
    transition: all 400ms ease 0ms;
    /* border: 2px dashed var(--font-color); */
}
.wzfl-two .wzfl-zi{
    /* padding: 8.5px; */
    border-radius: 8px;
    transition: .3s;
    /* background-color: aqua; */
    /* border: 1px solid rgb(167, 167, 167); */
    margin:8px;
	/* background-repeat: no-repeat; */
    cursor: pointer;
    /* background-color: var(--app-divbgcColor); */
    /* display:inline-block; */
    /* vertical-align:middle; */
    /* align-items: flex-start; */
    display: flex;
    /* display: inline-block; */
    border: 1px solid rgb(114, 114, 114);
    position: relative;
    padding: 10px;
} 
.wzfl-two .wzfl-zi span{
    font-size: 15px;
}
.wzfl-two .wzfl-zi sup{
    font-size: 10px;
    padding-left: 3px;
    margin-top: -3px;
}

.wzfl-two .wzfl-zi:hover {
    cursor: pointer;
    color:white;
    transition: 0.3s;
    /* background: var(--app-allColor); */
    
    /* border: 1px solid var(--app-allColor); */
    box-shadow: inset 0px 0px 25px var(--color);
    /* -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -o-transform: translateY(-8px);
    -ms-transform: translateY(-8px); */
    transform: translateY(-8px);
    /* background-color: #dadde2cb; */
    background-color: #ffffffe0;

    border: 1px solid var(--app-allColor);
    color: var(--app-allColor);
}

/* @media (max-width:880px) {	
    .wenz-fl{
        margin-left: 10px;
        margin-right: 10px;
    }
} */
@media (max-width:500px) {		
    .wzfl-two .wzfl-zi{
        padding: 5px;
        border-radius: 5px;
    } 
    .wzfl-two .wzfl-zi span{
        font-size: 12px;
    }
    .wzfl-two .wzfl-zi sup{
        font-size: 8px;
    }
}
/* 
.wzfl-two .wzfl-zi{
    margin:10px;
    border-radius: 10px;
    width:  calc(100% / 2 - 22px);
    height: 120px;
    color: var(--font-color);
    border-bottom :1px solid rgba(167, 167, 167, 0.5);
	border-left :1px solid  rgba(167, 167, 167, 0.5);
    background-image: url(../../assets/logo/R-C.jpg);
    background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

} 
.wzfl-two .wzfl-zi div {
    display: grid;

    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: linear-gradient(to right,var(--app-divbgcColor)25%, rgba(0, 0, 0, 0));
}
.wzfl-two .wzfl-zi div span{
    width: 200px;
    font-size: 15px;
    font-weight: 600;
    margin: 20px;
}

.wzfl-zi:hover.wzfl-zi div span{
    margin-top: 18px;
} */


</style>