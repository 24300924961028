<template>
    <div class="gongxiang-top">
        <div class="gongxiang-top-zi faguang" >
            <!-- 我是共享 -->
            <img v-if="togonggongTop.page_bg" v-lazy="files.myblogImageUrl+togonggongTop.page_bg" alt="">
            <img v-else v-lazy="togonggongTop.bqflImage" >

            <div class="everyMessage">
                {{ togonggongTop.msg }}
            </div>
            
            <div v-if="togonggongTop.page_title"  class="everyTitle">
                    <h1>{{ togonggongTop.page_title }}</h1>
                    <!-- <sup>53</sup> -->
            </div>
        </div>
            
    </div>
</template>

<script>
export default {
    name:'GongxiangTop',
    props: {
        togonggongTop: {               
            type: Object,
            required: true,
        },
    },
    data(){
        return{
            // jieshoufuData:''
        }
    },
    mounted(){
        // console.log(this.togonggongTop.bqflImage)
        // this.jieshoufuData=this.togonggongTop
    }
}
</script>

<style>
.gongxiang-top{
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* text-align: center; */
    overflow: hidden;
    /* position: relative; */
    /* width:calc(100% - 40px); */
	/* top:calc( -500px + 75px); */
	height: 500px;
    transition: .3s;
    /* margin-top: 70px; */
    /* background-color: rgba(0, 0, 0, 0.486); */
    /* position: absolute; */
}
.gongxiang-top-zi{
    /* position: relative; */
    box-shadow: 0px 5px  10px 2px rgba(238, 232, 170, 0);
    top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    position: relative;
    width:calc(100% - 20px);
    height: 420px;
    overflow: hidden;
}
.gongxiang-top img{
    /* position: absolute; */
    object-fit: cover;
    height: 100%;
    width: 100%;
    /* border-radius: 10px; */
    position: absolute;
}

.gongxiang-top img[lazy="loading"]{
  display:block;
  width:100px !important;
  height:100px!important;
  /* margin:0 auto; */
}
.gongxiang-top img[lazy="error"]{
  display:block;
  width:150px !important;
  height:150px !important;
  /* margin:0 auto; */
}
.gongxiang-top-zi::before{
	content: "";
	/* border-radius: 10px; */
	position: absolute;
	z-index: 1;  
	width:100%;
	height: 100%;
	background-color: var(--blog-gonxiang-tm);
	background-size: 100% 100%  ;         
	background-position: center;
	background-size: cover;
	opacity: 1; 
}
.everyMessage{
    position: absolute;
    left: 10px;
    top: 10px;
    font-family:"Times New Roman",Times,serif;
    font-size: 12px;
    font-weight: 300;
    color: rgb(214, 214, 214);
}
.everyTitle{
    bottom: 10px;
    left: 10px;
    position: absolute;  
    display: flex;
    background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 10px 20px;
    border-radius: 5px !important;
	border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.195);
	background:var(--card-tm-bg);
    backdrop-filter: blur(5px);
}
.everyTitle h1{
    font-size: 22px;
    letter-spacing: 2px;
    text-shadow: 0px 0px 1px rgb(222 222 222 / 74%), 0px 4px 4px rgb(0 0 0 / 44%);
	color: #ffffff;
    font-weight: 600;
}
.everyTitle sup{
    padding-left: 5px;
    font-weight: 600;
}
@media (max-width:880px) {	
    .gongxiang-top{
	    height: 350px;
        transition: .3s;
    }
    .gongxiang-top-zi{
        width:calc(100% - 20px);
        height: 270px;
        /* top:calc( -350px + 75px); */
    }
}
@media (max-width:500px) {	
    .gongxiang-top{
        transition: .3s;
	    height:280px;
    }
    .gongxiang-top-zi{
        height: 200px;
    }
    .everyTitle h1{
        font-size: 15px;
    }
}

</style>