import request from "@/utils/requests";
export const getws=() =>request(
    {url:`/webSetting`,
    method:'get',
    // data:param
})

export const blogIndex=() =>request(
    {url:`/blogIndex`,
    method:'get',
    // data:param
})