import request from "@/utils/requests";

export const getMessageIp=() =>request(
    {url:`/ms/ip`,
    method:'get',
    // data:param
})

export const addMessage=(param) =>request(
    {url:'/ms/add',
    method:'post',
    data:param
})

export const getMessageList=() =>request(
    {url:`/ms/list`,
    method:'get',
    // data:param
})