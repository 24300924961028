
const blogSetting={
    state:{
        // 主题颜色
        // skin:'',//light浅色模式  //dark深色模式
        
        // 背景壁纸
        blogBg:'',
        user:'',
        webSetting:'',

        // articleIndex:''
    },
    getters:{
        blogBg:state=>{
            let blogBg=state.blogBg
            if(!blogBg){
                blogBg=JSON.parse(window.localStorage.getItem('blogBg'))
            }
            return blogBg
        },
        user:state=>{
            let user=state.user
            if(!user){
                user=JSON.parse(window.sessionStorage.getItem('user'))
            }
            return user
        },
        webSetting:state=>{
            let webSetting=state.webSetting
            if(!webSetting){
                webSetting=JSON.parse(window.sessionStorage.getItem('webSetting'))
            }
            return webSetting
        },

        // articleIndex:state=>{
        //     let articleIndex=state.articleIndex
        //     if(!articleIndex){
        //         articleIndex=JSON.parse(window.sessionStorage.getItem('articleIndex'))
        //     }
        //     return articleIndex
        // },
    },
    mutations:{
        setBlogBg:(state,blogBg)=>{
            state.blogBg=blogBg;
            window.localStorage.setItem('blogBg',JSON.stringify(blogBg))
        },
        setUser:(state,user)=>{
            state.user=user;
            window.sessionStorage.setItem('user',JSON.stringify(user))
        },
        setWebSetting:(state,webSetting)=>{
            state.webSetting=webSetting;
            window.sessionStorage.setItem('webSetting',JSON.stringify(webSetting))
        },

        // setArticleIndex:(state,articleIndex)=>{
        //     state.articleIndex=articleIndex;
        //     window.sessionStorage.setItem('articleIndex',JSON.stringify(articleIndex))
        // },

    }
}

export default blogSetting