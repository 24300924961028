<template>
	<div style="justify-content: center;align-items: center;position: relative;">
		<article-bj-and-tit :toBiaotiTop="{
			bgi:article.articleCover,
			tags:article.lTags,
			category:article.lCategory,
			ctime:article.createTime,
			utime:article.updateTime,
			read:article.reading,
			toZiSum:ziSum,
			title:article.articleTitle}">
		</article-bj-and-tit>

		<div class="article-index" >

			<div class="left-chuankou" v-if="titleList.length!=0" style="border-right:1px solid #7474748e;">
				<!-- <HeadAndSJ></HeadAndSJ> -->
				<div class="ck-left">					
					<!-- 目录 -->
					<div class="mulu faguang"  >
						<div class="mulu-top">
							<span class="iconfont icon-liebiao1"> </span> 目录						
						</div>
						<div class="mulu-zi">			
							<div class="catalog-item" v-for="(anchor, index) of titleList" :key="index"
								:class="currentIndex === index ? 'active' : ''" :style="{ paddingLeft: `${5 + anchor.indent * 15}px` }"
								>
								<span style="cursor: pointer;" @click="handleAnchorClick(anchor, index)"> {{ anchor.title }} </span>
							</div> 
						</div>
					</div>
					
					<!-- <web-notice></web-notice> -->
					<!-- 最新评论 -->
					<!-- <NewComments style="position:  sticky;"></NewComments> -->

				</div>
				
			</div>

			<div class="right-chuankou">
				<!-- 文章内容 -->
				<div class="article faguang">
					<!-- <div class="wz-xinxi">			
					</div> -->
					<!-- 文章内容 -->
					<article id="html-text"  class="html-text" v-html="this.$Base64.decode($route.query.B) || article.articleText" @click="imageChagange($event)">
					</article>
					<!-- 文章作者&&版权 -->
					<div class="article-auther-inf">
						<div>
							<span class="auther-inf-l">
								作者：
							</span>
							<span class="auther-inf-r">								
								<a href="http://www.xiangmeng.xyz" style="color:var(--app-allColor)">{{ user.nickname }}</a>
							</span>
						</div>					
						<div>
							<span class="auther-inf-l">
								版权声明：
							</span>
							<span class="auther-inf-r">
								本博客所有文章除特别声明外，其余均为博主原创。转载请注明出处！
							</span>
						</div>
						
						<i class="iconfont icon-tongzhi"></i>
						
					</div>

					<div class="fxiang-or-zandashang">
							<!-- 分享与转发 -->
						<div class="article-fxiang">
							<i class="iconfont icon-QQ" style="background-color: #12B7F5;"></i>
							<i class="iconfont icon-weixin" style="background-color: #07C160"></i>			
						</div>

						<!-- 点赞或其他 -->
						<div class="article-zanordashang">
							<i class="iconfont icon-appreciate_fill_light">
								<sup>{{ article.zan }}</sup>
							</i>
							<el-popover
                        	:visible-arrow="false"
							placement="left"
							trigger="hover">
								<div>
									<img style="height: 120px;width:120px" src="../../assets/image/wxzanShang.png" alt="">
									
									<a href="https://qr.alipay.com/fkx14660gnlflomnjwrddc4">
										<img class="zfb" style="cursor: pointer; height: 50px;width:50px;padding:5px; border-radius: 50%;margin-bottom:10px" src="../../assets/image/zfb.png">
									</a>
								</div>

								<i slot="reference" class="iconfont icon-dashang"></i>
							</el-popover>

							
						</div>
					</div>
					
					<!-- 上一篇或下一篇 -->
					<div class="previous-or-next">

						<div class="article-previous pon" v-if="previousArticle" >
							<img :src="files.myblogImageUrl+previousArticle.articleCover">
							<div class="pon-title">
								<!-- <div> -->
									<span class="el-icon-caret-left"></span>
									<div class="previous-next-title" @click="toPervious(previousArticle.id,previousArticle.articleText,previousArticle.articleTitle)">
										{{ previousArticle.articleTitle }}
									</div>
									<div class="to-previous-next">
										上一篇
									</div>
								<!-- </div> -->								
							</div>
						</div>
						<div class="article-next pon" v-if="nextArticle">
							<img :src="files.myblogImageUrl+nextArticle.articleCover">
							<div class="pon-title">
								<!-- <div>					 -->
									<div class="previous-next-title" @click="toNext(nextArticle.id,nextArticle.articleText,nextArticle.articleTitle)">
										{{ nextArticle.articleTitle }}
									</div>
									<div class="to-previous-next" >
										下一篇
									</div>
									<span class="el-icon-caret-right"></span>
								<!-- </div> -->
							</div>
						</div>
					</div>
					<div>

					</div>
				</div>
				<!-- 评论 -->
				<!-- <comment :article_id="$route.query.id"></comment> -->
				<MyTwiKoo :article_id="$route.query.id"></MyTwiKoo>
			</div>
		</div>

		<!-- v-html解析图片点击浏览 -->
		<transition
            enter-active-class="animate__animated animate__fadeIn 0.5s"
            >
            <image-viewer
                v-if="showViewer"
                :url-list="srcListView"
                :on-close="closeViewer"
                :z-index="3000"
            />
        </transition>
	</div>

</template>

<script>
import { useScroll } from '@vueuse/core';
import WebNotice from './body-cp/WebNotice.vue';
import HeadAndSJ from './body-cp/HeadAndSJ.vue';
// import Comment from './article/Comment.vue';
import ArticleBjAndTit from './article/ArticleBjAndTit.vue';
// import NewComments from './body-cp/NewComments.vue';
import MyTwiKoo from './twikooComment/MyTwiKoo.vue'
import { addReading, getArticleIndex } from '@/api/article';
import ImageViewer from 'element-ui/packages/image/src/image-viewer'
import hljs from 'highlight.js'
import {mapGetters} from 'vuex';

export default {
  	components: { 
		WebNotice, 
		HeadAndSJ, 
		// Comment, 
		MyTwiKoo,
		ArticleBjAndTit, 
		// NewComments,
		ImageViewer},
	name:'ArticleIndex',
	computed:{
		...mapGetters([
			'user'
		]),
    },
	data(){
		return{	
			article:'',
			nextArticle:'',
			previousArticle:'',
			// htmlData:'<pre><code class="language-javascript">htmldata2(newName, oldName) {console.log("新的",newName)console.log("老的",oldName)this.upMOD()}</code></pre><p><br></p>',
			// htmlData:'<h1>biaoto1</h1><h3>我是耳机</h3><p>的手机jdfsklllllllllllllllllllllllllajksjafdjsfklasdfhkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkdisjfksfsdjfisdi大四还或嗲或佛is啊或覅偶偶偶偶多付或多所所所所所所所所所所所所所所所所所所所所所所所所所所所所所所所军多多娇滴滴的多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多娇滴滴的多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多号的撒好几十个号分公司甲方公司将分公司加工费技术规范书借古讽今施工方几十个梵蒂冈司法解释国防建设国防建设打个飞机大商股份急急急急急军军军军军军军军军军军军军军军军军军军多多多</p><p>yiji</p><h1>一级在线</h1><h3>二级在线</h3><p>h红红火火恍恍惚惚或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或或</p>',
			// htmlData:'<h1 style="text-align: start;">什么是二级域名？二级域名如何注册申请？</h1><p>什么是二级域名？</p><p><br></p><p><span style="color: rgb(66, 144, 247);"><strong>按照严格的标准来划分，顶级域名的下一级才是二级域名。其中顶级域包括.com、.net这些通用顶级域和.cn、.jp这样的国别域名。而这些顶级域的下一级就是二级域名，如baidu.com、sfn.cn等。二级域名通常作为企业进行推广的主域名存在，往往与企业名称、产品、业务有一定的关联性。</strong></span></p><p><br></p><p>但现在很多网站运营者为了便于理解，通常将.com、.cn称作顶级域，而将baidu.com称作一级域名，将www.baidu.com称为二级域名，这其实是一种误解。</p><p>————————————————</p><p>版权声明：本文为CSDN博主「中科三方」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。</p><p>原文链接：https://blog.csdn.net/weixin_53018687/article/details/127984009</p><p><br></p><pre><code class="language-css">.article-bj .article-bj-zi{position: relative;	/* min-height: 300px; */	padding: 30px;	width: 60%;   /* display: flex; */   justify-content: center;  align-items: center;    /*设置阴影*/ box-shadow: 20px 20px 50px rgba(0,0,0,0.5);   /*设置圆角*/    border-radius:10px;   /*设置上边界和左边界亮纹，使card有一种层次感*/   border-top: 1px solid rgba(255,255,255,0.5) border-left: 1px solid rgba(255,255,255,0.5);/* border-bottom: 1px solid rgba(255,255,255,0.5); *//*设置背景样式*/ /* background: rgba(255,255,255,0.1); */background: rgba(0, 0, 0, 0.205);/*让透过card的底部元素模糊化,达到毛玻璃效果*/backdrop-filter: blur(10px);}</code></pre><p><br></p>',
			// htmlData:'<h1 style="text-align: start;">什么是二级域名？二级域名如何注册申请？</h1><h2>耳机h2</h2><p><span style="color: rgb(231, 95, 51);">Ajax&nbsp;异步设置内容&nbsp;HTML</span></p>',
			// 目录
			titleList:'',			
	 		currentIndex :0,
			// 预览图片
			showViewer: false,
			gdy:0,

			copydata:'',

			// 总字数
			ziSum:''

		}
	},
	methods:{
		// 获取当前文章，下一篇，上一篇
		getArticleById(){
			getArticleIndex(this.$route.query.id).then((res)=>{
				// console.log("文章",res.data)
				this.article=res.data.article
				this.nextArticle=res.data.nextArticle
				this.previousArticle=res.data.previousArticle
			})
		},

			// 获取目录
		getTitles(){
			this.titleList=''
			const anchors =document.querySelectorAll('.html-text h1,.html-text h2,.html-text h3')
			anchors.forEach((i)=>{
				// i.
			})
			const titles = Array.from(anchors).filter((t) => !!t.innerText.trim())
			// console.log("anchors:",anchors)
			// console.log("titles:",titles)
			if (!titles.length)
				this.titleList = []
			const hTags = Array.from(new Set(titles.map((t) => t.tagName))).sort()
			this.titleList = titles.map((el, idx) => {			
			return {
				title: el.innerText,			
				lineIndex: el.getAttribute('data-v-md-line'),
				indent: hTags.indexOf(el.tagName),
				}
			})
			// console.log("titleList:",this.titleList)
		},

		  // 点击锚点目录
		handleAnchorClick(anchor, idx) {		
			// console.log("achor:",anchor)
			// console.log("idx:",idx)
			// this.currentIndex = idx
			// const heading = document.querySelector(`[data-v-md-line="${anchor.lineIndex}"]`)
			const heading = document.querySelectorAll('.html-text h1,h2,h3')
			// const heading = preview.querySelector(`#${anchor.title}`)
			// console.log("heading[]",heading[idx])
			// heading[idx].scrollIntoView({	
			// 	block: 'start',//顶部对其
			// 	behavior: 'smooth',//平滑过渡				
			// })	
			// var h = document.documentElement.clientHeight;//获取页面可见高度  		
			if (heading[idx]) {
				window.scrollTo({
					top:  heading[idx].offsetTop +460 ,	
					behavior: 'smooth',
				})
				// this.currentIndex = idx
				setTimeout(() =>{this.currentIndex = idx}, 500)				
			}	
			// this.currentIndex = idx
			// console.log("这是currentIndex",this.currentIndex)
		},

		//富文本渲染文本点击
		imageChagange(e){ 
            if (e.target.nodeName === 'IMG'|| e.target.nodeName == 'img') { //判断是否图片标签
                const img = e.target.currentSrc //获取点击图片地址
                this.onPreview([img]) 
                // this.srcList.push(img)
                // console.log('我是img:',this.srcList)
            }
        },
        //开启富文本图片预览
        onPreview(img) {
            this.showViewer = true;
            this.srcListView = img
            // const m = (e) => { e.preventDefault() };
            document.body.style.overflow = 'hidden';
            // document.addEventListener("touchmove", m, false); // 禁止页面滑动
        },
        //关闭富文本渲染文本图片预览
        closeViewer() {
			this.showViewer = false;
			// const m = (e) => { e.preventDefault() };
			// console.log("我关掉了")
			document.body.style.overflow = 'auto';
			// document.removeEventListener("touchmove", m, true);
        },

		// 上一篇
		toPervious(id,text,isTitie){
			// console.log(id)
			this.$router.push({
                path:'/article',
				query:{
					id:id,
					title:isTitie,
					B:this.$Base64.encode(text),
					t:Date.now()
				}, 
            })
		},
		// 下一篇
		toNext(id,text,isTitie){
			// console.log(id)
			this.$router.push({
                path:'/article',
				query:{
					id:id,
					title:isTitie,
					B:this.$Base64.encode(text),
					t:Date.now()
				}, 
            })
		},

		getZiSum(){
			var sum=document.getElementById("html-text").innerText
			this.ziSum= sum.length
		},

		upMOD(){
				const ifms=document.querySelectorAll(".html-text iframe")
                ifms.forEach((item) => {
                    let pre = item.parentElement;
                    // console.log("呀呀呀111",pre)
                    pre.setAttribute('style', 'position: relative;padding: 30% 45%;')
                })
			// this.$nextTick(() => {     
				const code = document.querySelectorAll('pre code');
				// const code=document.getElementsByTagName("pre")
				// console.log('这是code',code)
				code.forEach((item) => {
					// 取出 code 的父元素 pre（后面方便使用）
					let pre = item.parentElement;
					// this.dragAround(item);
					// 新建元素 代码块序号
					let lineNumBox = document.createElement('div');
					lineNumBox.setAttribute('style', 'height: ' + item.offsetHeight + 'px');
					lineNumBox.className = 'line-num-box';
					// 插入序号 计算方式：获取code元素的高并除以行高，得到行数，根据行数插入序号
					let num = '';  // 设行高二十
					for (let i = 1; i <= Math.ceil(item.offsetHeight / 28); i++) {
						num += i + '\n'; // 序号加上换行符
					}
					lineNumBox.innerText = num;// 插入序号
					item.parentElement.insertBefore(lineNumBox, item);

					let codeBox = document.createElement('div');
					codeBox.className = 'code-box';
					codeBox.appendChild(item);

					pre.appendChild(codeBox);

					let lang = pre.lastElementChild.firstElementChild.className;
					// console.log("lang:",lang)
					let icon = `<div class="mac-icon">` +
						`<span class="mac-icon-red"></span>` +
						`<span class="mac-icon-yellow"></span>` +
						`<span class="mac-icon-green"></span>` +
						// `<span class="mac-icon-yuan"></span>` +
						`<span class="mac-icon-lang">${lang ? lang.split('-')[1].toUpperCase():''}</span>` +
						`<button class="copy-button">复制</button>` +
						// `<button class="full-screen-button">全屏</button>` +
						`</div>`;
					pre.insertAdjacentHTML('afterbegin', icon);

					// 获取复制元素
				//	let copyButton = pre.firstElementChild.getElementsByClassName('copy-button')[0];
				//	copyButton.onclick = function () {
				//		// https://developer.mozilla.org/zh-CN/docs/Web/API/Clipboard/writeText
					//	const copyPromise = navigator.clipboard.writeText(pre.lastElementChild.innerText);
					//	copyPromise.then(() => {
					//		this.$message({
					//			message:"复制成功",
					//			type: 'success',
					//			duration:1000,
					//		});
					//	}).catch(() => {
					//		alert('复制失败');
					//	});
					//};
					// 获取复制元素
					const copyButton = pre.querySelector('.copy-button');
					

					copyButton.onclick = () => {
						navigator.clipboard.writeText(pre.querySelector('code').innerText)
							.then(() => {
								this.$message({
									message: "复制成功",
									type: 'success',
									duration: 1000,
								});
							})
							.catch(() => {
								alert('复制失败');
							});
					};
					hljs.highlightBlock(codeBox.firstElementChild);		
				});						
			// });	
		}

        // upMOD(){
        //     this.$nextTick(() => {             
        //     // setTimeout(() => {
        //         const code = document.querySelectorAll('.article pre code');
        //         // const code=document.getElementsByTagName("pre")
        //         // console.log('这是code',code)
        //         code.forEach((item) => {
        //             // 取出 code 的父元素 pre（后面方便使用）
        //             let pre = item.parentElement;
        //             // this.dragAround(item);
        //             // 新建元素 代码块序号
        //             let lineNumBox = document.createElement('div');
        //             lineNumBox.setAttribute('style', 'height: ' + item.offsetHeight + 'px');
        //             lineNumBox.className = 'line-num-box';
        //             // 插入序号 计算方式：获取code元素的高并除以行高，得到行数，根据行数插入序号
        //             let num = '';  // 设行高二十
        //             for (let i = 1; i <= Math.ceil(item.offsetHeight / 25); i++) {
        //                 num += i + '\n'; // 序号加上换行符
        //             }
        //             lineNumBox.innerText = num;// 插入序号
        //             item.parentElement.insertBefore(lineNumBox, item);
        //             let codeBox = document.createElement('div');
        //             codeBox.className = 'code-box';
        //             codeBox.appendChild(item);
        //             pre.appendChild(codeBox);
        //             let lang = pre.lastElementChild.firstElementChild.className;
        //             console.log("lang:",lang)
        //             let icon = `<div class="mac-icon">` +
        //                 `<span class="mac-icon-red"></span>` +
        //                 `<span class="mac-icon-yellow"></span>` +
        //                 `<span class="mac-icon-green"></span>` +
        //                 // `<span class="mac-icon-yuan"></span>` +
        //                 `<span class="mac-icon-lang">${lang.split('-')[1].toUpperCase()}</span>` +
        //                 `<button class="copy-button">复制</button>` +
        //                 // `<button class="full-screen-button">全屏</button>` +
        //                 `</div>`;
        //             pre.insertAdjacentHTML('afterbegin', icon);

        //             // 获取复制元素
        //             let copyButton = pre.firstElementChild.getElementsByClassName('copy-button')[0];
        //             copyButton.onclick = function () {
        //                 // https://developer.mozilla.org/zh-CN/docs/Web/API/Clipboard/writeText
        //                 const copyPromise = navigator.clipboard.writeText(pre.lastElementChild.innerText);
        //                 copyPromise.then(() => {
        //                     alert('复制成功');
        //                 }).catch(() => {
        //                     alert('复制失败');
        //                 });
        //             };
        //             // Hljs.highlightBlock(codeBox.firstElementChild);               
        //         });
        //         // },3000) 
        //     });
        // },

	},
	async mounted() {

	},

	mounted(){	
		this.upMOD()
		setTimeout(() => {
			// 获取目录
			this.getTitles()	
			// 获取总字数
			this.getZiSum()
		},200) 
	
		setTimeout(() => {
			addReading(this.article.id)
			// 在这里执行页面停留时长的操作，比如发送请求或执行某些逻辑
		}, 300000); // 设置停留时长超过五分钟阅读+1

		this.gdy=(useScroll(window)).y
		// console.log("gdy:",this.gdy)
		// this.getbjinf()		
		// window.onscroll=(()=>{				
		// 	// console.log("滚动")
		// 	// console.log("gg",this.gdy)
		// 	// console.log(window.pageYOffset);
		// 	this.titleList.forEach((e, idx) => {
		// 	// console.log("e",e)
		// 	// console.log("idx",idx)
		// 	const heading =document.querySelectorAll('.html-text h1,h2,h3')
		// 	// var h = document.documentElement.clientHeight;//获取页面可见高度  
		// 	// console.log("高度",h)
		// 	// console.log("g",this.gdy)
		// 	// console.log("he",heading[idx].offsetTop)
		// 	if (this.gdy >= heading[idx].offsetTop +500){ // 比 40 稍微多一点
		// 	// heading[this.currentIndex]=idx
		// 		this.currentIndex= idx
		// 		}
		// 	})
		// })		
	},

	created(){
		this.getArticleById()
	},

	watch:{
		gdy(newName, oldName) {
		// console.log("新的",newName)
        // console.log(newName,oldName)
			this.titleList.forEach((e, idx) => {
			// console.log("e",e)
			// console.log("idx",idx)
			const heading =document.querySelectorAll('.html-text h1,h2,h3')
			// var h = document.documentElement.clientHeight;//获取页面可见高度  
			// console.log("高度",h)
			if (this.gdy >= heading[idx].offsetTop +450) // 比 40 稍微多一点
			// heading[this.currentIndex]=idx
				this.currentIndex= idx
			})
		}
	},
	
	beforeDestroy() {
		clearTimeout(this.timer); // 清除定时器
	}
	

}
</script>

<style>
.zfb:hover{
	background-color: rgba(172, 172, 172, 0.603);
}
.article-index{
	/* z-index: 0; */
    display: flex;
	/* width: 1180px; */
	/* border:1px solid black ; */
	/* min-height: 100px;
	margin: 0 auto;
    padding: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: 1250px; */
	margin-top: 70px;
    /* background-color: rgba(250, 235, 215, 0.308); */
	padding: 10px;
}

.left-chuankou{	
	/* padding: 10px; */
	padding-left: 10px;
	margin-top: 10px;
	margin-right: 10px;
	/* margin: 10px; */
    width: 25%;
	/* height: 500px; */
	transition: all 400ms ease 0ms;
	/* background-color: #353535; */	
}
.ck-left{
	/* min-width: 230px; */
	/* position: -webkit-sticky; */
	position:  sticky;	
	top: 60px;
	margin-bottom: 10px;
}
.mulu{
	/* margin-top: 20px; */
    width: calc(100% - 20px);
	padding: 10px;
	min-height: 100%;
	/* position: sticky; */
	/* min-height:100px; */
	/* background-color: var(); */
	border-radius: 10px;
	/* border-bottom:1px solid #7474748e; */
	/* height: 200px; */	
}
.mulu .mulu-top {
	padding: 10px;
	font-size: 18px;
	border-bottom:  1px solid  var(--font-color);
}
/* .mulu .mulu-top span{
	font-size: 1.2rem;	
} */
.mulu .mulu-zi{
	border-left: 1px solid var(--font-color); 
	max-height: 75vh;
	overflow: auto;
	letter-spacing: 1px;
    overflow: auto; 
	/* position: absolute; */
	margin: 15px;
	/* display: inline-block; */
	padding: 10px;
    /* width: calc(100% - 40px); */

	/* width: 100%; */
	/* background-color: #15a1ff; */
}
.mulu .mulu-zi::-webkit-scrollbar{
    /*width: 100px !important;*/
    width: 5px;
	height: 5px;
    /* border-radius: 5px; */
    background-color: rgba(255, 255, 255, 0.319); 
	/* border-right: 1px solid  rgba(167, 167, 167, 0.5); */
	margin-right: 20px;
}
.mulu .mulu-zi::-webkit-scrollbar-thumb{/*滚动条里面小方块*/
    border-radius: 5px;
    background-color:  var(--font-color);
}
/* 目录点击高亮 */
.mulu .catalog-item {	
	margin: 5px 0;
	transition: .3s;
	/* width: calc(100% - 0px); */
	/* width: 100%; */
	/* transition: all 0.2s ease-in-out; */
	font-size: 14px;
	padding: 2px 6px;
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: #00ffff00;
	padding: 5px;
}
.mulu .catalog-item :hover {
	color: var(--app-allColor);
}
.mulu .active {
	padding: 5;
	background-color: var(--app-allColor);
	border-radius: 5px !important;
	color:#f4f7f7;
}
.mulu .active :hover {
	color:#ffffff;
}

.right-chuankou{
	/* margin: 10px; */
	padding-right: 10px;
    /* width: calc(100% - 250px); */
	flex: 1;
	/* margin: 10px; */
	/* padding: 10px; */
	margin-left: 10px;
	margin-top: 10px;
	/* background-color: #00FFFF; */
	transition: all 400ms ease 0ms;
}
.article{
	/* margin-top: 10px; */
	/* padding: 20px; */
	width:100%;	
}

.html-text{
	padding: 10px;
}

/* 作者转载表明 */
.article .article-auther-inf{
	margin: 15px;
	padding: 15px;
	/* background-color: crimson; */
	border-radius: 10px 10px 10px 10px;
	border: 2px solid  rgba(167, 167, 167, 0.5); 
	position: relative;
}
.article .article-auther-inf div{
	font-size: 14px;
	letter-spacing: 1px;
	margin: 5px;
}
.article .article-auther-inf .auther-inf-l{
	/* font-size: 17px; */
	font-weight: 600;
}
/* .article .article-auther-inf .auther-inf-r{

} */
.article .article-auther-inf i{
	position: absolute;
	color: rgba(167, 167, 167, 0.5); 
	top: 10px;
	right: 10px;
}
.article .article-auther-inf:hover.article-auther-inf i{
	transition: .3s;
	color: var(--app-allColor);
}

/* 转发分享和赞打赏 */
.fxiang-or-zandashang{
	position: relative;
	height: 50px;
	/* display: flex; */
	margin: 10px;
}
/* 转发分享 */
.article-fxiang{
	position: absolute;
	left: 10px;
	height: 100%;
	/* width: 30%; */
	display: flex;
	justify-content: center;
    align-items: center;
	/* padding: 10px; */
	/* width: 0%; */
	/* background-color: #353535; */
}
.article-fxiang .iconfont{
	border:  1px solid  rgba(167, 167, 167, 0.5);
	border-radius: 50%;
	color: #ffffff;
	padding: 5px;
	margin: 0 10px;
	font-size: 22px;
}
.article-zanordashang .icon-appreciate_fill_light:hover,
.article-fxiang .iconfont:hover{
	cursor: pointer;
	transition: .3s; 
    border: 1px solid  var(--app-allColor);
    color:  var(--app-allColor);  
	background-color: #ffffff !important;
}
/* 赞或打赏 */
.article-zanordashang{
	right: 10px;
	position: absolute;
	height: 100%;
	/* width: 30%; */
	display: flex;
	justify-content: center;
    align-items: center;
	/* display: flex; */
}
/* .article-zanordashang{
	margin: 0 15px;
} */
.article-zanordashang i{
	border:  1px solid  rgba(167, 167, 167, 0.5);
	border-radius: 50%;
	/* color: #ffffff; */
	padding: 5px;
	margin: 0 10px;
	font-size: 25px;
	position: relative;
}
.article-zanordashang .icon-appreciate_fill_light sup{
	position: absolute;
	text-align: center;
	font-weight: 600;
	top: -5px;
	right: -5px;
	font-size: 12px;
}

.article-zanordashang .icon-dashang:hover{
	cursor: pointer;
	color: rgb(206, 161, 12);
	transition: .3s; 
    border: 1px solid  var(--app-allColor);
	animation: myfirst1 5s infinite;	
}

@keyframes myfirst1 {
    0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }

}

.article .previous-or-next{
	display: flex;
	margin: 10px;
}

.pon{
	margin: 10px;
	height: 150px;
	width: calc(100% /2 - 20px);
	position: relative;
	overflow: hidden;
	border-radius: 10px 10px 10px 10px;
	/* border: 2px solid  rgba(167, 167, 167, 0.5); ; */
}
.pon img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute; 
}

.pon:hover.pon img{
	cursor: pointer;
	animation-name: blur; /*动画名称*/
	animation-duration:3s; /*动画持续时间*/
	animation-iteration-count:inherit; /*动画次数*/
}

/* @keyframes blur {
	0% {
	 filter: blur(10px); 
	-moz-filter: blur(10px);
	-ms-filter: blur(10px);
	filter: blur(10px);
	}
	50% {
	 filter: blur(5px);
	-moz-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
	}
	100% {
	 filter: blur(0px);
	-moz-filter: blur(0px);
	-ms-filter: blur(0px);
	filter: blur(0px);
	}
} */

.pon .pon-title{
	text-align: center;
	background: rgba(46,46,46,0.18000703699448528) ;
	/* background: var(--card-bg); */
	color:#ffffff;
	height: 100%;
	width: 100%;
	position: relative;
	cursor: pointer;
}
.pon:hover .pon-title{
	transition: 0.5s ease-out;
	background: linear-gradient(350deg, rgba(46,46,46,0.8886905103838411) 0%, rgba(46,46,46,0.8354692218684349) 19%, rgba(46,46,46,0.18000703699448528) 100%);
	/* background: linear-gradient(350deg, rgba(46,46,46,0.8718837876947654) 0%, rgba(46,46,46,0.8354692218684349) 27%, rgba(46,46,46,0.18000703699448528) 100%); */
}
.article-previous .pon-title span{
	left: 5px;
	font-size: 30px;
	position: absolute;
	display: flex;
	height: 100%;
	align-items: center;
}
.article-next .pon-title span{
	right: 5px;
	display: flex;
	height: 100%;
	align-items: center;
	position: absolute;
	/* background-color: #23e66d; */
}
.pon .pon-title  .previous-next-title{
	width: calc(100% - 60px);
	display: -webkit-box;
    -webkit-box-orient: vertical;
	/* line-height:50px; */
    -webkit-line-clamp:1;
	overflow: hidden;
	padding:0 30px;
	margin-top: 40px;
	position: absolute;
	font-size: 25px;
	font-weight: 600;
	letter-spacing: 2px;
	cursor: pointer;
	/* display: flex; */
	/* background-color: #00FFFF; */
}
.pon .pon-title  .previous-next-title:hover{
	transition: .5s;
	color:var(--app-allColor);
}
.pon .pon-title .to-previous-next{
	/* display: none;	 */
	width: 100%;
    -webkit-box-orient: vertical;
	/* line-height:px; */
	font-size: 15px;
    -webkit-line-clamp:1;
	overflow: hidden;
	/* padding: 10px; */
	top: 90px;
	position: absolute;
	text-align: center;

	transform: translateY(50px);
	transition: 0.3s ease-out;
	/* animation-fill-mode: forwards; */
	opacity: 0;
}
.pon:hover .pon-title div .previous-next-title{
	display: none;
}
.pon:hover .pon-title .to-previous-next{
	transform: translateY(0px);
	opacity: 1;
}


@media (max-width:600px) {	
	.article .previous-or-next{			
		flex-direction: column;
		display: grid;
		/* height: -moz-fit-content */
	}
	/* .fxiang-or-zandashang{
		display: grid;
	} */
	.article-previous{				
		width: calc(100% - 20px);							
	}		
	.article-next{					
		width: calc(100% - 20px);												
	}
	
}
			
@media (max-width:880px) {		
    .left-chuankou{
        display: none;
		padding-left: 0;
     }
    .right-chuankou{
		margin-left: 0;
		padding-right: 0;
        width: 100%;	
    }
}
</style>


<style src='../../assets/css/article/article.css' scoped>

</style>