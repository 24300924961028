import request from "@/utils/requests";
// 分类s
export const getCategoryAll=(search) =>request(
    {url:`/category/categoryList?search=${search}`,
    method:'get',
    // data:param
})
// 标签s
export const getTagAll=(search) =>request(
    {url:`/tag/tagList?search=${search}`,
    method:'get',
    // data:param
})