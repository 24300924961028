<template>
    <div>
		<transition
            enter-active-class="animate__animated animate__fadeInRight"
            leave-active-class="animate__animated animate__fadeOutRight">
			<div 
			title="深色模式" v-if="this.skin == 'light'" 
			id="setSkin" @click="setSkin" style="	
			color: rgb(26, 124, 236);">
				<span class="iconfont icon-yejianmoshi">
				</span>  
			</div>
        </transition>

		<transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut">
			<div 
			title="浅色模式" v-if="this.skin =='dark'" 
			id="setSkin" @click="setSkin"
			style="color: #f7be05;">
				<span 
				class="iconfont icon-baitianmoshimingliangmoshi">
				</span>
			</div>
        </transition>
	</div>
</template>

<script>
export default {
    name:'QieHuanTheme',
    data(){
		return{
			skin:this.$store.state.skin,
		}
	},
    methods:{
		setSkin(){			
			let val = ''
			if(this.skin == 'dark'){ //浅色模式
				val = 'light'
			} else {
				val = 'dark'
				// document.getElementById("blog-boby").style.background="rgba(53, 53, 53, 0.39)"
            	// document.getElementById("danban").style.background=" rgba(53, 53, 53, 0.39)"
			}
			this.skin=val
			this.$store.dispatch('updateSkin',val);// 存储到vuex
			document.getElementById("theme").href = `./css/theme/${val}.css`; //引入对应文件
			
		},
		
	},
	mounted() {
		if(JSON.parse(window.sessionStorage.getItem('skin')))
			this.skin=JSON.parse(window.sessionStorage.getItem('skin'))
		// this.skin=this.$store.state.skin;
		// console.log("App.vue ---- onMounted");
		// console.log("d:",document)
		// console.log("skin",this.skin)
		//方式2（创建link标签默认引入 ./css/theme_1.css 主题样式文件）
		let link = document.createElement("link");
		link.type = "text/css";
		link.id = "theme";
		link.rel = "stylesheet";
		link.href = `./css/theme/${this.skin}.css`;
		document.getElementsByTagName("head")[0].appendChild(link);
  	},
}
</script>

<style>
#setSkin{ 
	height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	position: fixed;
	/* margin-top: 10px; */
	bottom: 166px;
	right: 10px;
	z-index: 99;
	border: 1px solid  #7474748e;
	/* border-top: 1px solid  rgba(167, 167, 167, 0.5);
	border-bottom :1px solid rgba(167, 167, 167, 0.5);
	border-right :1px solid rgba(167, 167, 167, 0.5);
	border-left :1px solid rgba(167, 167, 167, 0.5); */
	/* z-index: 99; */
	
	cursor: pointer;
	padding: 5px;
	background: var( --card-bg);
	border-radius: 50%;
    transition: .3s;

}
#setSkin span{
	font-size: 1.5rem;
}

#setSkin:hover span{
	color: #fff;
}
#setSkin:hover{
    transition: .3s;
	background-color: var(--app-allColor);
    /* background-color:rgba(151, 151, 151, 0.425); */
	/* color:var(--app-divbgcColor); */
}
</style>