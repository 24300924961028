//定义全局变量
const serverUrl="http://www.xiangmeng.xyz/api"
// const serverUrl="http://192.168.43.152:8090/"
// const serverUrl="http://localhost:8090"

const qiNiuLink="http://qny.xiangmeng.xyz/"

const files={
    myblogImageUrl :serverUrl+'/files/image/',
    myblogVideoUrl :serverUrl+'/files/video/'
}


export {
    files,
    serverUrl,
    qiNiuLink
}
