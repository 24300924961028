<template>
  <div class="blog-index">
    <blog-header></blog-header>
    <blog-body></blog-body>
    <blog-footer></blog-footer>
  </div>

</template>

<script>
import BlogHeader from '@/pages/home/BlogHeader.vue'
import BlogBody from '@/pages/home//BlogBody.vue'
import BlogFooter from '@/pages/home/BlogFooter.vue'

export default {
  components: { BlogHeader, BlogBody, BlogFooter },

  name:'BlogIndex'
}
</script>

<style>
.blog-index{
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}
</style>