<template>
    <div style="position: relative;width: 100%; height: 100%; " >

        <gongxiang-top :togonggongTop="{page_title:'相册',page_bg:webSetting.webXcbgi,msg:''}"></gongxiang-top>

        <div class="xc-qiehuan">
            <!-- <el-popover
                placement="bottom"
                :visible-arrow="false"
                width="50"
                trigger="click">
                    <div>
                        <button class="ct-lanmu-but">日常</button>
                    </div>
                    <div>
                        <button class="ct-lanmu-but">壁纸</button>
                    </div>  
                <el-button slot="reference">类型</el-button>
            </el-popover> -->

            <el-collapse>
                <el-collapse-item title="类型" name="1">
                    <div>
                        <button class="ct-lanmu-but" @click="XiangceList(' ')">全部</button>
                    </div>
                    <div>
                        <button class="ct-lanmu-but" @click="XiangceList(1)">日常</button>
                    </div>
                    <div>
                        <button class="ct-lanmu-but" @click="XiangceList(2)">壁纸</button>
                    </div>                  
                </el-collapse-item>
            </el-collapse>
        </div>

        <div v-if="richangdata.length>0"  class="Waterfall wf-content" id="pubu">
            <div class="wf-item" v-for="(item,fuindex) in richangdata" :key="fuindex">

                <div class="xc-zi faguang">
                    <div class="xczi-xctext" style="">
                        {{ item.xcText }}
                    </div>
                    <div v-if="item.xcImages.length==1" class="xc-img"  style="overflow: hidden;position: relative;">
                        <img  alt="" v-lazy="item.xcImages[0]" @load="imageonload" @click="lookbigtupian(fuindex)">
                    </div>

                    <div v-else  style="display: flex;flex-wrap: wrap;width: 100%;height: 100%;">
                        <div  v-for="(rcd,idx) in item.xcImages" :key="idx"  style="width: 50%;overflow: hidden;position: relative;" class="xc-img">
                            <img  v-lazy="rcd" alt=""  style="object-fit: cover;" @click="lookbigtupian(fuindex,idx)">
                        </div>
                    </div>
                    <div class="xc-creatTime">
                        {{item.xcCtime}}
                    </div>

                    <!-- <el-button icon="el-icon-download" circle title="下载" size="mini" class="xc-downbut" @click="downloadIamge(item)">
                    </el-button> -->
                </div>               
            </div>           
        </div>
        
        <div class="xcfy"  v-if="richangdata.length>0" style="padding: 20px 10px 10px 0;position: absolute;bottom:10px;left:10px;">
            <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-size="pageSize"
                :pager-count="11"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <loading-two v-else-if="isLoading"></loading-two>
        <is-null v-else></is-null>

         <!-- v-html解析图片点击浏览 -->
         <transition
            enter-active-class="animate__animated animate__fadeIn 0.5s">
            <image-viewer
                v-if="imgViewerVisible"
                :url-list="srcList"
                 :on-close="closeViewer"
                :z-index="3000"
            />
        </transition>
    </div>
    
</template>
  
<script>
import {Water} from "../../utils/Water"
import ImageViewer from 'element-ui/packages/image/src/image-viewer'
import GongxiangTop from './gonxiangtop/GongxiangTop.vue'
// import { getAllXiangCeByType } from "@/api/xiangce"
import {mapGetters} from 'vuex';
import { mapState } from "vuex";
import LoadingTwo from "@/pages/errorOrNull/LoadingTwo.vue";
import IsNull from "@/pages/errorOrNull/IsNull.vue";
import request from '../../utils/requests'
export default {
    name: 'XiangCe',
    components: {
    ImageViewer,
    GongxiangTop,
    LoadingTwo,
    IsNull
    },
    computed:{
        ...mapGetters([
            'webSetting',
        ]),
        ...mapState(["isLoading"])
    }, 
    data(){
      return{
        
        // imageUrl:[
        //   require("../../assets/image/10.jpg"),
        //   require("../../assets/image/32.jpg"),
        //   require("../../assets/image/12.jpg"),
        //   require("../../assets/image/13.jpg"),
        //   require("../../assets/image/14.jpg"),
        //   require("../../assets/image/31.jpg"),
        //   require("../../assets/image/16.jpg"),
        //   require("../../assets/image/17.jpg"),
        //   require("../../assets/image/18.jpg"),
        //   require("../../assets/image/19.jpg"),
        //   require("../../assets/image/34.jpg"),
        //   require("../../assets/image/1.jpg"),
        //   require("../../assets/image/2.jpg"),
        //   require("../../assets/image/33.jpg"),
        // ],

        richangdata:[],
       
        winWidth:document.body.clientWidth,
        lieshu:4,

        srcListView:'',
        imgList:[],
        srcList: [], // 当前要放大的图片存放位置
        imgViewerVisible:false,

        total:0,	
		pageNum:1,
		pageSize:20,
        
        typeId:'',
      }
    },
  

    methods:{     
        lookbigtupian(fuindex,ziidx){
            // console.log("我打开了")   
            document.body.style.overflow = 'hidden !important';
            // document.body.scroll='no'
            this.imgViewerVisible = true;
            const urlList = [...this.richangdata[fuindex].xcImages]
            const temp = []
            for (let i = 0; i < ziidx; i++) {
                temp.push(urlList.shift())
            }
            this.srcList = urlList.concat(temp)
            // this.srcListView=idx
            // this.onPreview([idx]) 
            // this.imgList.push(idx)
        },
     
        //关闭富文本渲染文本图片预览
        closeViewer() {
            this.imgViewerVisible = false;  
            document.body.style.overflow = 'auto !important';
        },

        downloadIamge(imgsrc, name) {
                //下载图片地址和图片名
                var image = new Image();
                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous");
                image.onload = function() {
                    var canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;
                    var context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, image.width, image.height);
                    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
                    var a = document.createElement("a"); // 生成一个a元素
                    var event = new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    }); // 创建一个单击事件
                    a.download = name || "photo"; // 设置图片名称
                    a.href = url; // 将生成的URL设置为a.href属性
                    a.dispatchEvent(event); // 触发a的单击事件
                };
                image.src = imgsrc;
        },


        imageonload(){
                new Water({
                    clas:'Waterfall',
                    el:".wf-content",
                    column:this.lieshu,
                    gap:10
                })
    
        },

        // 给图片加上地址
        pushDiZhi(res){
            const is=[]
            res.forEach(element => {
                is.push(this.qiNiuLink+element)
            });
            return is
        },

        XiangceList(id){   
            this.typeId=id     

            request.get('/xc/list/'+id,{params:{pageNum:this.pageNum,pageSize:this.pageSize}}).then((res)=>{
                // console.log(res)
                this.total=res.data.total
                res.data.list.forEach(xc=>{
                    xc.xcImages= this.pushDiZhi(xc.xcImages.split(','))
                })
                this.richangdata=res.data.list
                // console.log(res.data)
            })
            // getAllXiangCeByType(id,{pageNum:this.pageNum,pageSize:this.pageSize}).then((res)=>{
            //     console.log(res.data)
            //     res.data.forEach(xc=>{
            //         xc.xcImages= this.pushDiZhi(xc.xcImages.split(','))
            //     })
            //     this.richangdata=res.data
            //     console.log(res.data)
            // })
        },

        handleCurrentChange(val) {
			// console.log(`当前页: ${val}`,"每页几个：",this.pageSize);
            request.get('/xc/list/'+this.typeId,{params:{pageNum:val,pageSize:this.pageSize}}).then((res)=>{
                this.total=res.data.total
                // console.log(res)
                res.data.list.forEach(xc=>{
                    xc.xcImages= this.pushDiZhi(xc.xcImages.split(','))
                })
                this.richangdata=res.data.list
            })
		},
    },
   
    mounted(){    
        // const wt= document.getElementsByClassName("Waterfall")[0];
        // console.log("宽度",this.winWidth)
        if(this.winWidth<=750){
                this.lieshu=2     
        }
        // this.$nextTick(() => {
        window.onresize = () => {
            this.winWidth=document.body.clientWidth               

                // this.$nextTick(() => {
                // console.log("宽度",this.winWidth)
                if(this.winWidth<=750){
                    this.lieshu=2                    
                }else{
                    this.lieshu=4
                }
                if(this.richangdata.length>0){
                    this.imageonload()
                }
                // })
         
          
        };
        //    })
    },
    // watch: {
    //  winWidth(newName, oldName) {
    //     // console.log("新的",newName)
    //     // console.log("老的",oldName)
    //     console.log("宽度",this.winWidth)
    //     }
    // } ,

    created(){
        this.XiangceList('')
    },

    destroyed() {
		// 组件销毁后解绑事件
		window.onresize = null;
	}
}

</script>
 
<style>
.xcfy .el-pagination.is-background .btn-next, 
.xcfy .el-pagination.is-background .btn-prev {
    margin: 0 5px;
    /* background-color: #252529; */
	background-color: var(--card-tm-bg);
	color: var(--font-color);
    min-width: 30px;
    /* border-radius: 2px; */
	border: 1px solid #7474748e;
}
.xcfy .el-pagination.is-background .el-pager li {
	transition: .3s;
	background-color: #ffffff00;
	min-width: 20px;
	padding: 0 2px;
	border: none;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    /* background-color: #409EFF; */
	background-color: #ffffff00 !important;
    color: var(--app-allColor) !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
	transition: .3s;
	color: var(--app-allColor) !important;
}

.xc-qiehuan .el-collapse {
    border: none;
}
.xc-qiehuan .el-collapse-item__header {
    /* color: #fff ; */
    color: var(--font-color);
    font-size: 13px;
    height: 15px;
    padding: 5px 0 5px 15px;
    background-color: #00000000;
    border: none;
}
.xc-qiehuan .el-collapse-item__wrap {
    background-color:rgba(163, 163, 163, 0);
}
.xc-qiehuan .el-collapse-item__content {
    padding-bottom: 0;
    background-color:rgba(163, 163, 163, 0);
}

</style>
<style scoped>

.xc-qiehuan{
    border-radius: 5px !important;
	border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.195);
    position: absolute;
    z-index: 1;
    top: 445px;
    right:  20px;
    /* height: 50px; */
    /* width: 60px; */
    border-radius: 5px;
    /* background-color: rgba(54, 53, 53, 0.411); */
	background:var(--card-tm-bg);
    backdrop-filter: blur(10px);
    padding:5px;
    transition: .3s;
}
.xc-qiehuan button{
    transition: .3s;
    /* color: #fff; */
    color: var(--font-color);
    height: 30px;
    font-size: 13px;
    line-height: 12px;
}
.xc-qiehuan button:hover{
    transition: .3s;
	color:var(--app-allColor);
    /* background: rgba(65, 65, 65, 0.575) */
}
.Waterfall{
    position: relative;
    margin-left: 10px;
    margin-top: 20px;
    /* width: 100%; */
    width: calc(100% - 20px);
    max-width:1250px;
    /* margin:0 auto; */
    /* position:relative; */
    /* display: flex;   */
    /* justify-content: center; */
    /* align-items: center; */
  }
.wf-item{
    position:absolute;
    width: 100%
    /* box-shadow: -3px 2px 5px rgba(0,0,0,0.5); */
}
.wf-item img{
    cursor: zoom-in;
    height:100%;
    width:100%;
    transition: .3s;
    /* border :1px solid rgba(167, 167, 167, 0.5); */
    /* border:3px solid white; */
    /* border-radius: 10px; */
}


.wf-item .xc-img:hover  img {
    transition: .5s;
	transform: scale(1.1);

}

.xc-zi{
    transition: .3s;
	border:1px solid #74747400;
    transition: all .3s ease-in-out 0s;
    position: relative;
    width: calc(100%-20px);
    height: 100%;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 1px 5px var(--text-shadow);
    /* box-shadow: 0 1px 5px rgb(180 195 255 / 30%); */
	background: var(--card-tm-bg);
}
.xc-zi:hover{
    transition: .3s;
	border:1px solid var(--app-allColor);
}
.xc-zi .xczi-xctext{
    /* padding-bottom: 5px; */
    padding: 2px;
    margin-bottom: 5px;
    /* background-color: rgba(59, 59, 59, 0.162); */
    /* border :1px solid rgba(167, 167, 167, 0.5); */
    font-size: 13px;
}
.xc-creatTime{
    position: absolute;
    bottom: 0px;
    padding: 2px 4px;
    right: 0px;
    /* background-color: aqua; */
    /* border-radius:   10px 0 10px 0; */
    border-radius:  0 0 5px 0;
    font-size: 10px;
    color: white;
    font-weight: 300;
    background:#3a3a3a3b;
    backdrop-filter: blur(10px);
}
.xc-downbut{
    position: absolute;
    top: 0px;
    right: 0;
}
img[lazy="loading"]{
  display:block;
  /* width:100% !important; */
  height:100% !important;
  /* margin:0 auto; */
}

@media (max-width:880px) {	
    .Waterfall{
    /* width: 100%; */
        width: calc(100% - 20px);
        margin-left: 10px;
    }
    .xc-qiehuan{
        top: 295px;
    }
}
@media (max-width:500px) {	
    .xc-qiehuan{
        top: 225px;
    }
}
/* @media (max-width:750px) {	
    .Waterfall{
        width: 100%;
        margin-left: 10px;
    }
} */

</style>
  