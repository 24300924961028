import Vue from 'vue'
import App from './App.vue'
import store from './store'
// iconfont 图标库
import '@/assets/iconfont/iconfont.css'

// 在main.js 引入css
import 'highlight.js/styles/monokai-sublime.css'
import VueLazyload from 'vue-lazyload';
// 引入插件
import VueRouter from 'vue-router'
// 引入路由器
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import { files,serverUrl,qiNiuLink } from '@/utils/config.js';

import Base64 from './utils/base64.js' 
 
Vue.prototype.$Base64 = Base64;

// 全局方法挂载
Vue.prototype.files = files;
Vue.prototype.serverUrl=serverUrl;
Vue.prototype.qiNiuLink = qiNiuLink;



const imageLoading=require('@/assets/loading/loading.gif')
const imageError=require('@/assets/logo/error.png')
// 使用vue-lazyload插件并配置
Vue.use(VueLazyload, {
    preLoad: 1.3, // 预加载的高度比例
    error: imageError, // 加载失败时的占位符
    loading:imageLoading, // 加载中的占位符
    attempt: 1, // 尝试加载次数
});

Vue.use(ElementUI);
// 应用
Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  beforeCreate(){
    Vue.prototype.$bus=this
  },
  render: h => h(App),
  router:router,
  store
}).$mount('#app')
