<template>
    <div style="min-height: 90vh;width: 100%; display: flex;   justify-content: center;align-items: center;">
        <div class="person-manage faguang">
        
            <div class="personManage-qq">
                <div style="margin-top: 10px;">
                    <span style="position: relative; display: flex;">
                        <!-- <img v-if="visitInform.qqImage" src="../../assets/image/12.jpg"> -->
                        <img v-if="visitInform" :src="visitInform.imgurl" alt="">

                        <img v-else src="../../assets/logo/head.png">

                        <div style="position:absolute;right:-15px;top:-5px">
                            <el-tooltip 
                            class="item"
                            effect="dark" 
                            placement="right-start">                        
                                <div slot="content">
                                    多行信息<br/>第二行信息
                                    <button>按钮</button>
                                </div>
                                <i style="font-size:25px;color:red" class="el-icon-warning"></i>                 
                            </el-tooltip>

                            <!-- <el-tooltip 
                            class="item"
                            effect="dark" 
                            placement="right-start">                        
                                <div slot="content">
                                    验证通过，现在你可以对你的评论和留言进行管理
                                </div>
                                <i style="font-size:25px;color:rgb(20, 255, 3)" class="el-icon-success"></i>                
                            </el-tooltip> -->


                            <!-- <i style="font-size:25px;color:red" class="el-icon-warning"></i> -->
                        </div>
                    </span>
                    <span>
                        <div v-if="visitInform" style="margin-bottom:10px;font-size:15px;font-weight:600;">{{ visitInform.name}}</div>

                        <div v-else style="margin-bottom:10px;font-size:15px;font-weight:600;">昵称</div>

                            <!-- 2415648320 -->
                        <el-tooltip 
                        class="item"
                        effect="dark"                    
                        popper-class="tip-class"
                        placement="bottom">                  
                            <div slot="content">
                                输入你的QQ号获取评论和留言，设置密码后即可管理
                            </div>
                            <el-input
                                type="text"
                                size="small"
                                :clearable="true"
                                placeholder="请输入QQ号"
                                maxlength="10"
                                show-word-limit
                                v-model="inputQQ"
                                @keyup.enter.native="getVisitInform"
                                >
                            </el-input>       
                        </el-tooltip>    

                    </span>               
                </div>

                <div>

                </div>
            </div>
            <!-- 消息 -->
            <div class="personManage-comments messagesandcomments">
                <div>
                    <el-collapse  @change="handleChange">
                        <el-collapse-item>
                            <template slot="title">
                                我的评论 ( 
                                    <span v-if="commentstableData.length==0">0</span>
                                    <span v-else>{{ commentstableData.length }}</span>
                                    
                                    )
                            </template>
                            <div>
                                <template>
                        <el-table
                            :data="commentstableData"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                            >
                            <el-table-column
                            header-align="center"
                            prop="time"
                            label="时间"
                            sortable
                            width="110">
                            </el-table-column>

                            <el-table-column
                            header-align="center"
                            prop="text"
                            >
                            </el-table-column>

                            <el-table-column
                            header-align="center"
                            prop="article"
                            width="100">
                            </el-table-column>

                        
                            <el-table-column
                            header-align="center"                 
                            width="120">
                            <template slot-scope="scope">
                                <div style="display:grid;width:60px"> 
                                    <el-button style="margin-left: 10px;margin-bottom:5px" type="primary" size="mini" icon="el-icon-edit"  @click="xcbianji(scope.row)">编辑</el-button>
                                    <el-button type="danger" size="mini" icon="el-icon-delete"  @click="isDelete(scope.row.id)">删除</el-button>
                                </div>
                                
                            </template>
                            </el-table-column>
                        </el-table>
                    </template>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            
            </div>
            
            <!-- 留言 -->
            <div class="personManage-messages messagesandcomments">
                <div>
                    <el-collapse  @change="handleChange">
                        <el-collapse-item>
                            <template slot="title">
                                我的留言
                            </template>
                            <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
                            <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import axios from 'axios'

export default {
    name:'PersonManage',
    data(){
        return{
            inputQQ:'',
            visitInform:'',
            commentstableData:[
                {
                    time:'2023-7-3',
                    text:'hhhh哈哈哈哈哈哈哈哈哈酷酷酷酷酷',
                    article:'我是傻逼'
                },
                {
                    time:'2023-8-3',
                    text:'hhhh哈哈哈哈哈哈哈哈哈酷酷酷酷酷',
                    article:'我是傻逼'
                },
                {
                    time:'2023-7-3',
                    text:'hhhh哈哈哈哈哈哈哈哈哈酷酷酷酷酷',
                    article:'我是傻逼'
                },
                {
                    time:'2023-8-3',
                    text:'hhhh哈哈哈哈哈哈哈哈哈酷酷酷酷酷',
                    article:'我是傻逼'
                }
            ]
           
        }
    },

    methods: {
           // 输入获取qq获取头像，昵称，邮箱
        getVisitInform(){
            var re = /^\d+$/;
            if(re.test(this.inputQQ)){
                axios({         
                    url:`https://api.qjqq.cn/api/qqinfo?qq=${this.inputQQ}`,
                    method: 'GET',
                    data: {
                        output:'jsonp' 
                    },
                    dataType:"jsonp",
                }).then(
                    res =>{
                        this.visitInform=res.data
                    // this.qqInform=res.data 
                    } 
                )
            }else{
                return this.$message({
                    message:"请确定输入的是阿拉伯数字",
                    type: 'warning',
                    duration:1000,
                });
            }
            
        },
        
        handleChange(val) {
            // console.log(val);
        }
    }
}
</script>

<style>
.person-manage{
    /* position: ; */
    /* height: 200px; */
    /* height: 100vh; */
    margin: 20px;
    padding: 20px;
    /* padding:200px 0 20px 20px; */
    /* margin:0 10px; */
    width: calc(100% - 50px);
    /* margin-top: -277px; */
    /* width: 100%; */
    /* margin:-423px 20px 20px 20px; */
    transition: all .3s ease-in-out 0s;

}
.personManage-qq{
    height: 100px;
    /* width: 100%; */
    /* background-color: aqua; */
}
.personManage-qq>div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.personManage-qq>div img{
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    /* justify-content: center;
    align-items: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
    background-image: url(../../assets//logo/head.png); */
}
.personManage-qq>div>span{
    margin: 0 10px;
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow:after,
.el-tooltip__popper[x-placement^=right] .popper__arrow:after{
    border-color: var(--app-bgColor) !important;
}
.el-tooltip__popper{
    background:var(--app-bgColor) !important;
}
.el-tooltip__popper.is-dark{
    color: var(--font-color) !important;
}

.personManage-comments{
    margin-bottom: 10px;
}
.personManage-messages{
}
.messagesandcomments{
    padding: 5px;
    border-radius: 10px;
    /* height: 100px; */
    /* width: 100%; */
    /* background-color: rgb(133, 133, 133); */
    border :1px solid rgba(167, 167, 167, 0.5);
    background-color: var(--card-bg);
}
/* 折叠框 */
.messagesandcomments .el-collapse{
    border: none;
}
.messagesandcomments .el-collapse-item__header,
.messagesandcomments .el-collapse-item__wrap
{
    background: transparent;
    /* background-color: var(--card-bg); */
    border-bottom: none;
    border: none;
}
.messagesandcomments .el-collapse-item__header:hover{
    color: var(--app-allColor);
    /* background-color: #4d4d4d5a; */
}
.messagesandcomments .el-collapse-item__content {
    background-color:rgba(255, 255, 255, 0);
    padding: 0;
}


/* 文字颜色 大小*/
.messagesandcomments .el-collapse-item__header{
    font-size: 15px;
    font-weight: 600;
    color: var(--font-color);
    padding-left: 10px;
}
.messagesandcomments .el-table thead,
.messagesandcomments .el-table
{
    font-size: 13px;
    color: var(--font-color);
}

/* 表格 */
.messagesandcomments .el-table, .el-table__expanded-cell,
.messagesandcomments .el-table th.el-table__cell,
.messagesandcomments .el-table tr
{
    background-color: transparent;
}
.messagesandcomments .el-table td.el-table__cell,
.messagesandcomments .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(167, 167, 167, 0.5);
}
.messagesandcomments .el-table__row{
    margin-bottom: 10px;
}
.messagesandcomments .el-table td.el-table__cell{
    /* background-color: #b9b8b8; */
}
/* .messagesandcomments .el-table td.el-table__cell:nth-child(3){
    background-color: rgba(108, 108, 108, 0.212);
} */
/* .messagesandcomments .el-table td.el-table__cell:last-child{
    border-radius:0  10px 10px 0;
} */

.messagesandcomments .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #37373700 !important;
}

/* 滚动条样式 */
.messagesandcomments .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    /*width: 100px !important;*/
    /* width: 5px; */
    height: 5px;
    /* border-radius: 5px; */
    background-color: rgba(43, 43, 43, 0.747); 
}
.messagesandcomments .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{/*滚动条里面小方块*/
    border-radius: 5px;
    background-color: rgba(250, 250, 250, 0.829);
}

/* @media (max-width:880px) {	
    .person-manage{
        margin-top: -277px;
        margin-left: 10px;
        margin-right: 10px;
    }
} */
</style>
<style scoped>

</style>