<template>
    <div class="new-comments faguang" id="faguang">
        <div class="nc-top">
            最新评论
        </div>
        <div>
            <div class="nc-zi" v-for="(recentCom,idx) in arr" :key="idx">      
                <el-tooltip 
                class="item"
                effect="dark"                    
                popper-class="tip-class"
                placement="right">                  
                    <div slot="content" style="width: 250px;">
                        <!-- Java是一门面向对象的编程语言，不仅吸收了C++语言的各种优点，还摒弃了C++里难以理解的多继承、指针等概念，因此Java语言具有功能强大和简单易用两个特征。Java语言作为静态面向对象编程语言的代表，极好地实现了面向对象理论，允许程序员以优雅的思维方式进.. -->
                        <span class="cmTexthtml" v-html="recentCom.cmText"></span>
                        <div style="font-size: 14px;margin-top: 10px;">
                            来自文章《{{ recentCom.articleTitle }}》
                        </div>
                    </div>

                    <div style="display: flex;">
                        <div class="nc-zi-left">
                            <img :src="'https://q1.qlogo.cn/headimg_dl?dst_uin='+recentCom.cmQq+'&spec=100'" alt="">
                        </div>
                        <div class="nc-zi-right">
                            
                            <span>
                                <span style="">
                                    {{ recentCom.cmNickname }}
                                </span>
                                <span style="font-size: 10px; margin-left:5px;">
                                    {{ JudgmentTime(recentCom.cmTime)}}
                                </span>
                            </span>
                            <div class="cmTexthtml" v-html="recentCom.cmText"> </div>
                                <!-- Java是一门面向对象的编程语言，不仅吸收了C++语言的各种优点，还摒弃了C++里难以理解的多继承、指针等概念，因此Java语言具有功能强大和简单易用两个特征。Java语言作为静态面向对象编程语言的代表，极好地实现了面向对象理论，允许程序员以优雅的思维方式进.. -->                           
                        </div>
                    </div>        
                </el-tooltip>  
                <!-- <div :key="idx" class="cards-button">
                    <el-button type="primary" plain size="mini">前往</el-button>
                </div>  -->
            </div>  
        </div>

            
    </div>
</template>

<script>
import { getRecentCommentList } from '@/api/article'
export default {
    name:'NewComment',
    data(){
        return{
            arr:[]
        }
    },
    methods:{
        getRecentComs(){
            getRecentCommentList().then(res=>{
                // console.log("评论",res.data)
                this.arr=res.data
            })
        },
        // 判断评论时间与当前时间差距
        JudgmentTime(cmtime){
            // console.log(cmtime)
            var diffValue=new Date(cmtime)
            // console.log("时间撮",diffValue)
            var minute = 60*1000;
            var hour = minute * 60;
            var day = hour * 24;
            var month = day * 30;  
            var nowTime = (new Date()).getTime(); //获取当前时间戳 
            // console.log("new ",nowTime)                  
            var ShiJianCha = nowTime - diffValue;
            var monthC = ShiJianCha / month;
            var weekC = ShiJianCha / (7 * day);
            var dayC = ShiJianCha / day;
            var hourC = ShiJianCha / hour;
            var minC = ShiJianCha / minute;
            var res = '';
            if(monthC>=12){
                     // 获取记录的时间戳
                var date = diffValue;
                res = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
            }else if(monthC>=1){
                res = parseInt(monthC) + "个月前";
            }
            else if(weekC>=1){
                res = parseInt(weekC) + "周前"
            }
            else if(dayC>=1){
                res = parseInt(dayC) +"天前"
            }
            else if(hourC>=1){
                res = parseInt(hourC) +"个小时前"
            }
            else if(minC>=1){
                res = parseInt(minC) +"分钟前"
            }else{
                res = "刚刚"
            }
            return res;  
    
        }
    },
    created(){
        this.getRecentComs()
    }
}
</script>

<style>

.new-comments{
    border-bottom:1px solid #7474748e;
    /* height: 100px; */
    margin-top: 20px;
    padding: 10px;
}

.nc-top{
    line-height: 20px;
    padding-bottom: 10px;
    font-weight: 600;
}

.nc-zi{
    margin-bottom: 10px;
    /* border-bottom: 1px solid beige; */
    /* height: 50px; */
    /* width: 100%; */
}
.nc-zi:last-child{
    margin-bottom: 0;
}
.nc-zi .nc-zi-left{
    height: 50px;
    width: 50px;
}
.nc-zi .nc-zi-left img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.nc-zi .nc-zi-right{
    height: 50px;
    flex: 1;
    padding-left: 10px;
    /* background-color: antiquewhite; */
}
.nc-zi .nc-zi-right>div{
    line-height: 28px;
    /* max-height: 5rem; */
    font-size: 14px;
	/* padding: 10px; */
	display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:1;
    max-height: 5rem;
    overflow: hidden;
}.nc-zi .nc-zi-right>div:hover{
    cursor: pointer;
    transition: 0.3s;
    color: var(--app-allColor);
}
.nc-zi .nc-zi-right>span{
    line-height: 23px;
    /* max-height: 5rem; */
    font-size: 12px;
}
.cmTexthtml{
    font-weight: 300;
}
.cmTexthtml img{
    height: 20px;
    width: 20px;
    margin:0 2px 0 2px ;
    transform: translateY(2px)
}

.cards-button{
    transform: translateX(-50px);
	border: none;
	background-color: var(--app-divbgcColor);
	box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
	position: absolute;
	opacity: 0;
	transition: 0.3s ease-out;
	animation-fill-mode: forwards;
    margin-top: -50px;
}
.nc-zi:hover .cards-button{
    /* transform: translate(40%,-40%); */
    transform: translateX(-30px);
	opacity: 1;
}
</style>