<template>
	<div id="myhomeIndex">	
		<div class="my-home-top">
			<div class="home-controller" id="home-controller"></div>
			<div id="uu2" style="font-size: 30px;"></div>
		</div>
		
		<!-- <gongxiang-top :togonggongTop="{page_title:'',page_bg:webSetting.webHomeImage,msg:''}"></gongxiang-top> -->

		<div class="my-home" style="border-bottom: 1px solid #7474748e;border-top: 1px solid #7474748e;">		
			<div class="home-right enter-x-left" >
				<lun-bo-one></lun-bo-one>
			</div>
			<div class="home-left enter-x-right" style="border-left:1px solid #7474748e;">						
				<WebNotice></WebNotice>												
			</div>
		</div>

		<div class="my-home" style="border-bottom: 1px solid #7474748e;">
			<div class="home-left enter-x-left" style="border-right:1px solid #7474748e;">
				<div class="left-kj">		
					<HeadAndSJ></HeadAndSJ>
					<WeatherInfo></WeatherInfo>
					<!-- <web-informa></web-informa> -->
					<HomeTagList></HomeTagList>	
					<HomeCategory></HomeCategory>  
					<new-comments></new-comments>
					<div class="wzbq-ckou faguang" id="faguang">
					</div>          						
				</div>
			</div>

			<div class="home-right">
				<!-- <div class="smile-daohan faguang">
					heihei
				</div> -->
				<wen-zhang></wen-zhang>
				
			</div>

		</div>		
  </div>
</template>

<script>
import HeadAndSJ from '../body/body-cp/HeadAndSJ.vue'
import HomeTagList from './body-cp/HomeTagList.vue'
import HomeCategory from './body-cp/HomeCategory.vue'
import LunBoOne from './body-cp/LunBoOne.vue'
import NewComments from './body-cp/NewComments.vue'
import WebInforma from './body-cp/WebInforma.vue'
import WebNotice from './body-cp/WebNotice.vue'
import WenZhang from './body-cp/WenZhang.vue'
import GongxiangTop from './gonxiangtop/GongxiangTop.vue'
import {mapGetters} from 'vuex';
import WeatherInfo from './body-cp/WeatherInfo.vue'

export default {
  	components: { HeadAndSJ, WebNotice, WebInforma, LunBoOne, WenZhang, NewComments, HomeTagList, GongxiangTop, HomeCategory, WeatherInfo },
    name:'MyHome',
	computed:{
        ...mapGetters([
            'webSetting',
        ]),
    },
	data(){
		return{
			// bgImage:require("../../assets/logo/R-C.jpg")
		}
	},
	methods:{
		setController(){
		
		}
	},
	mounted(){
		// console.log("网站签名",this.webSetting.webSignature)
		const str=this.webSetting.webSignature;
		let num=0;
		let mm=document.getElementById("home-controller");
		
		window.setInterval(()=>{
			if(num<=str.length)
		
			{		
				mm.innerHTML=str.substring(0,num);
				num++;
			}
		},200)
	}

}
</script>

<style >
.my-home-top{
	height: 500px;
    transition: .3s;
	width: 100%;
	display: flex;
	align-items: center;
    text-align: center;
    justify-content: center;
	margin-top: 60px;
}
.my-home-top .home-controller{
	/* text-shadow:16px 11px 13px #626262a3; */
	text-shadow:16px 11px 13px var(--text-shadow);
	padding: 0 15px;
	line-height: 60px;
	font-size: 45px;
	font-weight: 400;
	color: var(--font-color);
	letter-spacing: 1px;			 
	/* font-family: 'Times New Roman', Times, serif; */
	-webkit-animation: bounce-down 1.6s linear infinite;
	animation: bounce-down 1.6s linear infinite;
}
@-webkit-keyframes bounce-down {
	25% {
		-webkit-transform: translateY(-4px);
	}
	50%, 100% {
		-webkit-transform: translateY(0);
	}
	75% {
		-webkit-transform: translateY(4px);
	}
}
		 
@keyframes bounce-down {
	25% {
			transform: translateY(-4px);
	}
	50%, 100% {
			transform: translateY(0);
	}
	75% {
			transform: translateY(4px);
	}
}
#myhomeIndex{
	/* padding: 10px; */
	position: relative;
}
.my-home{
    display: flex;
	/* border:1px solid black ; */
	height: 100%;
}

.home-left{
    width: 27%;
	padding: 10px;
	/* height: 500px; */
	/* margin: 5px; */
	transition: all 400ms ease 0ms;
	/* background-color: #00FFFF; */
}

.wzfl-ckou{
	height: 200px;
	margin-top: 20px;
}

.wzbq-ckou{
	height: 200px;
	margin-top: 20px;
}

.left-kj{
	min-height:200px;	
}

.home-right{
	/* background-color: #646464; */
    width: calc(100% - 250px);
	/* height: 500px; */
	/* margin: 5px; */
	transition: all 400ms ease 0ms;
	padding: 10px;
}
			
@media (max-width:880px) {	
	.my-home-top{
	    height: 350px;
        transition: .3s;
    }
	#myhomeIndex{
		padding: 0;
		padding-bottom: 10px;
	}	
    .home-left{
        display: none;		
     }
    .home-right{
        width: 100%;	
    }
}
@media (max-width:500px) {	
    .my-home-top{
        transition: .3s;
	    height:280px;
    }
	.my-home-top .home-controller{
		font-size: 35px;
	}

}
.smile-daohan{
	height: 50px;
}



.enter-x-left {
    /* z-index: 9; */
    opacity: 0;
    animation: enter-x-left 0.4s ease-in-out 0.3s;
    animation-fill-mode: forwards;
    transform: translateX(-50px);
    }
.enter-x-right {
    /* z-index: 9; */
    opacity: 0;
    animation: enter-x-right 0.4s ease-in-out 0.3s;
    animation-fill-mode: forwards;
    transform: translateX(50px);
}

@keyframes enter-x-left {
	to {
		 opacity: 1;
		transform: translateX(0);
	}
}
@keyframes enter-x-right {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
</style>