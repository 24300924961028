export class Water{
    constructor(options){
        this.$options = options
        this.miniHeight = []
        this.__init()     
        this.__getMaxHight()
    }

    __init() {
        const container = document.querySelector(this.$options.el);

        this.items = container.children
        this.el=document.getElementsByClassName(this.$options.clas)[0];
        // console.log("el",this.el)
        this.column = this.$options.column
        this.gap = this.$options.gap
        this.itemWidth = (container.offsetWidth - this.gap * (this.column-1)) / this.column
        this.__render()
    }

    __render() {
        [...this.items].forEach((value, index) => {
            value.style.width = this.itemWidth + "px"    
            if (index < this.column) {
                value.style.top = "0px"
                value.style.left = (this.itemWidth + this.gap) * index + "px"
                this.miniHeight.push(value.offsetHeight)    
            } else {
                this.miniIndex = this.__getMiniIndex()      
                value.style.top = this.miniHeight[this.miniIndex] + this.gap + "px"
                value.style.left = (this.itemWidth + this.gap) * this.miniIndex + "px"
                this.miniHeight[this.miniIndex] = this.miniHeight[this.miniIndex] +value.offsetHeight + this.gap          
           }
          
        })   
    }
    __getMiniIndex() {
        return  this.miniHeight.indexOf(Math.min(...this.miniHeight))
    }
    // 获取最大高度值
    __getMaxHight(){
        // let maxheight=0
        // for (let i = 0; i < this.miniHeight.length; i++){
        //     if (this.miniHeight[i]>maxheight){
        //         maxheight=this.miniHeight[i]
        //     }
        // }
        // this.el.style.height=maxheight+80 +'px'
        // console.log("max",maxheight)
        // return maxheight      
        const maxHeight = Math.max(...this.miniHeight);
        this.el.style.height = `${maxHeight + 80}px`;
    }
}