<template>
    <div class="wzbq-index">
        <gongxiang-top :togonggongTop="{page_title:'文章标签',page_bg:'',msg:'',bqflImage:toImg}"></gongxiang-top>
               
        <div style="width: 100%;">

            <div class="wenz-bq  faguang"> 
                <!-- <div class="wzbq-top">
                    <h1>文章标签</h1><sup>53</sup>
                </div> -->
                <div class="wzbq-body">
                    <transition v-for="(b,idx) in biaoqList"  :key="idx" >
                        <div class="wzbq-zi"   :style="{'--color':b.tagColor}" @click="getTagId(b.tagName)">                                                                
                                <span  :style="{fontSize:`${15 + (b.articleList.length) / 5 }px`}">
                                    <!-- <i class="iconfont icon-tianmaohaoquan"></i>  -->
                                # {{ b.tagName }}
                                </span>
                                <sup>{{b.articleList.length}}</sup>                     
                        </div>
                    </transition>
                </div>
            </div>
            <fen-lei-and-biaoq v-if="articleList.length>0" :toArticleList="articleList"></fen-lei-and-biaoq>
            
            <loading-two v-else-if="isLoading"></loading-two>
            
            <is-null v-else></is-null>
        </div>
		
    </div>
</template>

<script>
import LoadingTwo from '@/pages/errorOrNull/LoadingTwo.vue';
import FenLeiAndBiaoq from './FenLeiAndBiaoq.vue'
import GongxiangTop from './gonxiangtop/GongxiangTop.vue'
import { getTagAll } from '@/api/tagAndCategory';
import { mapState } from "vuex";
import IsNull from '@/pages/errorOrNull/IsNull.vue';

export default {
    computed: {//加载数据过程loading
	    ...mapState(["isLoading"])
	},
    components: { FenLeiAndBiaoq, GongxiangTop, LoadingTwo, IsNull },
    name:'WenZhangBiaoq',
    data(){
        return{
            biaoqList:'', 
            articleList:'',
            routeS:'',

            toImg:require("@/assets/image/5555.jpg")
        }
    },

    methods:{
        getTagId(idex){       
            getTagAll(idex||this.biaoqList[0].tagName).then(res=>{
                this.articleList=res.data[0].articleList
                // console.log(this.articleList)
            })
        }
    },
    mounted(){
        if(this.$route.query.search){
            this.getTagId(this.$route.query.search)
        }else{
            getTagAll('').then(res=>{
                // console.log(res.data)
                this.biaoqList=res.data
                this.articleList=res.data[0].articleList
            })
        }
        getTagAll('').then(res=>{
            // console.log(res.data)
            this.biaoqList=res.data
        })

		// console.log("这是加载",this.isLoading)

    },
    created(){
    }
    
}
</script>

<style>
.wzbq-index{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    /* display: flex;   */
    /* border-radius: 10px 10px 10px 10px; */
	/* margin-top: 10px;	 */
	position: relative;
	color: var(--font-color);
	width: 100%;

    /* background-color: aqua; */
}

.wenz-bq{
    /* margin: 20px; */
    margin: 10px;
    padding: 10px;
    /* background-color: aqua; */
}
.wzbq-top{
    display: flex;
    text-align: center;
    /* justify-content: center; */
    /* align-items: center; */
    /* margin: 0 10px; */
    /* height: 100px; */
    margin-bottom: 20px;
    color: var(--font-color);
    /* margin: 0 10px; */
    width: 100%;
}
.wzbq-body{
    text-align: center;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    /* padding: 20px; */
    /* margin: 20px; */
    /* border: 2px dashed var(--font-color); */
    /* background-color: var(--app-divbgcColor);
    background: var( --card-bg); */
    /* background-color: aliceblue; */
    border-radius: 10px;
    transition: all 400ms ease 0ms;
}
.wzbq-zi{
    cursor: pointer;
    position: relative;
    /* background-color: var(--app-divbgcColor); */
    /* display:inline-block; */
    /* vertical-align:middle; */
    /* align-items: flex-start; */
    display: flex;
    transition: .3s;
    margin:10px;
    border-radius: 8px;
    /* display: inline-block; */
    position: relative;
    /* color: var(--font-color); */
    color: var(--color);  
    border: 1px solid var(--color);
    /* background-color: transparent; */
    padding: 3px 8px 9px;
    /* border-bottom :1px solid rgba(167, 167, 167, 0.5);
	border-right :1px solid rgba(167, 167, 167, 0.5);
	border-left :1px solid rgba(167, 167, 167, 0.5); */
    /* transition: all 200ms ease 0ms; */
    /* background: url(../../assets/logo/R-C.jpg); */
    /* background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(247, 247, 247)); */
    /* width: 10em; */ 
    /* height: 3.5em; */
}
.wzbq-zi::before{
    content: "";
	position: absolute;
	z-index: -1;  
    left: 0px;
    top: 0;
	width:100%; 
    height: 100%;
    opacity: .5;
    background-color: var(--card-tm-bg);
    border: 1px solid var(--card-tm-bg);
    /* border: 1px solid #bbbbbb3e; */
}
.wzbq-zi span{  
    margin-top: 5px;
    /* height: 80px; */
    /* width: 200px; */
    /* background-color: azure; */
}
.wzbq-zi sup{  
    padding: 3px;
    font-size: 12px;
}

/* .wzbq-zi::after{
    content: "";
    position: absolute;
    top: -3px;
    left: 5%;
    width: 90%;
    height: 3px;
    background: var( --card-bg);
    transform-origin: center;
}

.wzbq-zi::before {
    content: "";
    transform-origin: center;
    position: absolute;
    bottom: -3px;
    left: 5%;
    width: 90%;
    height: 3px;
    background: var( --card-bg);
}

.wzbq-zi:hover::before, .wzbq-zi:hover::after {
    transition: 0.5s;
    transform: scale(0)
} */

.wzbq-zi:hover {
    color:white;
    transition: .3s;
    background:var(--color);
    box-shadow: inset 0px 0px 25px var(--color);
    /* -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -o-transform: translateY(-8px);
    -ms-transform: translateY(-8px); */
    transform: translateY(-8px);
}
@media (max-width:880px) {	
    .wenz-bq{
        /* margin: 20px; */
        margin-left: 10px;
        margin-right: 10px;
    }
    .wzbq-zi{
        padding: 0px 5px 5px
    }
    .wzbq-zi span{
        font-size: 12px !important;
    }
    .wzbq-zi sup{  
        font-size: 10px;
    }
}
</style>