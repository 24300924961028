<template>
    <div class="footeronor-tuijian-l footeronor-tuijian-zi">
        <!-- <div class="" style="font-size: 15px;font-weight: 500;">
            网站推荐
        </div> -->
        <div class="" style="display: grid;padding: 5px;">
            <span>
                <a href="https://lbs.amap.com/">高德开放平台</a>
            </span>
            <span>
                <a href="https://www.aliyun.com/">阿里云</a>
            </span>
            <span>
                <a href="https://dash.cloudflare.com/">cloudflare</a>         
            </span>
            <span>
                <a href="http://admin.xiangmeng.xyz/">像梦后台管理</a>
            </span>
            <!-- <span>
                <a href="https://www.transition.style/">炫酷样式</a>
            </span>
            <span>
                <a href="https://echarts.apache.org/">统计图组件</a>
            </span> -->
        </div>               
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>