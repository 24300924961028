<template>
    <!-- <div class="fenlei-and-biaoq faguang"> -->
        <!-- <div class="flbq-top">
			<div class="flbq-top-zi">
			哈哈
			</div>
		</div> -->
		
		
		<div class="flbq-body enter-x-left">
			<el-timeline>
				<el-timeline-item
				v-for="(activity, index) in againArticleList(toArticleList)"
				:key="index"
				icon="iconfont icon-fengche"
				:type="activity.type"
				:color="activity.color"
				:size="activity.size"
				:timestamp="activity.date" placement="top">
				{{activity.content}}
				<div style="display: flex;flex-wrap: wrap;">
				
					<div class="flbqb-zi faguang" v-for="(t,idx) in activity.value" :key="idx" >
					<div class="flbqbzi-top">
						<img v-lazy="files.myblogImageUrl+t.articleCover" alt="">
						
						<div class="flbqbzi-top-shan"></div>

						<div  v-if="createTimeifNew(t.createTime)" class="flbqbzi-top-new">
							最新 
						</div>
						<div class="flbqbzi-top-textinf">
							<span>
								<!-- <i class="iconfont icon-24gf-calendar">发表时间：2023-2-3</i>											 -->
								<i class="iconfont icon-rili1"> {{t.createTime}}</i>								
							</span>
							<!-- <span>
								<i class="iconfont icon-liulan"> {{t.reading}}</i>			
							</span>
							<span>
								<i class="iconfont icon-xiaoxi1"> {{ t.text_plun }}</i>								
							</span>	 -->

						</div>
					</div>
					<div class="flbqbzi-body">
						<div class="flbqbzi-body-top">
							<span @click="toArticleIndex(t.id,t.articleText,t.articleTitle)">
								{{t.articleTitle}}
							</span>
						</div>
						<!-- <div class="flbqbzi-body-body">
							<span>
								<i class="iconfont icon-rili">{{t.createTime}}</i>								
							</span>
							<span>
								<i class="iconfont icon-liulan">{{t.text_yuedu}}</i>			
							</span>
							<span>
								<i class="iconfont icon-xiaoxi1">{{ t.text_plun }}</i>								
							</span>							
						</div> -->
					</div>
				
					</div>
				</div>
				</el-timeline-item>		
			</el-timeline>

			<!-- <div class="flbqb-zi faguang" v-for="(t,idx) in toArticleList" :key="idx">
				<div class="flbqbzi-top">
					<img src="../../assets//logo/R-C.jpg" alt="">
					
					<div class="flbqbzi-top-shan"></div>

					<div class="flbqbzi-top-new">
						new 
					</div>
				</div>
				<div class="flbqbzi-body">
					<div class="flbqbzi-body-top">
						<span>
							{{t.text_title}}
						</span>
					</div>
					<div class="flbqbzi-body-body">
						<span>
							<i class="iconfont icon-icon-">{{t.text_yuedu}}</i>
							
						</span>
						<span>
							<i class="iconfont icon-31pinglun">{{ t.text_plun }}</i>
							
						</span>
						<span>
							<i class="iconfont icon-rili">{{t.createTime}}</i>
							
						</span>
					</div>
				</div>
			</div> -->
		</div>


    <!-- </div> -->
</template>

<script>
import LoadingTwo from '@/pages/errorOrNull/LoadingTwo.vue';
export default {
	
  	components: {LoadingTwo },
    name:'FenLeiAndBiaoq',
	props: {
        toArticleList: {               
			type:Array,
            required: true,
        },
    },
	data(){
		return{
			// toArticleList:[
			// 	{
			// 		text_id:1,
			// 		text_title:"傻逼",
			// 		createTime:"2022-02-03",
			// 		text_yuedu:44,
			// 		text_plun:6
			// 	},
			// 	{
			// 		text_id:2,
			// 		text_title:"傻哈哈哈红红火火恍恍惚惚或或或或或或或或或或",
			// 		createTime:"2022-02-28",
			// 		text_yuedu:44,
			// 		text_plun:550
			// 	},{
			// 		text_id:1,
			// 		text_title:"傻逼",
			// 		createTime:"2022-02-03",
			// 		text_yuedu:44,
			// 		text_plun:6
			// 	},
			// 	{
			// 		text_id:2,
			// 		text_title:"傻哈哈哈红红火火恍恍惚惚或或或或或或或或或或",
			// 		createTime:"2022-02-28",
			// 		text_yuedu:44,
			// 		text_plun:550
			// 	},
				
			// 	{
			// 		text_id:13,
			// 		text_title:"66666傻逼",
			// 		createTime:"2022-05-03",
			// 		text_yuedu:44,
			// 		text_plun:100
			// 	},
			// 	{
			// 		text_id:4,
			// 		text_title:"三十岁",
			// 		createTime:"2022-07-03",
			// 		text_yuedu:44,
			// 		text_plun:88
			// 	},{
			// 		text_id:6,
			// 		text_title:"赣东大道多",
			// 		createTime:"2023-02-03",
			// 		text_yuedu:44,
			// 		text_plun:9899
			// 	},{
			// 		text_id:7,
			// 		text_title:"更新阿基",
			// 		createTime:"2023-02-15",
			// 		text_yuedu:44,
			// 		text_plun:77
			// 	}
			// ],
			resturndata:[]
		}
	},
	methods:{
		againArticleList(dataList){
			// var arr = [];
			// var keyMap = {};
			// for (var i = 0; i < dataList.length; i++) {
			// 	var arrElem = dataList[i];
			// 	var elemKey = arrElem.createTime.substring(0, 7);//截取年月
			// 	if (elemKey in keyMap) {
			// 		arr[keyMap[elemKey]].value.push(arrElem);
			// 	} else {
			// 		arr.push({
			// 			"date": arrElem.createTime.substring(0, 7),
			// 			"value": [arrElem]
			// 		});
			// 		keyMap[elemKey] = arr.length - 1;
			// 	}
			// }
			// return arr
			const arr = [];
			const keyMap = {};

			dataList.forEach((arrElem) => {
			const elemKey = arrElem.createTime.substring(0, 7); // 截取年月
			if (keyMap[elemKey] !== undefined) {
				arr[keyMap[elemKey]].value.push(arrElem);
			} else {
				arr.push({
				date: elemKey,
				value: [arrElem]
				});
				keyMap[elemKey] = arr.length - 1;
			}
			});
			// 按date属性进行时间倒序排序
			arr.sort((a, b) => new Date(b.date) - new Date(a.date));
			return arr;

		},

		toArticleIndex(id,text,isTitle){
			console.log(id)
			this.$router.push({
                path:'/article',
				query:{
					id:id,
					B:this.$Base64.encode(text),
					title:isTitle,
					t:Date.now()
				}, 
            })
		},

		createTimeifNew(aTime){
			var diffValue=new Date(aTime)
            // console.log("时间撮",diffValue)
            var minute = 60*1000;
            var hour = minute * 60;
            var day = hour * 24;
            var nowTime = (new Date()).getTime(); //获取当前时间戳 
            // console.log("new ",nowTime)                  
            var ShiJianCha = nowTime - diffValue;    
            var dayC = ShiJianCha / day;
			if(dayC>2){
                return false;
            }
            return true;  
	  	},
	},
	mounted(){
	}
}
</script>

<style>
.fenlei-and-biaoq{
    /* display: grid; */
	/* border:1px solid black ; */
	border-radius: 10px;
	/* margin-top: 10px;	 */
	position: relative;
	/* width: 100%; */
	/* background-color: var(--app-divbgcColor); */
}
/* .flbq-top{
	display: flex;
	flex-wrap: wrap;
	height: 100px;
	width: 100%;
	border-bottom: 2px dashed var(--font-color);
}
.flbq-top .flbq-top-zi{
	margin: 10px;
	height: 20px;

	color: #090909;
	padding: 0.7em 1.7em;
	font-size: 18px;
	border-radius: 10px 10px 10px 10px;
	background: #e8e8e8;
	border: 1px solid #e8e8e8;
} */


.flbq-body{
	padding-top: 20px;
	padding: 10px;
	/* margin: 20px; */
	/* height: 50vh; */
	/* padding: 10px; */
	/* margin-top: 20px; */
	color: var(--font-color);
	/* min-height: 400px; */
	/* width: 100%; */
	/* display: flex; */
	/* flex-wrap: wrap; */
	/* background-color:rgb(199, 199, 199); */
}
.flbq-body .flbqb-zi{
	border-bottom:1px solid #7474748e;
	/* border-bottom:  1px solid  rgba(167, 167, 167, 0.5); */
	/* border: none; */
	margin: 10px;
	transition: .3s;
	/* height: 260px; */
	width:  calc(100% / 4 - 22px);
	/* border:1px solid var( --card-bg); */
}
.flbqbzi-top{
	height: 120px;
	width: 100%;
	overflow: hidden;
	border-radius: 0 10px 0 0 !important;
	position: relative;
}
.flbqbzi-top img{
	object-fit: cover;
	height: 100%;
	width: 100%;
	position: absolute;
}
.flbqbzi-top .flbqbzi-top-new{
	font-size: .9rem;
	right: 0%;
	
	border-radius: 0 10px 0 10px !important;
	text-align: center;
	padding: 5px 10px;
	color: white;
	background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 0%, rgba(253,92,46,1) 48%, rgba(252,176,69,1) 100%);
	/* background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%); */
	/* background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 0%, rgba(253,92,46,1) 48%, rgba(252,176,69,1) 100%); */
	position: absolute;
}
.flbqbzi-top .flbqbzi-top-shan{
	height: 100%;
	width: 100%;
	position: absolute;
	background: linear-gradient(120deg, #e5e5e500 30%, #f0f0f0 38%, #f0f0f000 39%, #970d0d00 48%);
	background-size: 200% 100%;
	background-position: 100% 0;
	font-weight: bold;
}

.flbqb-zi:hover{
	cursor: pointer;
	transform: translateY(-8px);
}
.flbqb-zi:hover .flbqbzi-top .flbqbzi-top-shan{
	animation: load89234 1s linear;
}

.flbqbzi-top .flbqbzi-top-textinf{
	background:var(--card-tm-bg);
    backdrop-filter: blur(5px);
	bottom: 0;
	position: absolute;
	flex-wrap: wrap;
	/* height: 30px; */
	/* width: 100%; */
	display: flex;
	/* border-radius: 0 10px 0 0; */
}
.flbqbzi-top .flbqbzi-top-textinf span{
	color: var(--font-color);
	padding: 3px;
	margin: 0 4px;
	/* background-color: #242424a2; */

}

.flbqbzi-top .flbqbzi-top-textinf span i{
	font-weight: 500;
	font-size: 12px;
}


.flbqbzi-body{
	width: 100%;
	display: grid;
}
.flbqbzi-body .flbqbzi-body-top{
	width: 100%;
	/* background-color: #262626; */
	font-size: 16px;
	font-weight: 500;
}

.flbqbzi-body .flbqbzi-body-top span{
	/* font-weight: 600; */
	margin: 5px 10px;
	/* padding: 10px; */
	display: -webkit-box;
    -webkit-box-orient: vertical;
	line-height:30px;
    -webkit-line-clamp:1;
    /* max-height: 5rem; */
    overflow: hidden;
	letter-spacing:2px
}

.flbqbzi-body .flbqbzi-body-body{
	flex-wrap: wrap;
	height: 30px;
	width: 100%;
	display: flex;
}
.flbqbzi-body .flbqbzi-body-body span {
	/* border-right: 1px solid black; */
	margin-top: 5px;
	padding: 0 8px;
}
.flbqbzi-body .flbqbzi-body-body span i{
	font-size: .5rem;
}

.flbqb-zi:hover .flbqbzi-body-top span{
	color: var(--app-allColor);
}


@media (max-width:1000px) {		
	.flbq-body .flbqb-zi{
		width:  calc(100% /3  - 22px);
        transition: .3s;
	}
}
@media (max-width:750px) {		
	.flbq-body .flbqb-zi{
		width:  calc(100% /2  - 22px);
        transition: .3s;
	}
}
@media (max-width:500px) {		
	.flbq-body .flbqb-zi{
		width: 100%;
	}
}

@media (max-width:880px) {	
	.flbq-body{
		margin: 10px;
		margin-top: 20px;
   	 	margin-bottom: 20px;
	}
}

/* 时间线 */

.flbq-body .el-timeline {
    margin-left: -20px;
	margin-top: 10px;
}
.flbq-body .el-timeline-item__content{
	margin-left: -10px;
}
.flbq-body .el-timeline-item__tail{
	/* border-left: 2px solid var(--font-color); */
	border-left:  2px solid rgb(138, 138, 138);
}

.flbq-body .el-timeline-item__timestamp{
	color: var(--font-color);
	line-height: 0.5;
	
}
.flbq-body .el-timeline-item__node--normal {
	width: 4px;
	height: 4px;
	left: 3px;
	border-radius: 50%;
	/* background-color: var(--font-color); */
}
.flbq-body .el-timeline-item__node--normal .el-timeline-item__icon{
    /* left: -2.5px; */
	font-size: 18px;
    /* width: 15px;
    height: 15px; */
	color: var(--app-allColor);
	/* color: var(--font-color); */
	/* background-color:var(--font-color); */
	/* border:3px solid #dbdbdb; */
	transition: .3s;
}
.flbq-body .el-timeline-item:hover .el-timeline-item__icon{

	transition: .3s;
}
.flbq-body .el-timeline-item:hover .el-timeline-item__node{
	/* top: -3px; */
	/* width: 17px;
	height: 17px; */
	cursor: pointer;
	/* background-color:var(--font-color); */
	color: var(--app-allColor);
	animation: rotate 1s  linear infinite;	
}
@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	25% {
		-webkit-transform: rotate(90deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
	}

	75% {
		-webkit-transform: rotate(270deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}		
}
</style>