import axios from "axios";
// import store from '../store'
import { serverUrl } from '@/utils/config.js';
import store from '@/store'//vuex的路径

 // 注意！！ 这里是全局统一加上了 后端接口前缀 前缀，后端必须进行跨域配置！
const request=axios.create({
    baseURL:serverUrl,
    // baseURL:'http://localhost:8090',
    // baseURL:'http://192.168.43.152:8090',
    timeout:10000,
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use((config)=>{
    // console.log(config)
     config.headers['Authorization'] = sessionStorage.getItem("token")  // 设置请求头
    //  config.headers.Authorization=store.state.token 
    store.state.isLoading=true//loading加载动画
    // console.log("开启")
    return config
}, error => {
    return Promise.reject(error)
})

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use( 
    response => {
        let res = response.data;
        if(res.code === 200) {
            store.state.isLoading=false//loading加载动画
            // console.log("关闭")

            return res
        }
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
})


export default request