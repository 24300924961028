// 该文件专门用于创建整个应用的路由器
import VueRouter  from "vue-router"
// import BlogIndex from '../pages/home/BlogIndex'
import MyHome from '../components/body/MyHome'
import ArticleIndex from '../components/body/ArticleIndex'
import WenZhangFlei from '../components/body/WenZhangFlei'
import WenZhangBiaoq from '../components/body/WenZhangBiaoq'
import LiuYanWall from '../components/body/LiuYanWall'
// import FenLeiAndBiaoq from '../components/body/FenLeiAndBiaoq'
import XiangCe from '../components/body/XiangCe'
import SuiBi from '../components/body/SuiBi'
import PersonManage from '../components/body/PersonManage'
import FriendLink from '../components/body/FriendLink'
import ToolBox from '../components/body/ToolBox'

const router= new VueRouter({
    routes:[
        {
            path:'/',
            component:MyHome,
            meta:"像梦",
            children:[
            ]
        },
        {
            path:'/article',
            component:ArticleIndex,
            meta:"文章详情页",
            children:[
            ]
        },
        {
            path:'/category',
            component:WenZhangFlei,
            meta:"文章分类",
            children:[
            ]
        },
        {
            path:'/tag',
            component:WenZhangBiaoq,
            meta:"文章标签",
            children:[
            ]
        },
        // 留言
        {
            path:'/liuyan',
            component:LiuYanWall,  
            meta:"留言",         
            children:[
            ]
        },
        // 随笔
        {
            path:'/suibi',
            component:SuiBi,
            meta:"随笔",
            children:[
            ]
        },
        // 相册
        {       
            path:'/pictures',
            component:XiangCe,
            meta:"相册",
            children:[
            ]
            
        },
        // 个人
        {
            path:'/person',
            component:PersonManage,
        },
        // 友链
        {
            path:'/friendLink',
            meta:"友链",
            component:FriendLink,
        },
        {
            path:"/toolbox",
            meta:"工具箱",
            component:ToolBox,
        }

    ]
})

    // 全局前置路由守卫===初始化的时候被调用，每次路由切换前被调用
    // router.beforeEach((to,from,next)=>{
    //     console.log()
    //     if(to.path == '/sousuojieguo' || to.path == '/bofang'){
    //         // document.title=to.meta.title || '主页'
    //         sessionStorage.setItem('islunbo',false)
    //     }else{
    //         next()
    //         sessionStorage.setItem('islunbo',true)
    //     }
    // })
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
    
router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    /* 路由发生变化修改页面title */
    if (to.meta) {
        document.title = to.meta     
    }
    if(to.path==='/article'){
        // console.log(to.$route,to.query.title)
        document.title=to.query.title
    }
    
    // 兼容IE
    window.scrollTo(0, 0);
    next();
})
    
export default router