<template>
    <div class="footeronor-tuijian-r footeronor-tuijian-zi">
        <div class="" style="font-size: 15px;font-weight: 500;">
            友链 <i class="el-icon-refresh-right"></i>
        </div>
        <div class="" style="display: grid;padding: 5px;">
            <span v-for="index in 4" :key="index">
                <a href="">友链{{index}}</a>
            </span>
      
        </div>               
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>