<template>
    <div class="is-null">
        <div>
            <img src="../../assets/logo/空状态.png">
            <div>空空如也~~</div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.is-null{
    height: 100%;
    width: 100%;
    display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
    color: var(--font-color);
}

.is-null img{
    width: 50%;
}
</style>