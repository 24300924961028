<template>
  <div  class="nav-items">       
        <li v-for="(obj,index) in arr" :key="index">
            <span v-if="obj.roList">
                <i :class="obj.ro_icon"></i>  
                {{obj.ro_name}}
                <div  class="ml-erji" style=""> 
                    <div class="erji-list">

                        <li  v-for="(ond,index) in obj.roList" :key="index">
                            <span 
                            :class="{active:ond.ro_id === findIndex}"
                            @click="btn(ond,ond.ro_id)">
                                <i :class="ond.ro_icon" ></i> 
                                {{ond.ro_name}}
                            </span>
                        </li>
                    </div>
                    
                </div> 
            </span>
                
            <span v-else :class="{active:obj.ro_id=== findIndex}"
                @click="btn(obj,obj.ro_id)">
                <i :class="obj.ro_icon"></i> 
                {{obj.ro_name}}
                    <!-- 二级目录 -->
                       
            </span>
        </li>
<!-- 
                <li ><a href="">Home2</a></li>
                <li><a href="">Home3</a></li>
                <li><a href="">Home4</a></li>
                <li><a href="">Home5</a></li> -->
    
    </div>
</template>

<script>
export default {
    name:"HeaderMulu",
    data() {
    return {    
        arr: [{
          ro_id: "0",
          ro_name: "首页",
          route:'/',
          ro_icon:"iconfont icon-icon1",
          roList:''
        },
        {
          ro_id: "1",
          ro_name: "文章",
          route:'/',
          ro_icon:"iconfont icon-shuben",
          roList:[
          {
                ro_id: "11",
                ro_name: "分类",
                route:'/category',
                ro_icon:"iconfont icon-shujutongjifenxibingtu"
            },
            {
                ro_id: "12",
                ro_name: "标签",
                route:'/tag',
                ro_icon:"iconfont icon-jiagebiaoqian"
            }
            ]
        },
        {
          ro_id: "2",
          ro_name: "动态",
          route:'/',
          ro_icon:"iconfont icon-xiuxiankafeiyule",
          roList:[
            {
                ro_id: "13",
                ro_name: "相册",
                route:'/pictures',
                ro_icon:"iconfont icon-xiangjisheying"
            },
            {
                ro_id: "14",
                ro_name: "随笔",
                route:'/suibi',
                ro_icon:"iconfont icon-dati"
            }
            ]
        },
        // {
        //   ro_id: "2",
        //   ro_name: "友链",
        //   route:'/friendLink',
        //   ro_icon:"iconfont icon-lianjie3",               
        // },
        {
          ro_id: "3",
          ro_name: "留言",
          route:'/liuyan',
          ro_icon:"iconfont icon-benzi",
          roList:''
        },
        // {
        //   ro_id: "4",
        //   ro_name: "生活",
        //   route:'/liuyan',
        //   ro_icon:"iconfont icon-coffee-full",
        //   roList:'',
        //   roList:''
        // },
        // {               
        //     ro_id: "13",
        //     ro_name: "相册",
        //     route:'/pictures',
        //     ro_icon:"iconfont icon-xiangjisheying"
        // },
        // {
        //     ro_id: "5",
        //     route:'/suibi',
        //     ro_name: "随笔",
        //     ro_icon:"iconfont icon-dati",
        //     roList:''
        // },
        {           
            ro_id: "7",
            route:'/toolbox',
            ro_name: "工具",
            ro_icon:"iconfont icon-shangwugongwenbao",
            roList:''
        }
        // {
        //     ro_id:'6',
        //     route:'/person',
        //     ro_name:'个人',
        //     ro_icon:'iconfont icon-iconfuzhi'
        // }
    ],
        
        findIndex:0,
    };
  },
methods: {
    btn(obj,index){
            this.$router.push({
                        path:obj.route,
                    //     query:{
                    //         // search:input,
                    //         t:Date.now()
                    // }, 
            })
        // console.log("idx:",index)
        this.findIndex = index
        }
    },
}
</script>

<style>
.nav-items {
    display: flex;
    list-style: none;
    padding: 0 0 0 40px;
}
.nav-items li{
    /* margin: 0 5px; */
    padding: 0 5px;
    /* padding: 15px; */
    height: 100%;
}
.nav-items li span i{
    font-size: 16px;
    /* font-weight: 600; */
}
.nav-items li span{
    border: 1px solid rgba(255, 255, 255, 0);  /* border-radius: 10px; */
    position: relative;
    padding: 8px;
    cursor: pointer;
    color: var(--font-color);
    /* letter-spacing: px; */
    transition: .3s;
    font-size: 15px;
    /* padding-bottom: 8px; */
    /* font-weight: 600; */
    border-radius: 5px;
    /* text-decoration: none; */
}
.nav-items li span:hover{
    transition: 0.3s;
     /* padding: 0px; */
    /* border-bottom: 4px solid #0c9eff; */
    /* background-color:var(--app-allColor); */
    color:#fff;
    background-color: #ffffffe0;
    border: 1px solid  var(--app-allColor);
    color:  var(--app-allColor);
    transition: .3s;
    /* transition: all  ease 0.5s; */
}

.ml-erji{
    top: 30px;
    z-index: 10;
    position: absolute;
    background-color:transparent;
    /* background-color: #18c4bb; */
    width: 100%;
    display: none;
    /* padding: px; */
 
}
.erji-list{
    margin-left: -5px;
    margin-top: 8px;
    width: 100%;
    background-color: var(--card-bg);
    /* background-color: var(--app-bgColor); */
    /* box-shadow: 0 0.2rem 0.3rem  rgb(0, 0, 0); */
    /* display: none; */
    border-radius: 5px;
    padding: 5px;
    transition: all 300ms ease 0ms; 

}
.ml-erji li{
    /* display: flex; */
    margin-bottom: 2px;
    margin-top:2px ;
    padding: 0px;   
}
.ml-erji li span i{
    font-weight: 400;
}
.ml-erji li span{
    /* display: flex; */
    /* padding-bottom: 10px;   */
    font-size: 14px;
    padding: 7px;
    font-weight: 400;
}
.nav-items li span:hover .ml-erji{  
    display: block;
}
.nav-items li span:hover .ml-erji li span{
    /* margin-top: 4px; */
    display: block;
    /* background: rgba(51, 226, 28, 0.753); */
}


</style>