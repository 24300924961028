<template>
    <div class="footeronor-tuijian-c footeronor-tuijian-zi">
        <div class="" style="font-size: 15px;font-weight: 500;">
        网站推荐
        </div>
        <div class="" style="display: grid;padding: 5px;">
        <span>
            我是1
        </span>
        <span>
            我是www
        </span>
        <span>
            我是网站5
        </span>
        <span>
            我是网站5
        </span>
        </div>               
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>