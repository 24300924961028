<template>
  <div v-if="webSetting!=null&&user!=null" id="app">
	<BeiJing></BeiJing>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <blog-index></blog-index>

	<qie-huan-theme></qie-huan-theme>
	<music-xuan-fu></music-xuan-fu>
	<set-bg></set-bg>
	
	<div id="myblog-box">
		
		<to-top-but></to-top-but>
	</div>
  </div>
  <loading-one v-else></loading-one>
</template>

<script>
import { getws } from './api/webSetting'
import MusicXuanFu from './components/MusicXuanFu.vue'
import QieHuanTheme from './components/QieHuanTheme.vue'
import SetBg from './components/SetBg.vue'
import ToTopBut from './components/ToTopBut.vue'
import BlogIndex from './pages/home/BlogIndex.vue'
import BeiJing from '@/components/BeiJing.vue';
import LoadingOne from '@/pages/errorOrNull/LoadingOne.vue'
import {mapGetters} from 'vuex';
export default {
	computed:{
		...mapGetters([
			'webSetting',
			'user',
		]),
	}, 
	name: 'App',
	components: {
    BlogIndex,
    MusicXuanFu,
    QieHuanTheme,
    ToTopBut,
    SetBg,
	BeiJing,
	LoadingOne
},
	data(){
		return{			
		}
	},
	methods:{	
		// 鼠标点击效果
		note() {
			(() => {
				let a_idx = 0;
				const words = ["富强", "民主", "文明", "和谐", "自由", "平等", "公正", "法治", "爱国", "敬业", "诚信", "友善"];

				window.onclick = event => {
				const heart = document.createElement("b");
				heart.onselectstart = () => false; // 防止拖动

				heart.innerHTML = words[a_idx];
				a_idx = (a_idx + 1) % words.length;

				const f = 16; // 字体大小
				let x = event.clientX - f / 2; // 横坐标
				let y = event.clientY - f; // 纵坐标
				const c = randomColor(); // 随机颜色
				let a = 1; // 透明度
				let s = 1.2; // 放大缩小

				heart.style.cssText = `position: fixed; left: ${x}px; top: ${y}px; font-size: ${f}px; color: ${c}; cursor: default; opacity: ${a}; transform: scale(${s});`;
				document.body.appendChild(heart);

				const timer = setInterval(() => {
					if (a <= 0) {
					document.body.removeChild(heart);
					clearInterval(timer);
					} else {
					y--;
					a -= 0.016;
					s += 0.002;
					heart.style.top = `${y}px`;
					heart.style.opacity = a;
					heart.style.transform = `scale(${s})`;
					}
				}, 15);
				};

				// 随机颜色
				const randomColor = () => `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`;
			})();
			},

			getWebIndex() {
			getws().then(res => {
				this.$store.commit('setUser', res.data.user);
				this.$store.commit('setWebSetting', res.data.web);
			});
		},


		getWebIndex(){
			getws().then(res=>{				
				this.$store.commit('setUser',res.data.user)
				this.$store.commit('setWebSetting',res.data.web)
			})
		}
	},
	mounted() {	
		window.onscroll =function() {scrollFunction()};
		function scrollFunction() {
			// console.log(121);
			if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {		
				// document.getElementById("myblog-box").style.display = "block";		
				document.getElementById("myblog-box").style.visibility="visible"	
				document.getElementById("myblog-box").style.opacity="1"	
				document.getElementById("myblog-box").style.transition="0.4s"
			} else {
				document.getElementById("myblog-box").style.visibility="hidden"	
				document.getElementById("myblog-box").style.opacity="0"	
				document.getElementById("myblog-box").style.transition="0.4s"

			}
			
		}
		this.note()
  	},

	created(){
		this.getWebIndex()
		document.documentElement.style.setProperty("--themeColor",this.webSetting.themeColor)
	}
}
</script>

<style >
@import "@/assets/css/font1/font.css";
@import "@/assets/css/font2/font.css";
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
html,
body,
div,
span,
h1,
h2,
h3,
p {
	margin: 0;
	padding: 0;
}

html,
body,
#app {
	width: 100%;
	height: 100%;
	/* position: relative; */
	/* font-family: "_GB2312 FangSong_GB2312"; */
	font-family :  'MyFont1';
	/* font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Lato,Roboto,'PingFang SC','Microsoft YaHei',sans-serif; */
	/* font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; */
}
/* 解决页面dialog出现时，页面滚动条出现消失导致的Message消息提示抖动问题 */
body {
	overflow-y: auto !important;
	padding-right: 0 !important;
	font: BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
	background: var(--app-bgColor); 
	position: relative;
}
div{
	border-radius: 0 !important;
}
ul,
ol {
	list-style: none;
}

a {
	text-decoration: none;
}
img[lazy="loading"]{
  display:block;
  /* width:100% !important; */
  height:50px !important;
  /* margin:0 auto; */
}
img[lazy="error"]{
  display:block;
  width:100px !important;
  height:100px !important;
  /* margin:0 auto; */
}

.faguang {
	border-radius: 10px;
	/* background: var(--card-tm-bg); */
	/* border-radius: 5px; */
	/* margin-top: 10px;	 */
	position: relative;
	color: var(--font-color);
	/* background: var(--card-tm-bg); */
	/* width: 100%; */
	/* background-color: var(--app-divbgcColor); */	
	/* background: #FFFFFF; */
	transition: all .3s ease-in-out 0s;
	/* box-shadow: 0 1px 5px var(--text-shadow); */
	/* border: 1px solid var(--app-boderColor); */
	/* box-shadow:  0px 2px  6px 2px var(--blogCradShowColor); */
	/* backdrop-filter: blur(10px); */
	/* box-shadow: 0 5px 20px 2px ; */
             /* -6px -6px 4px #ffffff; */
	/* border: 1px solid #474747; */
	/* background:var(--headertoumingc) ;
	backdrop-filter:blur(5px); */
	/* border-top: 1px solid  rgba(167, 167, 167, 0.5); */
	/* border-bottom :1px solid rgba(167, 167, 167, 0.5); */
	/* border-right :1px solid rgba(167, 167, 167, 0.5); */
	/* border-left :1px solid rgba(167, 167, 167, 0.5); */
	/* box-shadow:  6px 6px 10px var(--box-shadowColorA),
             -6px -6px 10px var(--box-shadowColorB); */
}
.faguang:hover {
	transition: .3s;
    /* border: 1px solid  var(--app-allColor); */
	/* transform: translateY(-8px); */
	/* border: 1px solid var(--app-allColor); */
	/* box-shadow:  6px 6px  4px #969696 */
             /* -6px -6px 4px #ffffff; */
	/* box-shadow: 0 0 4px 2px var(--app-allColor); */
	/* cursor: pointer; */
	/* text-shadow: 0 0 1px #25ccf7;
	color: #25ccf7; */
	}
/* .faguang::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	transition: 0.5s;
	transform: scale(0.9);
	z-index: -1;
} */
/* .faguang:hover::before {
	box-shadow: 0 0 5px 2px var(--app-allColor);
	transform: scale(1.01);
} */

/* 滚动条样式 */
::-webkit-scrollbar{
    /*width: 100px !important;*/
    width: 5px;
    /* border-radius: 5px; */
    background-color: var(--card-tm-bg); 
}
::-webkit-scrollbar-thumb{/*滚动条里面小方块*/
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.603);
}

#myblog-box{
	min-height:10px;
	/* width: 80px; */
	position: fixed;
	z-index: 99;
	/* background-color: rgba(220, 17, 17, 0.879); */
	right: 0px;
	bottom: 20px;
	padding: 10px;
	opacity: 0;
	visibility: hidden;
}
.el-pagination.is-background .btn-next, 
.el-pagination.is-background .btn-prev, 
.el-pagination.is-background .el-pager li {
    margin: 0 5px;
    /* background-color: #252529; */
	background-color: var(--card-tm-bg);

    border-radius: 0 !important;
	color: var(--font-color);
    min-width: 30px;
    /* border-radius: 2px; */
	border: 1px solid #7474748e;
}


:root{
	--app-allColor:var(--themeColor,#4082EF);
}

</style>
