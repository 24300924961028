import Vue from "vue"
import Vuex from "vuex"
import blogSetting from "./blogSetting"

Vue.use(Vuex)

// 准备actions 用于响应组件中的动作
const actions={
    updateSkin(context, value){
      // 修改getskin的值
        context.commit('setSkin',value)
    }
}
// 准备mutations 用于操作数据（state）
const mutations={
    setSkin(state, newValue){
        state.skin = newValue
        window.sessionStorage.setItem('skin',JSON.stringify(state.skin))
    }
}

// 准备state  用于存储数据
const state={
    skin:'dark',//light浅色模式  //dark深色模式

    isLoading: false

}


// 创建store
export default new Vuex.Store({
    actions,
    mutations,
    state,
    modules:{
        blogSetting,
        // webSetting,
    }
})




 
// export default new Vuex.Store({
//   state: {
//     skin:'dark',//shallow浅色模式  //deep深色模式
//   },
  
//   getters: {

//   },
  
//   mutations: {
//     getskin(state, newValue){
//       state.skin = newValue
//     }
//   },

//   actions: {
//     setSkin(context, value){
//       // 修改getskin的值
//       context.commit('getskin',value)
//     }
//   },
//   modules: {

//   },
// });
