<template>
    <div class="blog-footer">
      <div class="footer-box">

        <div class="blog-footer-one">   
            <div class="footer-one-l footer-one-zi">
              <footer-my-inf></footer-my-inf>
            </div>


            <div class="footer-one-r footer-one-zi">
              <footer-r-one></footer-r-one>
              <!-- <footer-r-two></footer-r-two>
              <footer-r-three></footer-r-three> -->
            </div>
        </div>
        
        <div class="blog-footer-two">
          <a href="https://beian.miit.gov.cn/">
            粤ICP备2024178589
          </a>

        </div>
      </div>
    </div>
</template>

<script>
import FooterMyInf from '@/components/footer/FooterMyInf.vue'
import FooterROne from '@/components/footer/FooterROne.vue'
import FooterRTwo from '@/components/footer/FooterRTwo.vue'
import FooterRThree from '@/components/footer/FooterRThree.vue'
export default {
  components: { FooterMyInf, FooterROne, FooterRTwo, FooterRThree },
  name:"BlogFooter"
}
</script>

<style>
.blog-footer{
  position: relative;
  /* position: relative; */
  color: var(--font-color);
  /* position: relative; */
  /* z-index: 0; */
  /* min-height: 100px; */
  width: 100%;
  display: flex;   
  justify-content: center;
  align-items: center;
  transition: .3s;
  /* border-top: 0.5px solid #e0e3ed67; */
  /* margin-top: 10px; */
  /* background-color:var(--app-divbgcColor); */
  /* background:var(--footer-bg);    */
  background: var(--card-tm-bg);
  backdrop-filter: blur(5px);
  /* background-color: rgba(230, 230, 230, 0.267); */
}
.footer-box{
  width: 100%;
  max-width: 1250px;
  /* background-color: aqua; */
}

.footer-one-r{
  width: calc(70% - 20px);
  /* background-color: aqua; */
  display: flex;
  padding-top: 15px;
}
.footeronor-tuijian-zi{
  color: var(--font-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  /* width:calc(100%/2 - 20px);  */
  margin: 0 10px;
  height: 100%;
  /* background-color: antiquewhite; */
}
.footeronor-tuijian-zi span{
  font-weight: 400;
  font-size: 13px;
  padding: 3px;
}
.footeronor-tuijian-zi a{
  color: var(--font-color);
  transform: .3s;
}
.footeronor-tuijian-zi a:hover{
  transform: .3s;
  color: var(--app-allColor);
}
.blog-footer-two{
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color:var(--card-tm-bg); */
}

.blog-footer-two a{
  font-size: 14px;
  color: var(--font-color);
}

@media (max-width:610px) {
  .footer-my-Inf .my-touxinag{
    display: grid;
  }		
  .footer-my-Inf .footer-my-logo{
    margin: 0;
  }

  /* .footeronor-tuijian-zi{
    width:  calc(100%/2 - 20px);
    padding-left: 20px;
  }

  .footeronor-tuijian-r{
    display: none;
  } */
} 


</style>