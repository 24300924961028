<template>
  <div class="twikoo-comment">
    <div class="comment-pinglun">
      <i class="iconfont icon-tamenduyongguo">  评论</i>            
    </div>
    <!-- other contents -->
    <MyComment :toComment="article_id"></MyComment>
  </div>
</template>

<script>
import MyComment from './MyComment.vue'
export default {
 
  name:'MyTwiKoo',
  components: {
    MyComment,
  },
  props:{
    article_id:{
        type: String,
    }
  },
};
</script>
<style>
.comment-pinglun{
    /* height: 70px; */
    /* background-color: #189ef7; */
    border-bottom:  2px dashed var(--font-color) ;
    padding-bottom: 10px;
}
.comment-pinglun i{ 
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 10px;
}
.twikoo-comment{
    padding: 10px;
    color: var(--font-color);
    padding-bottom: 40px;
}
.twikoo-comment .tk-comment{
  margin-bottom: 10px;
}
.twikoo-comment .tk-actions a{
  color: var(--app-allColor);
  /* color: #c71b1b; */
}
.twikoo-comment .tk-content{
  border-left: 1px solid #7474748e;
  border-bottom: 1px solid #7474748e;
  padding: 8px;
  background: var(--card-tm-bg);
}
.twikoo-comment .tk-content span{
  font-size: 14px;
}
.twikoo-comment .tk-ruser{
  color: var(--app-allColor);
}
.twikoo-comment .tk-footer{
  display: none;
}
</style>

  