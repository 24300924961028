<template>
    <div class="weather-info faguang">
        <div class="nc-top" style="padding: 10px;">
            天气
        </div>

        
        <div style="display: flex;margin-top: 10px;">
            <div class="left-lanmu">
                <div>
                    日期
                </div>
                <div>
                    白天
                </div>
                <div>
                    夜晚
                </div>
            </div>

            <div class="weather-address">
                {{ weatherData.province }} - {{ weatherData.city }}
            </div>

            <div class="wrapper" v-if="dataList.length!=0">
                
                <template v-for="(w,i) in dataList" >
                    <div class="item" :class="'item'+i" :key="i" @mousemove="isMove">
                        <div>
                            {{dataSubString(w.date)}} {{toWeek(w.week)}}
                        </div>
                        <div >
                            <!-- <i class="el-icon-light-rain" title="多云"></i> -->
                            <span>{{ w.dayweather }}</span> / <span> {{ w.daytemp }}℃</span>
                        </div>
                        <div>
                            <!-- <i class="el-icon-lightning"></i> -->
                            <span>{{ w.nightweather }}</span> / <span> {{ w.nighttemp }}℃</span>
                        </div>
                    </div>  
                </template>
            </div>     
            <div v-else>
                <loading-two style="margin-left: 30px;"></loading-two>
            </div>
            
        </div>

        
    </div>
</template>

<script>
import axios from 'axios'
import LoadingTwo from '@/pages/errorOrNull/LoadingTwo.vue';
import { getMessageIp } from '@/api/message'
export default {
    name:"WeatherInfo",
    data(){
        return{
            weatherData:{
                city:'',
                province:''
            },
            dataList:[]
        }
    },
    components: { LoadingTwo },
    methods:{
        dataSubString(data){
            return data.substring(5, 10)
        },

        toWeek(week){
            if(week=="1"){
                return "周一"
            }else if(week=="2"){
                return "周二"
            }else if(week=="3"){
                return "周三"
            }else if(week=="4"){
                return "周四"
            }else if(week=="5"){
                return "周五"
            }else if(week=="6"){
                return "周六"
            }else if(week=="7"){
                return "周日"
            }
        },

        isMove(){
            // 获取所有 item 元素
            const items = document.querySelectorAll('.item');

            // 为每个 item 元素添加鼠标悬停和离开事件监听器
            items.forEach(item => {
                item.addEventListener('mouseover', () => {
                    items.forEach(i => i.classList.add('paused'));
                });

                item.addEventListener('mouseout', () => {
                    items.forEach(i => i.classList.remove('paused'));
                });
            });
        },

        // 获取高德天气信息
        getGaoDeWeatherData(code){
            axios({         
                url:`https://restapi.amap.com/v3/weather/weatherInfo?city=${code}&key=9ab5e1012e467822d65870cb1270e340&extensions=all`,
                method: 'GET',
                // data: {
                //     output:'jsonp' 
                // },
                // dataType:"jsonp",
            }).then(
                res =>{
                    this.weatherData=res.data.forecasts[0];
                    this.dataList=res.data.forecasts[0].casts
                    // console.log("天气信息：",res.data.forecasts[0])
                } 
            )
        }
    },
    mounted(){        
        getMessageIp().then((res)=>{
            // console.log(res.data)           
            this.getGaoDeWeatherData(res.data.cityCode)
        })
        
    }
}
</script>

<style>
.weather-info{
    margin-top: 20px;
	border-bottom:1px solid #7474748e;
    color: var(--font-color);
    padding-bottom: 20px;
    position: relative;
}
.weather-info .wrapper {
    width: 100%;
    max-width: 300px;
    margin-left: 40px;
    margin-inline: auto;
    position: relative;
    height: 150px;
    /* margin-top: 5rem; */
    overflow: hidden;
    mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 20%,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 0)
    );
}

.weather-address{
    position: absolute;
    font-size: 14px;
    right: 10px;
    top: 15px;
}

@keyframes scrollLeft {
    to {
        left: -120px;
    }
}
.left-lanmu{
    width: 35px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    position:relative;
    /* left: -5px; */
}
.left-lanmu>div{
    font-size: 14px;
}
.weather-info .item  {
    font-size: 13px;
    width: 100px;
    height: 100px;
    position: absolute;
    left: max(calc(90px * 4), 100%);
    animation-name: scrollLeft;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 5px;
    border-radius: 10px !important;
    backdrop-filter: blur(30px);
    background-color: rgba(80, 80, 80, 0.096);
    border: 1px solid rgba(255, 255, 255, 0.089);
    cursor: pointer;
}

.weather-info .item1 {
    animation-delay: calc(30s / 4 * (4 - 1) * -1);
}

.weather-info .item2 {
    animation-delay: calc(30s / 4 * (4 - 2) * -1);
}

.weather-info .item3 {
    animation-delay: calc(30s / 4 * (4 - 3) * -1);
}

.weather-info .item4 {
    animation-delay: calc(30s / 4 * (4 - 4) * -1);
}



/* 新增暂停动画的类 */
.paused {
    animation-play-state: paused !important;
}
</style>