<template>
    <div ref="twikoo"></div>
  </template>
  
  <script>
  import Twikoo from 'twikoo';
  
  export default {
    name:'MyComment',
    props:{
      toComment:{
          type: String,
      }
    },
    mounted() {
      // console.log(this.toComment)
      new Twikoo({
        el: this.$refs.twikoo,
        envId: 'http://xiangmeng.xyz/twikoo',
        path: 'article/'+this.toComment,
        theme: 'light',
        // 更多选项...
      });
    }
  }
  </script>>
  