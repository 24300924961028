<template>
    <div class="home-category faguang">
        <div class="nc-top" style="padding: 10px;">
            文章分类
        </div>
        <div class="home-category-zi">
            <div class="category" v-for="(c,index) in categoryList" :key="index">
                
                <span @click="toCategory(c.categoryName)">{{ c.categoryName }}</span>
                <!-- <el-divider :key="index" direction="vertical"></el-divider> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getCategoryAll } from '../../../api/tagAndCategory'
export default {
    name:"HomeCategory",
    data(){
        return{
            categoryList:''
            // categoryList:[]
        }
    },
    methods:{
        cList(){
            getCategoryAll('').then((res)=>{
                this.categoryList=res.data
            })
        },
        toCategory(val){
            this.$router.push({
                path:'/category',
                query:{
                    search:val,
                    t:Date.now()
                }, 
            })
        }
    },
    mounted(){
    },
    created(){
        this.cList()
    }
}
</script>

<style>
.home-category{
    margin-top: 20px;
    padding-bottom: 10px;
    color: var(--font-color);
    width: 100%;
    border-bottom:1px solid #7474748e;
}
.home-category-zi{
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 10px 10px;
}
.home-category-zi .category{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2px 0;
    border-right:1px solid #7474748e;
    /* color: var(--font-color) */
   
}
.home-category-zi .category span{
    /* font-weight: 00; */
    font-size: 14px;
    /* padding: 5px; */
    cursor: pointer;
    margin:0 6px;
    transition: .3s;
    /* background: #7d7b7b; */
}
.home-category-zi .category span:hover{
    transition: .3s;
    color: var(--app-allColor);
}
</style>