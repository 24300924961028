<template>
    <div class="article-bj gongxiang-top faguang" id="faguang" :style="{backgroundImage:'url('+files.myblogImageUrl+toBiaotiTop.bgi+')'}">
		<div class="article-bj-zi">
		
			<!-- 标题 -->
			<div class="article-bj-title">				
				<span v-if="toBiaotiTop.title!=null">{{ toBiaotiTop.title }}</span>
				<span v-else class="el-icon-loading" ></span>
			</div>

			<div class="article-bj-tyle">
				<div class="articlebjtyle-top">
					<span class="articlebjtyle-top-left-yes" style="">
						<span class="iconfont icon-shujutongjifenxibingtu"></span> 分类
					</span>
					<span v-if="toBiaotiTop.category==null" class="el-icon-loading" style=" height: 12px;width: 12px;margin-top: 2px;"></span>							
					<span v-else > {{toBiaotiTop.category.categoryName}} </span>											
				</div>
				<div class="articlebjtyle-top" v-if="toBiaotiTop.tags==null">
					<span class="el-icon-loading" style=" height: 12px;width: 12px;margin-top: 2px;"></span>							
				</div>

				<div class="articlebjtyle-top" style="display: flex;font-weight: 500;margin:4px;">
					<span class="articlebjtyle-top-left-yes" style="">
						<span  class="iconfont icon-jiagebiaoqian"></span>
						标签
					</span>
					<span v-for="(t,i) in toBiaotiTop.tags" :key="i"  style="display: flex;">
						<!-- <div class="article-bj-tag">
							<div :style="{borderRightColor :`${t.tagColor}`}" class="tag-tou"></div>
							<div :style="{backgroundColor :`${t.tagColor}`}" class="tag-body">hhh</div>
						</div> -->
						<div style="margin:0 2px;padding:0 5px 0 2px;border-radius: 4px !important;" :style="{background :`${t.tagColor}`}">
							<!-- <span :style="{color :`${t.tagColor}`}" class="iconfont icon-jiagebiaoqian" style="margin:0px;"></span> -->
							<span style="margin:0px;color: #fff;font-size: 11px;">#{{ t.tagName }}</span>
						</div>
						
					</span>
					<!-- <span>#标签2</span> -->
				</div>
			</div>
			

			<div class="article-bj-ainf">
				<div class="articlebjainf-bottom">
					<!-- <i class="iconfont icon-icon-"> 阅读量</i> -->
					<span class="articlebjtyle-top-left-yes" style="">
						<i class="iconfont icon-icon-"> </i> 阅读量
					</span>
					<span v-if="toBiaotiTop.read!=null">{{ toBiaotiTop.read }}</span>
					<span v-else class="el-icon-loading" style=" height: 12px;width: 12px;margin-top: 2px;"></span>							
				</div>
				<div class="articlebjainf-bottom">
					<span class="articlebjtyle-top-left-yes" style="">
						<i class="iconfont icon-jisuanqi_o"> </i> 总字数
					</span>
					<!-- <i class="iconfont icon-jisuanqi_o"> 总字数</i> -->
					<span v-if="toBiaotiTop.toZiSum!=null">{{ toBiaotiTop.toZiSum }}</span>	
					<span v-else class="el-icon-loading" style=" height: 12px;width: 12px;margin-top: 2px;"></span>
				</div>
				<div class="articlebjainf-bottom">
					<span class="articlebjtyle-top-left-yes" style="">
						<i class="iconfont icon-rili1"> </i> 发表时间
					</span>
					<!-- <i class="iconfont icon-rili1"> 发表时间</i> -->
					<span v-if="toBiaotiTop.ctime!=null">{{ toBiaotiTop.ctime }}	</span>
					<span v-else class="el-icon-loading" style=" height: 12px;width: 12px;margin-top: 2px;"></span>												
				</div>
				<div class="articlebjainf-bottom" v-if="toBiaotiTop.utime">
					<span class="articlebjtyle-top-left-yes" style="">
						<i class="iconfont icon-gengxinshijian"> </i> 更新时间
					</span>
					<span> {{ toBiaotiTop.utime }}</span>							
				</div>
				<!-- <div>
					<i class="iconfont icon-shijian2"> 阅读时长：1分钟</i>							
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name:'ArticleBjAndTit',
    props:{
        toBiaotiTop:{
			type: Object,
            required: true,
		}
    },
	methods:{

	}
}
</script>

<style>
.article-bj{
	/* position: absolute; */
	/* position: relative; */
	top: 70px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	/* position:absolute;	 */
	/* background-color: #393a3a5e; */
	/* border: 1px solid rgba(255,255,255,0.5); */
	width:calc(100% - 40px);
	/* top:calc( -500px + 75px); */
	height: 430px;
	display: flex;
	border-radius: 10px;
	justify-content: center;
    align-items: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	/* background-image: url(../../../assets/logo/39053.jpg);	 */
}
.articlebjtyle-top-left-yes{
	border-radius: 5px ;
	background: var(--app-bgColor); 
	position: relative;
	padding: 2px 5px 2px 8px;
	margin-right: 5px;
	font-size: 12px;
}
.articlebjtyle-top-left-yes::before{
	content: "";
	position: absolute;
	width: 4px;
	top: 0;
	border-radius: 0px;
	left: 0;
	height: 100%;
	background: var(--app-allColor);
}
.article-bj::before{
	content: "";
	position: absolute;
	z-index: 1;  
	width:100%;
	height: 100%;
	background-color: var(--blog-gonxiang-tm);
	background-size: 100% 100%  ;         
	background-position: center;
	background-size: cover;
	opacity: 1; 
}
.article-bj .article-bj-zi{
	z-index: 2;
	margin: 20px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 20px;
	position: relative;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.195);
	background:var(--card-tm-bg);
    backdrop-filter: blur(5px);
	/* background-image: url(../../../assets/logo/meixi.jpg);
	min-height: 300px;
	padding: 30px;
	width: 60%;
    display: flex; */
    /*设置上边界和左边界亮纹，使card有一种层次感*/
	border-radius: 5px !important;
	border: 1px solid rgba(255, 255, 255, 0.18);
    /* border-bottom: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5); */
	/* border: 1px solid rgba(255,255,255,0.5);
    background: rgba(255,255,255,0.1); */
}

.article-bj-tag{
	display: flex;
	position: relative;
	margin: 0 2px;
}

.article-bj-tag .tag-tou:after{
	content: "";
	padding: 0;
	position: absolute;
	height: 6px;
	width: 6px;
	top: 35%;
	left: 6px;
	border-radius: 50% !important;
	background: #e0e0e0;
	box-shadow: inset 5px 5px 10px #656565,
				inset -5px -5px 10px #ffffff;
}
.article-bj-tag .tag-tou{
	height: 1px;
	width: 1px;
	border-top: 9px solid transparent;
	border-bottom: 9px solid transparent;
	border-right: 10px solid var(--card-tm-bg);
}
.article-bj-tag .tag-tou:before{
	content: "";
	position: absolute;
	top: -2%;
	z-index: -1;
	width: calc(100% - 10px);
	left: 10px;
	height: 104%;
	border-radius: 2px !important;
	background-color:var(--card-tm-bg);
	background-size: 100% 100%  ;         
	background-position: center;
	background-size: cover;
	opacity: 1; 
}
.article-bj-tag .tag-body{
	border-radius: 0 2px 2px 0 !important;
	color: #787878a4;
	display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	padding:0  5px ;
	
}
.article-bj .article-bj-zi .iconfont{
	font-size: 13px;
}
.article-bj .article-bj-zi .article-bj-tyle {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
}
.article-bj .article-bj-zi .article-bj-title span{
	transition: .2s;
	/* background-color: #00FFFF; */
	/* width: 100%; */
	color: transparent;
    /* text-shadow: 0px 0px 1px rgb(222 222 222 / 74%), 0px 4px 4px rgb(0 0 0 / 44%); */
	/* color: #ffffff; */
	color: var(--font-color);
	/* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
	letter-spacing: 2px;
	padding-left: 5px;
	font-size: 35px;
	line-height: 1.6;
	-webkit-line-clamp: 3;
	font-weight: 600;
}
.article-bj .article-bj-zi .article-bj-ainf{
	display: flex;
	flex-wrap: wrap;
	/* padding: 10px; */
}
.article-bj .article-bj-zi .article-bj-ainf .articlebjainf-bottom,
.article-bj .article-bj-zi .article-bj-tyle .articlebjtyle-top{
	display: flex;
	margin: 4px;
	padding:0 5px 0 0px;
	color: transparent;
	line-height: 18px;
	/* text-shadow:
	0px 0px 1px rgba(255, 255, 255, 0.685),
	0px 4px 4px rgba(0, 0, 0, 0.055); */
	/* color: #d8d8d8; */
	color: var(--font-2-color);
	/* border: 1px solid #4e4e4e80; */
	/* background-color: #4e4e4e80; */
	background:var(--card-tm-bg);
	border-radius: 5px !important;
    align-items: center;
    text-align: center;
	font-size: 12px;
	/* font-weight: 300; */
}
.article-bj .article-bj-zi .article-bj-ainf .articlebjainf-bottom i{
	/* font-weight: 300; */
	font-size: 12px;
}
@media (max-width:880px) {	
	.article-bj{
		width:calc(100% - 20px);
		height: 300px;
	}
	/* 标题 */
	.article-bj .article-bj-zi .article-bj-title span{
		font-size:26px;	
		transition: .2s;
	}
}

@media (max-width:600px) {	
	/* 磨砂框 */
	.article-bj .article-bj-zi{
		padding: 10px;
	}
	/* 标题 */
	.article-bj .article-bj-zi .article-bj-title span{
		font-size:22px;
		transition: .2s;
	}
	.article-bj .article-bj-zi .article-bj-tyle span{
		font-size: 12px;
	}
	.article-bj .article-bj-zi .article-bj-ainf div i {
		font-size: 12px;
	}
}

</style>