<template>
    <div class="sui-bi-index">
        <gongxiang-top :togonggongTop="{page_title:'随笔',page_bg:webSetting.webSbbgi,msg:''}"></gongxiang-top>

        
        <div class="sui-bi enter-x-left">
            <el-timeline>
                <el-timeline-item
                    v-for="(s, index) in suibidata"
                    :key="index"
                    icon="iconfont icon-fengche1"                 
                    :timestamp="s.suibiCtime">

                    <div class="suibi-text faguang" id="faguang">
                        <!-- <i class="iconfont icon-luyin"></i> -->
						
                        <div class="suibi-image" v-if="s.suibiImg">
                            <i class="iconfont icon-annex-full"></i>
                            <img  :src="files.myblogImageUrl+s.suibiImg" @click="opentupian(files.myblogImageUrl+s.suibiImg)" alt="">
                        </div>
                        <div class="text-html" id="text-html" v-html="s.suibiText"> </div>
                        
                        
                    </div>    
    
                </el-timeline-item>		
            </el-timeline>
        </div>

         <!-- v-html解析图片点击浏览 -->
         <transition
            enter-active-class="animate__animated animate__fadeIn 0.5s">
            <image-viewer
                v-if="imgViewerVisible"
                :url-list="srcListView"
                :on-close="closeViewer"
                :z-index="3000"
            />
        </transition>
    </div>
    
</template>

<script>
import ImageViewer from 'element-ui/packages/image/src/image-viewer'
import GongxiangTop from './gonxiangtop/GongxiangTop.vue'
import {mapGetters} from 'vuex';
import { getAllSuiBi } from '@/api/suibi';

export default {
    name:"SuiBi",
    computed:{
        ...mapGetters([
            'webSetting',
        ]),
    },
    components: {
        ImageViewer,
        GongxiangTop,
    },
    data(){
        return{
            suibidata:[],
            srcListView:'',      
            imgViewerVisible:false,

            length1:200,
        }
    },
    methods:{
        opentupian(img){      
            this.onPreview([img]) 
            // this.srcListView=idx
            // this.onPreview([idx]) 
            // this.imgList.push(idx)
        },
        //开启富文本图片预览
        onPreview(img) {
            this.imgViewerVisible = true;  
            this.srcListView = img
        },
        //关闭富文本渲染文本图片预览
        closeViewer() {
            this.imgViewerVisible = false;            
            document.body.style.overflow = 'auto';
        },

        list(){
            getAllSuiBi('').then((res)=>{
                // console.log(res)
                this.suibidata=res.data
            })
        },

        upDom(){            
                const ifms=document.querySelectorAll(".text-html iframe")
                console.log("我是",ifms)
                ifms.forEach((item) => {
                    let pre = item.parentElement;
                    // console.log("呀呀呀111",pre)
                    pre.setAttribute('style', 'position: relative;padding: 30% 45%;')
                })
            
        }

    },
    mounted(){
        this.list()
        // console.log(this.webSetting.webSbbgi)
        
        setTimeout(() => {
			this.upDom()
		}, 2000); 
        
    },
    created(){
        
    }
}
</script>

<style>
.sui-bi-index{
    font-family: 'Long Cang', cursive;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    /* display: flex;   */
    /* border-radius: 10px 10px 10px 10px; */
	/* margin-top: 10px;	 */
	position: relative;
	width: 100%;
}
.sui-bi{
    /* width: calc(100% -20px); */
    margin: 10px;
    height: 100%;
    /* background-color: aqua; */
}

.suibi-text{
    position: relative;
    width: calc(100% - 20px);
	/* box-sizing: border-box; */
	/* max-width: 300px; */
	/* font-size: 20px; */
    min-height: 50px;
	/* border-radius: 10px; */
    background:beige;
	/* background-image: linear-gradient(var(--suibi-bgcolor) 27px, var(--suibi-xiancolor) 26px); */
	/* background: #ffffff; */
	/* background-image: linear-gradient(#f1f1f19a 27px, #ccc 26px); */
    /* color: rgb(66, 66, 66); */
	background-size: 100% 28px;
	/* line-height:25px; */
	/* padding: 1.4rem 0.5rem 0.3rem 4.5rem; */
	padding: 10px;
    box-shadow: 0 1px 5px var(--text-shadow); 
	background:var(--card-tm-bg);
    backdrop-filter: blur(5px);
	/* background-image: url(../../../assets/logo/meixi.jpg);
	min-height: 300px;
	padding: 30px;
	width: 60%;
    display: flex; */
    /*设置上边界和左边界亮纹，使card有一种层次感*/
	border-radius: 5px !important;
	border: 1px solid rgba(255, 255, 255, 0.18);
}
.suibi-text::before{
    content: "";
	position: absolute;
	z-index: -1;  
    left: 0px;
    top: 0;
	width:100%; 
    height: 100%;
    background: var(--suibi-tm-color); 
}
/* .suibi-text h1,
.suibi-text h2,
.suibi-text h3,
.suibi-text span
{ 
    color: var(--font-color) !important;
    background-color: transparent !important;
}
.suibi-text p { 
    color: var(--font-color) !important;
    background-color: transparent;
	font-size: 16px;
	text-indent: 1rem;
    letter-spacing: 1px;
	line-height: 24px;
} */

.suibi-text-index{
	display: -webkit-box;
    -webkit-box-orient: vertical;   
    white-space:nowrap;
	/* line-height:30px; */
    -webkit-line-clamp:2;
    /* margin: 10px 20px 20px 20px; */
    overflow: hidden;
	/* position: absolute; */
	word-break: break-word;
}
/* .suibi-text .icon-luyin{
    position: absolute;
    left: 5px;
    top: 5px;
} */
.suibi-text .text-html{
    position: relative;
    color: var(--font-color) !important;
}

.suibi-text .icon-annex-full{
    position: absolute;
    top: -17px;
    right: 5px;
    z-index: 2;
    color: var(--app-allColor);
}
.suibi-text .suibi-image{
    /* width: 100%; */
    /* overflow: hidden; */
    position: relative;
    /* position: absolute; */
    width: 90px;
    height: 80px;
    float: right;
    /* right: -10px;
    top:0; */
    /* z-index: 1; */
    /* background-color: aqua; */
   
}
.suibi-text .suibi-image img{
    /* right: -20px; */
    position: absolute;
    object-fit: cover;
    top: -15px;
    /* height: 50px; */
    width: 60px;
    right: 0;
    cursor: zoom-in;
	opacity: 0.8; 
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    border: 2px solid white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);  
}

/* 时间线 */
.sui-bi .el-timeline {
    /* position: relative;
    left: 50%; */
    margin-left: 50%;
    padding: 0;
}

.sui-bi .el-timeline-item__wrapper{
    padding-left: 20px;
}

.sui-bi .el-timeline-item:nth-child(2n+1) .el-timeline-item__content{
    transform: translateX(calc(-100% - 30px));
    /* margin-left: -60px; */
}
.sui-bi .el-timeline-item:nth-child(2n+1) .el-timeline-item__timestamp{
    margin-left: -95px;
}

.sui-bi  .el-timeline-item__timestamp{
	color: var(--font-color);
}

.sui-bi .el-timeline-item__node--normal {
	width: 4px;
	height: 4px;
	left: 2px;
	border-radius: 50%;
	background-color: var(--font-color);
}

.sui-bi  .el-timeline-item__node--normal .el-timeline-item__icon{
    /* left: -2.5px; */
	font-size: 18px;
    /* width: 15px;
    height: 15px; */
	/* color: var(--app-allColor); */
    color: var(--app-allColor);
	/* background-color:var(--font-color); */
	/* border:3px solid #dbdbdb; */
	transition: .3s;
}
.sui-bi .el-timeline-item:hover .el-timeline-item__icon{

    transition: .3s;
}
.sui-bi .el-timeline-item:hover .el-timeline-item__node{
/* top: -3px; */
	/* width: 17px;
	height: 17px; */
	cursor: pointer;
	/* background-color:var(--font-color); */
	color: var(--app-allColor);
	animation: rotate 1s  linear infinite;		
}
@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	25% {
		-webkit-transform: rotate(90deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
	}

	75% {
		-webkit-transform: rotate(270deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}		
}

@media (max-width:620px) {	

}
</style>