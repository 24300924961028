<template>
 

    <div class="loader-box">
        <div class="spinner">
            <!-- <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
            <img style="height: 74px;width: 74px;border-radius: 50%;"
              src="https://q3.qlogo.cn/headimg_dl?dst_uin=2415648320&spec=100" alt=""> -->
            
            <div class="container">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <img style="height: 74px;width: 74px;border-radius: 50%;"
              src="https://q3.qlogo.cn/headimg_dl?dst_uin=2415648320&spec=100" alt="">
            <div style="position: absolute;bottom: -20px;width: 100%;text-align: center;color:#ffffff">
                 
                <div class="loader-two">
                  <div style="font-size: 15px;margin-right: 5px;font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif">加载中</div>
                  <div class="dot dot-1"></div>
                  <!-- <div class="dot dot-2"></div>
                  <div class="dot dot-3"></div> -->
                  <div class="dot dot-4"></div>
                  <div class="dot dot-5"></div>
                </div>

            </div>
        </div>          
	</div>
</template>

<script>
export default {
    name:'LoadingOne',
}
</script>

<style>
.loader-box{
    height: 100vh;
    width: 100%;
    background: #18181E;
    /* background: var(--app-bgColor);    */
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
}
.spinner {
  top: -10vh;
  width: 200px;
  height: 180px;
  position: relative;
  margin: 100px auto;
}
.spinner img{
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 60px;
  border: 3px solid #ffffff;
}
/* .double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  background-color: #00aaff;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.5s infinite ease-in-out;
  animation: sk-bounce 2.5s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.3s;
  animation-delay: -1.3s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.4) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }

} */

  .spinner .container {
    position: absolute;
    top: 120px;
    left: 100px;
    border-radius: 50% !important;
    height: 96px;
    width: 96px;
    animation: rotate_3922 1.2s linear infinite;
    background-color: #9b59b6;
    background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
  }

  .spinner .container span {
    position: absolute;
    border-radius: 50% !important;
    height: 100%;
    width: 100%;
    background-color: #9b59b6;
    background-image: linear-gradient(#9b59b6, #55aaff, #5ad1cd);
  }

  .spinner .container span:nth-of-type(1) {
    filter: blur(5px);
  }

  .spinner .container span:nth-of-type(2) {
    filter: blur(10px);
  }

  .spinner .container span:nth-of-type(3) {
    filter: blur(25px);
  }

  .spinner .container span:nth-of-type(4) {
    filter: blur(50px);
  }

  .spinner .container::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    border: solid 5px #ffffff;
    border-radius: 50% !important;
  }

  @keyframes rotate_3922 {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }


  .loader-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    border-radius: 50% !important;
    -webkit-animation: dot-pulse2 1.5s ease-in-out infinite;
    animation: dot-pulse2 1.5s ease-in-out infinite;
  }

  .dot-1 {
    background-color: #4285f4;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .dot-2 {
    background-color: #34a853;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .dot-3 {
    background-color: #fbbc05;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .dot-4 {
    background-color: #ea4335;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }

  .dot-5 {
    background-color: #4285f4;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }

  @keyframes dot-pulse2 {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      opacity: 0.5;
    }

    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      opacity: 0.5;
    }
  }

</style>