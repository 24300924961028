<template>
    <div>
        <div class="notice faguang" id="faguang">
            <div class="notice-gg">
                <div class="gg-tou">
                    <img src="../../../assets/logo/notice.png" alt="">
                    <span>公告</span>
                </div>
                <div class="gg-text">                      
                  <!-- <span>  后脱离文档流，不再进行不再.</span> -->
                    <div v-html="webSetting.webNotice"></div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    computed:{
      ...mapGetters([
        'webSetting'
      ]),
    },
    name:'WebNotice',
    data(){
      return{
        // webNotice:"<p>成品NAS虽好，可价格高，配置低，显然不符合我的需求。于是乎，作为一个垃圾佬，肯定要去看看二手设备了，最终发现二手企业服务器是个好选择。优点:价格低:准系统1000内搞定，商家帮你装好，回来只用装CPU，硬盘，内存就搞定了，不用担心装机麻烦。寿命长:企业设备用个10年左右都不一定出问题。而且可以用E5洋垃圾低价神U，还能搞双路快乐翻倍。关键是不用买寨板，大厂企业服务器主板靠谱多了。缺点:二手:</P>"
      }
    }
   
}
</script>

<style>
.notice-gg{
    border-radius: 15px;
    min-height: 0px;
    height: 200px;
    position: relative;
    /* width: 100%; */
    /* padding: 20px; */
    /* background-color:	#56ff13; */
    /* overflow: auto; */
}
.gg-tou{
    position: absolute;
    display: flex;
    top: 5px;
    left: 10px;
    /* background: #d37d7d; */
    /* text-align: center; */
}
.gg-tou img{
    /* padding: 20px; */
    height: 25px;
    width: 25px;
    animation: myfirst 5s infinite;	
}
.gg-tou span{
    padding-top: 2px;
    padding-left: 5px;
    /* font-size: 18px; */
    font-weight: 500;
}
@keyframes myfirst {
    0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }

}

.gg-text{
    position: absolute;
    height:140px;
    /* width: calc(100% - 60px); */
    /* padding: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    /* justify-content: center; */
    display: flex;
    top: 35px;
    left: 5px;
    /* left: 15px; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    /* color: rgb(255, 255, 255); */
}

</style>