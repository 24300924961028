import { render, staticRenderFns } from "./PersonManage.vue?vue&type=template&id=5e907231&scoped=true&"
import script from "./PersonManage.vue?vue&type=script&lang=js&"
export * from "./PersonManage.vue?vue&type=script&lang=js&"
import style0 from "./PersonManage.vue?vue&type=style&index=0&id=5e907231&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e907231",
  null
  
)

export default component.exports