<template>
     <div class="beijin" id="beijin">
        <canvas id="bg"></canvas>
      <!-- <canvas id="canvas" /> -->
    </div>
    
</template>

<script>
export default {
    name:"BeiJing",
    methods:{
        
    },
    mounted(){
		class StarrySky {
            constructor() {
                this.canvasElement = null;
                this.canvasContext = null;
                this.canvasWidth = 0;
                this.canvasHeight = 0;
                this.starList = [];
                this.starColorList = ["245,245,245", "255, 170, 255", "2,247,165","30,144,255"];
                this.starRadius = 1;
                this.focalDistanceLevel = 0.4;
                this.starCountLevel = 0.2;
                this.starSpeedLevel = 0.0005;
                this.focalDistance = 0;
                this.starCount = 0;
                this.rAF = null;
            }

            init(canvasElement) {
                if (!canvasElement || canvasElement.nodeName !== "CANVAS") {
                    console.error('Initialization failed, a Canvas element is required.');
                    return;
                }
                
                this.canvasElement = canvasElement;
                this.updateCanvasSize();
                // this.canvasElement.style.backgroundColor = "black";
                this.canvasContext = this.canvasElement.getContext("2d");

                this.focalDistance = this.canvasWidth * this.focalDistanceLevel;
                this.starCount = Math.ceil(this.canvasWidth * this.starCountLevel);
                this.starList = Array.from({ length: this.starCount }, () => this.createStar());

                window.addEventListener("resize", this.throttle(() => this.onResize(), 200));
            }

            updateCanvasSize() {
                this.canvasElement.width = this.canvasElement.clientWidth;
                this.canvasElement.height = this.canvasElement.clientHeight;
                this.canvasWidth = this.canvasElement.clientWidth;
                this.canvasHeight = this.canvasElement.clientHeight;
            }

            createStar() {
                return {
                    x: this.canvasWidth * (0.1 + 0.8 * Math.random()),
                    y: this.canvasHeight * (0.1 + 0.8 * Math.random()),
                    z: this.focalDistance * Math.random(),
                    color: this.starColorList[Math.floor(Math.random() * this.starColorList.length)]
                };
            }

            onResize() {
                this.updateCanvasSize();
                this.focalDistance = this.canvasWidth * this.focalDistanceLevel;
                const newStarCount = Math.ceil(this.canvasWidth * this.starCountLevel);
                this.adjustStarList(newStarCount);
                this.starCount = newStarCount;
            }

            adjustStarList(newStarCount) {
                if (this.starCount > newStarCount) {
                    this.starList.splice(newStarCount);
                } else {
                    this.starList.push(...Array.from({ length: newStarCount - this.starCount }, () => this.createStar()));
                }
            }

            setSkyColor(skyColor = "black") {
                this.canvasElement.style.backgroundColor = skyColor;
            }

            setStarRadius(starRadius = 1) {
                this.starRadius = starRadius;
            }

            setFocalDistanceLevel(focalDistanceLevel = 0.4) {
                this.focalDistanceLevel = focalDistanceLevel;
                this.focalDistance = this.canvasWidth * this.focalDistanceLevel;
            }

            setStarCountLevel(starCountLevel = 0.2) {
                this.starCountLevel = starCountLevel;
                this.onResize();
            }

            setStarSpeedLevel(starSpeedLevel = 0.0005) {
                this.starSpeedLevel = starSpeedLevel;
            }

            setStarColorList(color, immediate = false) {
                if (Array.isArray(color)) {
                    this.starColorList = color;
                } else if (typeof color === 'string') {
                    this.starColorList.push(color);
                }

                if (immediate) {
                    this.starList.forEach(star => {
                        star.color = this.starColorList[Math.floor(Math.random() * this.starColorList.length)];
                    });
                }
            }

            render() {
                const starSpeed = this.canvasWidth * this.focalDistanceLevel * this.starSpeedLevel;
                this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

                this.starList.forEach(star => {
                    const starX = (star.x - this.canvasWidth / 2) * (this.focalDistance / star.z) + this.canvasWidth / 2;
                    const starY = (star.y - this.canvasHeight / 2) * (this.focalDistance / star.z) + this.canvasHeight / 2;

                    star.z -= starSpeed;
                    if (star.z <= 0 || star.z > this.focalDistance || starX < -20 || starX > this.canvasWidth + 20 || starY < -20 || starY > this.canvasHeight + 20) {
                        Object.assign(star, this.createStar());
                    } else {
                        const starRadius = this.starRadius * (this.focalDistance / star.z * 0.8);
                        const starOpacity = 1 - 0.8 * (star.z / this.focalDistance);
                        this.canvasContext.fillStyle = `rgba(${star.color}, ${starOpacity})`;
                        this.canvasContext.shadowBlur = 10;
                        this.canvasContext.shadowColor = `rgb(${star.color})`;
                        this.canvasContext.beginPath();
                        this.canvasContext.arc(starX, starY, starRadius, 0, 2 * Math.PI);
                        this.canvasContext.fill();
                    }
                });

                this.rAF = requestAnimationFrame(() => this.render());
            }

            destroy() {
                cancelAnimationFrame(this.rAF);
                this.starList = [];
                this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
                this.canvasElement.width = 0;
                this.canvasElement.height = 0;
            }

            debounce(func, delay = 200) {
                let timeoutId;
                return (...args) => {
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => func.apply(this, args), delay);
                };
            }

            throttle(func, delay = 200) {
                let lastTime = 0;
                let timeoutId;

                return (...args) => {
                    const now = Date.now();

                    if (now - lastTime >= delay) {
                        clearTimeout(timeoutId);
                        lastTime = now;
                        func.apply(this, args);
                    } else {
                        timeoutId = setTimeout(() => {
                            lastTime = now;
                            func.apply(this, args);
                        }, delay - (now - lastTime));
                    }
                };
            }
        }

        const canvas = document.getElementById("bg");
        const starrySky = new StarrySky();
        starrySky.init(canvas);
        starrySky.render();
        starrySky.setStarSpeedLevel(0.0005);
    }
}
</script>

<style>
.beijin{
    position: fixed;
    z-index: -5;
    min-height: 100vh;
    width: 100%;
    /* background-color: aqua; */
    /* background: var(--app-bgColor);  */
    /* background-image: repeating-linear-gradient(90deg,var(--blogBgColorA),var(--blogBgColorA) 2px,var(--blogBgColorB) 2px,var(--blogBgColorB) 4px); */
    /* background-image: repeating-linear-gradient(90deg,#333333,#333333 2px,#272822 2px, #272822 4px); */
    /* background-image: url(../../assets/logo/bj1.jpg); */
    /* background: #000; */
    /* background: var(); */  
    background-position: center 0;
    background-size: cover;
    background-repeat: no-repeat;
}
.beijin::after{
	content: "";
	position: absolute;
	z-index: 1;  
	width:100%;
	height: 100%;
    background: var(--card-tm-bg); 
}
#bg {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 250px;
    height: 100%;
    position: fixed;
    z-index: 50;
}
</style>