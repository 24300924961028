<template>
    <div class="webinform faguang">
      <div>
          网站资讯
      </div>
         <div>
        </div>
    </div>
</template>

<script>
export default {
    name:'WebInforma'
}
</script>

<style>
.webinform{
    margin-top: 20px;
    border-bottom:1px solid #7474748e;
    height: 200px;
    width: 100%;
    /* background-color: rgb(255, 81, 0); */
}
</style>