<template>
    <div class="footer-my-Inf">
        <div class="my-touxinag">
            <img class="touxiang-img" :src="user.avatar" alt="">

               
            <!-- <img class="footer-my-logo" src="../../assets/logo/mylogo.png" alt=""> -->
              <div class="footer-my-logo">
                <div style="font-size: 25px;">像梦</div>
                <div style="font-size: 14px;">xiangmeng</div>
              </div>
           
        </div>
        
        <div class="my-shejiao">   
            <div>
            <div >
              <a href="https://github.com/ljf6522">
                <i class="iconfont icon-github" style="color: black;"></i>
              </a>
            </div>
            <div >
              <a href="https://gitee.com/dashboard/projects">
                <i class="iconfont icon-gitee-fill-round" style="color: #C71D23;"></i>
              </a> 
            </div>
            <div>
              <a href="">
                <i class="iconfont icon-bilibili" style="color: #FB7299;"></i>
              </a> 
            </div>
            <div >
              <a href="tencent://message/?uin=2415648320&Site=&Menu=yes">
                <i class="iconfont icon-QQ-circle-fill" style="color: deepskyblue;" title="点击添加我好友"></i>
              </a>
            </div>
            <div >
              <el-tooltip 
                class="item"
                effect="dark"                    
                popper-class="tip-class"
                placement="top">                  
                    <div slot="content" style="">
                       <img src="../../assets/image/Myweixin.png" alt="">
                    </div>           
                    <i class="iconfont icon-weixin" style="color:#2BA245;"></i>                       
              </el-tooltip> 
                <!-- <i class="iconfont icon-weixin" style="color:#2BA245;"></i> -->
            </div>
            <div >
              <el-tooltip 
                class="item"
                effect="dark"                    
                popper-class="tip-class"
                placement="top">                  
                    <div slot="content" style="font-weight: 600; font-size: 15px; color: #409EFF;">
                      我的邮箱：2415648320@qq.com
                    </div>           
                    <i class="iconfont icon-xiaoxi" style="color:rgb(228, 146, 52);"></i>                     
              </el-tooltip> 
                <!-- <i class="iconfont icon-xiaoxi" style="color:rgb(228, 146, 52);"></i> -->
            </div>
            </div>
        
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    computed:{
		...mapGetters([
			'user'
		]),
    },
    methods:{
    
    }
}
</script>

<style>
@import "@/assets/css/font2/font.css";

.blog-footer-one{
  display: flex;
  /* height: 100px; */
  width: 100%;
}
.footer-one-l{
  display: flex;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  /* width:  calc(35% - 20px); */
  width: 100%;
  margin: 0 10px;
  /* background-color: rgb(61, 61, 61); */
}
.footer-my-Inf{
  /* padding: 10px; */
  display: flex;
}
.footer-my-Inf .my-touxinag{
  display: flex;
  text-align: center;
  padding: 10px;


  /* background-color: #1f1f1f; */
}
.footer-my-Inf .my-touxinag .touxiang-img{
  transition: filter 375ms ease-in .2s,transform .3s;
  /* position: absolute; */
  left: 50%;
  object-fit: cover;
  /* z-index: 1; */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px #fff solid;
}
.footer-my-Inf .my-touxinag .touxiang-img:hover {
	/* box-shadow: 0 0 18px #000000; */
	animation: rotate 0.5s linear;	
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	25% {
		-webkit-transform: rotate(90deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
	}

	75% {
		-webkit-transform: rotate(270deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}		
}
.footer-my-Inf .footer-my-logo{
  background-repeat: no-repeat;
	background-size: cover!important;
  background-position: center!important;
  /* background-image: var(--logo-bgi); */
  font-family :  'MyFont2';
  /* margin-left: 20px; */
  color: var(--font-color); 
  /* height: 32px; */
  width: 62px;
  margin-top: 10px;
  margin-left: 5px;
}
.footer-my-Inf .my-shejiao{
  /* background-color: aqua; */
  /* display: grid; */
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: aqua; */
}
.footer-my-Inf .my-shejiao span{
  padding: 5px;
  /* background-color: aliceblue; */
  /* padding: 5px; */
  font-size: 18px;
  font-weight: 500;
  /* background-color: aqua; */
}
.footer-my-Inf .my-shejiao>div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 68px;
}
.footer-my-Inf .my-shejiao>div div{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 50% !important;
  margin: 3px;
  /* background-color: rgb(146, 146, 146); */
  /* padding: 5px; */
}
.footer-my-Inf .my-shejiao>div div:hover{
  /* background-color: var(--app-allColor); */
  cursor: pointer;
  background-color: rgba(172, 172, 172, 0.603);
}
.footer-my-Inf .my-shejiao>div div i{
  font-size: 20px;
}

</style>