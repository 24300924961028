<template>
    <div class="tool-box">
        <GongxiangTop :togonggongTop="{page_title:'工具箱',page_bg:webSetting.webToolbgi,msg:message}"></GongxiangTop>
        
        <div class="searchTool" id="searchTool">
            <div style="display:flex; position: relative;">
                <el-input 
                    size="mini"
                    v-model="search" 
                    placeholder="回车也可搜索"  
                    @keyup.enter.native="getSearchResult">
                </el-input>
                <button @click="getSearchResult">搜索</button>
            </div>
            <div  class="tool-result" v-show="show" @click.stop>
                <div v-if="resultData.length>0"  class="tool-result-zi" style="margin: 2px;">

                    <a v-for="(tool,index) in resultData" 
                    :key="index" 
                    target="_blank"
                    :href="tool.toolLink">
                        <el-tooltip 
                            class="item"
                            effect="dark"                    
                            popper-class="tip-class"
                            placement="left">
                            <div slot="content" style="width: 150px;">
                                {{ tool.toolIntroduce }}
                            </div>

                            <div style="display: flex;">
                                <img style="height: 28px;width:28px;margin:2px" :src="files.myblogImageUrl+tool.toolLogo" alt="">
        
                                <div style="color: var(--font-color);
                                font-szie:12px;
                                padding:2px;
                                margin:5px;
                                font-size: 12px;
                                line-height: 20px;
                                font-weight: 400; 
                                position: relative;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                letter-spacing: 1px;
                                -webkit-line-clamp:1;
                                overflow: hidden;">
                                {{ tool.toolName }}
                                </div>
                            </div>
                        </el-tooltip>
                    </a>
                </div>
                <loading-two v-else-if="isLoading"></loading-two>
                <is-null style="" v-else></is-null>
            </div>
            
        </div>

        <div v-if="toolData.length>0" class="tool-box-body">
            <fieldset v-for="(ts,i) in toolData" :key="i">            
                <legend>{{ ts.typeName }}</legend>
                <a  class="tool-box-zi" v-for="(t,index) in ts.tools" 
                    :key="index"
                    target="_blank"
                    :href="t.toolLink">                    
                    <div class="tool-box-zi-left">
                        <img :src="files.myblogImageUrl+t.toolLogo" > 
                    </div>
                    <div class="tool-box-zi-right">
                        <div class="toolbox-zi-righttop">
                            {{ t.toolName }}
                        </div>

                        <div class="toolbox-zi-xuancai"></div>

                        <el-tooltip 
                        class="item"
                        effect="dark"                    
                        popper-class="tip-class"
                        placement="bottom">
                            <div slot="content" style="width: 200px;">
                                {{ t.toolIntroduce }}
                            </div>

                            <div class="" style="
                                padding-top: 5px;
                                padding-left:5px;
                                color: var(--font-1-color);
                                font-size: 13px;
                                font-weight: 400; 
                                position: relative;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                letter-spacing: 1px;
                                -webkit-line-clamp:1;
                                overflow: hidden;">
                                {{ t.toolIntroduce }}
                                <!-- 本例演示如何创建文本域（多行文本输入控件）。用户可在文本域中写入文本。可写入字符的字数不受限制。 -->
                            </div>
                        </el-tooltip>
                    </div>                       
                </a>
            </fieldset>           
        </div>
        <loading-two v-else-if="isLoading"></loading-two>
        <is-null v-else></is-null>
    </div>
</template>

<script>
import { getToolList, getToolsBySearch } from '@/api/tool';
import GongxiangTop from './gonxiangtop/GongxiangTop.vue';
import IsNull from '@/pages/errorOrNull/IsNull.vue';
import LoadingTwo from '@/pages/errorOrNull/LoadingTwo.vue';
import { mapState } from "vuex";
import {mapGetters} from 'vuex';

export default {
    name: "ToolBox",
    computed:{
        ...mapGetters([
            'webSetting',
        ]),
        ...mapState(["isLoading"])
    },
    components: { GongxiangTop, IsNull, LoadingTwo },
    data(){
        return{
            message:'以下这些网站均是引用第三方的，仅是为了需要用到时可以方便取用，无其他恶意。另外，如有侵犯，可联系我删除。',
            search:'',
            toolData:'',
            show:false,

            resultData:''
        }
    },
    methods:{
        getSearchResult(){
            if(!this.search){
                return this.$message({
                    message:"不能为空",
                    type: 'warning',
                    duration:1000,
                }); 
            }
            this.show=true;

            getToolsBySearch(this.search).then((res)=>{
                console.log(res)
                this.resultData=res.data
            })
        },
        getList(){
            getToolList().then((res)=>{
                this.toolData=res.data
            })
        },

    },
    mounted(){
        //点击任意位置关闭区域弹窗
        document.addEventListener('click',(e) => {
        //获取弹窗对象
            const userCon = document.getElementById('searchTool');

            //判断弹窗对象中是否包含点击对象
            if(userCon && !userCon.contains(e.target)) {
                //如果包含则跳转回之前的页面
                this.show = false;
            }
    
        })

    },
    created(){
        this.getList()
    },
    
    beforeDestroy() {},
    }
</script>

<style>
.searchTool{
    right: 20px;
    position: absolute;
    border-radius: 5px !important;
	border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.195);
    position: absolute;
    z-index: 1;
    top: 440px;
    transition: .3s;
    right:  20px;
    width: 200px;
    /* height: 50px; */
    /* width: 60px; */
    border-radius: 5px;
    /* background-color: rgba(54, 53, 53, 0.411); */
	background:var(--card-tm-bg);
    backdrop-filter: blur(10px);
    padding:5px;
}
.searchTool input{
    border-radius: 0;
    color: var(--font-color);
    background: hsla(0, 0%, 0%, 0);
    border-right: none;
    border-left: none;
    border-top: none;

}
.searchTool button{
    position: absolute;
    background: hsla(0, 0%, 0%, 0);
    color: var(--font-color);
    border-right: none;
    border-bottom: none;
    border-top: none;
    border-left: 1px solid #DCDFE6;
    right: 0px;
    font-size: 11px;
    border-radius: 0;
    padding: 5px;
    margin: 2px;
    transition: .3s;
    cursor: pointer;
}
.searchTool button:hover{
    transition: .3s;
    background: hsla(0, 0%, 0%, 0);
    color: var(--app-allColor);
}

.searchTool .tool-result{
    transition: .3s;
    max-height: 250px;
    overflow: auto;
    width: 100%;
    background: #5a5a5a92;
}

.tool-box{
    position: relative;
	width: 100%;
    padding-bottom: 20px;
}
.tool-box-body fieldset{
    color: var(--font-color);
    margin:  10px;
    /* height: 200px; */
    /* width: calc(100% -20px); */
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #7474748e;
}
.tool-box-body fieldset legend{
    padding:5px 10px;
    border-radius: 5px;
	background: var(--card-tm-bg);
    border: 1px solid  #7474748e;
}
.tool-box-zi{
    position: relative;
    box-shadow: 0 1px 5px var(--text-shadow); 
    transition: .3s;
	border:1px solid #ffffff00;
    color: var(--font-color);
    /* height: 50px; */
    margin: 10px;
    /* box-shadow: 0 1px 5px rgb(180 195 255 / 30%); */
	background: var(--card-tm-bg);
	width:  calc(100% / 4 - 22px);
    display: flex;
    transition: .3s;
}
.tool-box-zi-left{
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.tool-box-zi-left img{
    height: 45px;
    width: 45px;
	transition: 0.3s;
}
.tool-box-zi-right{
    width: calc(100% - 80px);
    padding: 0 10px 0 0;
    /* background-color: rgba(149, 153, 153, 0); */
}
.toolbox-zi-righttop{
    font-size: 14px;
    font-weight: normal;
    padding-left:5px;
    margin-top: 10px;
    letter-spacing: 0.5px;
    padding-bottom: 5px;
    border-bottom: 1px solid  #7474748e;
    line-height: 23px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    letter-spacing: 1px;
    -webkit-line-clamp:1;
    overflow: hidden;
}
.toolbox-zi-xuancai{
    margin-top: -3px;
    position: relative;
    height: 5px;
}
.toolbox-zi-xuancai::before {
    content: '';
    /* width: 0; */
    width: 20%;
    background: var(--app-allColor);
    height: 5px;
    border-radius: 30em;
    position: absolute;
    top: 0;
    left: 0;   
    /* background-image: linear-gradient(to right, #0061f2 0%, #aaa9a4 100%);		 */
    transition: .5s ease;
    display: block;
    z-index: 0;
}
.tool-box-zi-right:hover .toolbox-zi-xuancai::before {
    width: 70%;
}
.tool-box-zi:hover{
    transition: .3s;
    box-shadow: 0 2px 10px rgb(180 195 255 / 50%);
	border:1px solid var(--app-allColor);
}
.tool-box-zi .tool-box-zi-left img:hover {
	transition: 0.3s;
	transform: scale(1.1);
}
@media (max-width:1000px) {		
	.tool-box-zi{
        transition: .3s;
		width:  calc(100% /3  - 22px);
	}
}
@media (max-width:750px) {		
	.tool-box-zi{
        transition: .3s;
		width:  calc(100% /2  - 22px);
	}
}
@media (max-width:500px) {		
	.tool-box-zi{
        transition: .3s;
		width: 100%;
	}
}

@media (max-width:880px) {	
    .searchTool{
        top: 290px;
    }
}
@media (max-width:500px) {	
    .searchTool{
        top: 220px;
    }
}
</style>