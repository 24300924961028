import request from "@/utils/requests";
export const getToolList=() =>request(
    {url:`/tp/blogTList`,
    method:'get',
    // data:param
})

export const getToolsBySearch=(search) =>request(
    {url:`/tool/tools?search=${search}`,
    method:'get',
    // data:param
})