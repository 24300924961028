<template>
    <div class="home-biao-qian faguang" id="faguang">
        <div class="nc-top" style="padding: 10px;">
            文章标签
        </div>
        <div style="display: flex; justify-content: center;align-items: center;text-align: center;">
            <svg  :width="width" :height="height" >
                <a class="fontA" v-for="(tag, index) in tags" :key="`tag-${index}`" :style="{fill:`${tag.tagColor}`}">
                    <text :id="tag.id" :x="tag.x" :y="tag.y"
                        :font-size="13 + tag.articleLang/5" :fill-opacity="(400 + tag.z) / 600"
                        @mousemove="listenerMove($event)" @mouseout="listenerOut($event)" @click="clickToPage(tag.tagName)">
                        #{{ tag.tagName }}
                    </text>
                </a>
            </svg>
        </div>

        <div class="tagmore" style="" @click="toTagList">
            更多标签<i class="el-icon-d-arrow-right"></i>
        </div>
        

    </div>
</template>

<script>
import { getTagAll } from '@/api/tagAndCategory';

export default {
    name:"HomeBiaoQian",

    data(){
        return{
            width: 260, //svg宽度
            height: 250, //svg高度
            tagsNum: 20, //标签数量
            RADIUS: 90, //球的半径
            speedX: Math.PI / 360 / 1.5, //球一帧绕x轴旋转的角度
            speedY: Math.PI / 360 / 1.5, //球-帧绕y轴旋转的角度
            tags: [],
            data: [
            // {
            // name:"金晨",
            // yuedu:4
            // },
            // {
            // name:"Li晨",
            // yuedu:8
            // },
            // {
            // name:"我擦",
            // yuedu:3
            // },
            // {
            // name:"你好",
            // yuedu:5
            // },
            // {
            // name:"傻逼",
            // yuedu:3
            // },
            // {
            // name:"儿子",
            // yuedu:5
            // },
            // {
            // name:"嘿嘿",
            // yuedu:9
            // },
            // {
            // name:"保护",
            // yuedu:5
            // },
            // {
            // name:"很好",
            // yuedu:3
            // },
            ]
        }
    },
    computed: {
		CX() {
			//球心x坐标
			return this.width / 2.5;
		},
		CY() {
			//球心y坐标
			return this.height / 2;
		},
	},
    methods:{
        // 初始化数据
	    initData() {
			//初始化标签位置
			let tags = [];
            getTagAll('').then(res=>{
                // this.tagsNum = res.data.length
                for (let i = 0; i<res.data.length&&i <= this.tagsNum; i++) {
                    let tag =  res.data[i];
                    let k = -1 + (2 * (i + 1) - 1) / res.data.length;
                    let a = Math.acos(k);
                    let b = a * Math.sqrt(res.data.length * Math.PI); //计算标签相对于球心的角度  

                    // console.log(tag)
                    // console.log(k,"\n",a,"\n",b)              
                    // tag.text = '#' + res.data[i].tagName;
                    tag.x = this.CX + this.RADIUS * Math.sin(a) * Math.cos(b); //根据标签角度求出标签的x,y,z坐标
                    tag.y = this.CY + this.RADIUS * Math.sin(a) * Math.sin(b);
                    tag.z = this.RADIUS * Math.cos(a);
                    // tag.id=res.data[i].id;
                    // tag.yuedu = res.data[i].articleLang;
                    // tag.tColor = res.data[i].tagColor;
                    // tag=res.data[i]
                    tags.push(tag);
                    // console.log(tag);
                }
                this.tags = tags; //让vue替我们完成视图更新
            })
			// this.tagsNum = res.data.length;
			// for (let i = 0; i < this.tagsNum; i++) {
            //     console.log("哈哈",this.data[i].tagName)
			// 	let tag = {};
			// 	let k = -1 + (2 * (i + 1) - 1) / this.tagsNum;
			// 	let a = Math.acos(k);
			// 	let b = a * Math.sqrt(this.tagsNum * Math.PI); //计算标签相对于球心的角度                
			// 	tag.text = '#' + this.data[i].tagName;
			// 	tag.x = this.CX + this.RADIUS * Math.sin(a) * Math.cos(b); //根据标签角度求出标签的x,y,z坐标
			// 	tag.y = this.CY + this.RADIUS * Math.sin(a) * Math.sin(b);
			// 	tag.z = this.RADIUS * Math.cos(a);
			// 	tag.id = i; // 给标签添加id
			// 	tag.yuedu = this.data[i].articleLang;
			// 	tags.push(tag);
			// 	// console.log(tag);
			// }
			// this.tags = tags; //让vue替我们完成视图更新
		},
		// 纵向旋转
		rotateX(angleX) {
			var cos = Math.cos(angleX);
			var sin = Math.sin(angleX);
			for (let tag of this.tags) {
				var y1 = (tag.y - this.CY) * cos - tag.z * sin + this.CY;
				var z1 = tag.z * cos + (tag.y - this.CY) * sin;
				tag.y = y1;
				tag.z = z1;
			}
		},
		// 横向旋转
		rotateY(angleY) {
			var cos = Math.cos(angleY);
			var sin = Math.sin(angleY);
			for (let tag of this.tags) {
				var x1 = (tag.x - this.CX) * cos - tag.z * sin + this.CX;
				var z1 = tag.z * cos + (tag.x - this.CX) * sin;
				tag.x = x1;
				tag.z = z1;
			}
		},
		// 运动函数
		runTags() {
			if (typeof this.timer === "number") {
				clearInterval(this.timer);
				this.timer = null;
			}
			let timer = setInterval(() => {
				this.rotateX(this.speedX);
				this.rotateY(this.speedY);
			}, 17);
			this.timer = timer;
		},
		// 监听移入事件
		listenerMove(e) {
			if (e.target.id) {
				clearInterval(this.timer);
			}
		},
		// 监听移出事件
		listenerOut(e) {
			if (e.target.id) {
				this.runTags();
			}
		},
		// 点击事件
		clickToPage(tN) {
            this.$router.push({
                path:'/tag',
                query:{
                    search:tN,
                    t:Date.now()
                }, 
            })
        },


        toTagList(){
            this.$router.push({
                path:'/tag',
                query:{
                    // search:tN,
                    t:Date.now()
                }, 
            })
        }
    },
    mounted() {
        this.runTags();
    },

    created() {
		this.initData();
	},
}
</script>

<style>
.home-biao-qian{
    margin-top: 20px;
    width: 100%;
    border-bottom:1px solid #7474748e;
}


.fontA text{
    font-weight: 600;
}

.fontA:hover {
    fill: #ffffff;
    cursor: pointer;
}
.tagmore{
    margin: 10px;
    position: absolute;
    font-weight: 400;
    font-size: 13px;
    top: 0;
    right: 0;
    transition: .3s;
    /* text-decoration:underline; */
    border-bottom: 0.5px solid var(--font-color);
    cursor: pointer;
}
.tagmore:hover{
    transition: .3s;
    color: var(--app-allColor);
    border-bottom: 0.5px solid var(--app-allColor);

}
</style>