<template>
    <div class="bianlitieList">
        <div class="wall-height faguang" id="faguang">     
            <div class="note-one"  
            v-for="(item,index) in parentdata" 
            :key="index" 
            :style="{backgroundColor :`${item.bgcolor}`}">
        
                <div class="note-top">
                    <div class="nt-zuo">
                        <div class="zuo-bt">
                            <!-- <span>{{ item.note_title }}</span> -->
                            <!-- <img src="../../../assets//logo/cz.png" alt=""> -->
                            <img :src="'https://q1.qlogo.cn/headimg_dl?dst_uin='+item.messageQq+'&spec=100'" alt="">
                        </div>
                        <div class="zuo-sj">
                            <div>
                                <span>{{item.createTime}}</span>
                            </div>
                            <div>
                                <span >ip:</span><span> {{ item.ipSource }} </span>
                            </div>
                        </div>
                    </div>
                    <div style="flex: 1;"></div>                 
                    <div class="nt-you">
                        <!-- <div class="you-one">
                            <span title="编辑">
                                <i class="iconfont icon-yongyan"></i>
                            </span>
                            <span title="删除">
                                <i class="iconfont icon-shanchusekuai"></i>
                            </span>                     
                        </div>
                        <div class="you-two">
                            <span> ip：广东</span>
                        </div> -->
                    </div>
                </div>
                <div class="note-text">
                    <!-- <div class="text-zi"  :style="{color :`${'#2b2b2b'}`,}"> -->
                    <div class="text-zi" >
                      <span> {{ item.messageContent }}</span>
                    </div>
                </div>
                <div class="note_footer">
                    <span>—— {{ item.nickname }}</span>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name:'BianLiTie',
    props:{
        parentdata:{
            type:[],
            default:""
        }
    },
    methods:{

    },
    mounted(){
        // console.log("父传：",this.parentdata)
    }

}
</script>

<style>
.bianlitieList{
    padding: 15px 0;
}
.wall-height{
    /* margin: 20px; */
    padding: 10px 10px 20px 10px;
    /* align-items: flex-start; */
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;  
    /* background-color: #fff; */
}
.note-one{
    /* font-family:"kaiti"; */
    margin: 0px;
    min-width: 100px;
    max-width: 290px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);  
    letter-spacing: 1px;
    line-height: 20px;
    /* border: 1px solid #bfbfbf; */
    color: #636363;
    text-decoration: none;
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    -ms-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

/* .wall-height .note-one{
    min-width: 100px;
    max-width: 300px;
	position:relative;
    -webkit-border-radius: 5px 30px  10px  30px/80px  1px  80px 5px;
	-moz-border-radius: 5px 30px  10px  30px/80px  1px  80px 5px;
	border-radius:0px 30px  10px  30px/80px  1px  60px 5px;
	-webkit-box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.205) inset;
	-moz-box-shadow: 0px 0px 35px rgba(43, 43, 43, 0.144) inset;
	box-shadow: 0px 0px 35px rgba(32, 32, 32, 0.37) inset;
} */

/* 
 * 旋转
 */
 .note-one:nth-child(even) {
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
}
.note-one:nth-child(odd) {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    transform: rotate(-2deg);
}
.note-one :nth-child(3n) {
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    -o-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    transform: rotate(1deg);
}

/* 
转换演示
 使用过渡（上面定义）来缓解。
*/


.note-one:hover{
    transition: .3s;
    -webkit-box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.301);
    -moz-box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.288);
    -o-box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.253);
    -webkit-transform: rotate(0deg) scale(1.1);
    -moz-transform: rotate(0deg) scale(1.1);
    -o-transform: rotate(0deg) scale(1.1);
    -ms-transform: rotate(0deg) scale(1.1);
    transform: rotate(0deg) scale(1.1);
    z-index: 10;
}

.note-top{
    height: 50px;
    display: flex;
    /* background-color: #df0e0e; */
}
.note-top .nt-zuo{
    display: flex;
}
.note-top .nt-zuo .zuo-bt {
    padding: 5px;
    /* border-bottom: 2px solid rgb(88, 88, 88); */
}
.note-top .nt-zuo .zuo-bt img{
    height: 35px;
    width: 35px;
    border: 1px solid rgb(110, 110, 110);
    /* padding-bottom: 5px; */
    border-radius: 5px;
}
.note-top .nt-zuo .zuo-sj{
    color: #747575;
    margin-top: 5px;
    padding-right: 10px;
    margin-left: 10px;
    font-size: 10px;
    /* background-color: antiquewhite; */
}
.note-top .nt-zuo .zuo-sj>div:last-child{
    /* margin-top: 5px; */
    font-size: 10px;
    font-weight: 300;
}


.note-one  .nt-you .you-one span{
    cursor: pointer;
    margin: 0 7px;
}
.note-one  .nt-you .you-one span i{
    color: rgb(34, 34, 34);
    font-size: 1.1rem;
}
.note-top .nt-you .you-two span{
    padding-left: 10px;
    font-size: 5px;
    font-weight: 100;
    padding-right: 10px;
}

/* .note-one .nt-you .you-bj{
   
}
.note-one .nt-you .you-sc{

} */

.note-text{ 
    width: 100%;
    max-height: 150px;
    min-height: 60px;
    /* height: 25px; */
    /* background-color: rgba(197, 197, 197, 0.082); */
    overflow-y: auto;
}
 /* 滚动条样式 */
.note-text::-webkit-scrollbar{
    /*width: 100px !important;*/
    width: 3px;
    /* border-radius: 5px; */
    background-color: rgba(43, 43, 43, 0.747); 
}
.note-text::-webkit-scrollbar-thumb{/*滚动条里面小方块*/
    border-radius: 5px;
    background-color: rgba(250, 250, 250, 0.829);
}	
.note-text .text-zi {
    margin:0 10px 10px 10px;
    color: #333333;
    font-size: 12px; 
    font-weight: 500;
}
.note-one .note_footer{
    height: 20px;
    width: 100%;
    /* background-color: #bfbfbf69; */
}
.note-one .note_footer span{
    padding-right: 10px;
    font-size: 10px;
    /* font-weight: 100; */
    float: right;
    /* background-color: #bfbfbf69; */
}

@media (max-width:880px) {	
    .bianlitieList{
        padding-left: 0;
        padding-right: 0;
        margin:0 10px;
    }
}
</style>