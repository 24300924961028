<template>
    <div>
        <div class="menu-icon">          
            <span class="el-dropdown-link"  @click="drawer = true">  
                <!-- <img class="tubiao" src="../../assets/logo/栏目.png" alt=""> -->
                <i   class="iconfont icon-liebiao"></i> 
            </span>             
        </div>

        <el-drawer  
            :visible.sync="drawer"
            direction="ltr"
            :append-to-body="true"
            :with-header="false">
                <div class="chouti">
                    <div class="ct-zon">
                        <div class="ct-header">
                            <div>
                                <img :src="user.avatar">
                            </div>
                            <div style="font-size: 20px;color: var(--font-color); font-weight: 600;margin-top: 10px;">
                                {{ user.nickname }}
                            </div>
                            

                            <div class="ct-wenz">
                                <div class="h-wz" @click="$router.push({ path:'/category'}),drawer=false">
                                    分类<sup>{{num.c}}</sup>
                                </div>
                                <el-divider direction="vertical"></el-divider>
                                <div class="h-wz"  @click="$router.push({ path:'/tag'}),drawer=false">
                                    标签<sup>{{num.t}}</sup>
                                </div>
                                <el-divider direction="vertical"></el-divider>
                                <div class="h-wz"  @click="$router.push({ path:'/pictures'}),drawer=false" style="border: none;">
                                    相册<sup>{{num.xc}}</sup>
                                </div>
                            </div>

                        </div>
                        <!-- <el-divider></el-divider>                  -->
                        <div class="ct-lanmu">
                            <transition v-for="(ro,idex) in arr" :key="idex">                              
                                <el-collapse accordion>
                                    <div v-if="!ro.roList" class="ct-lanmu-zi">
                                        <button class="ct-lanmu-but" @click="topath(ro.route)">
                                            <span>
                                                <i :class="ro.ro_icon"> {{ ro.ro_name }}</i>                                              
                                            </span>                           
                                        </button>                     
                                    </div>

                                    <el-collapse-item v-else>
                                        <template slot="title">
                                            <i :class="ro.ro_icon"> {{ ro.ro_name }}</i>
                                        </template>
                                        <div class="ct-lanmu-erji">
                                            <div class="ct-lanmu-zi"  v-for="(erj,idex) in ro.roList" :key="idex">
                                                <button class="ct-lanmu-but"  @click="topath(erj.route)">
                                                    <span style="padding-left: 20px;">
                                                        <i :class="erj.ro_icon" style="font-size: 15px;"> {{ erj.ro_name }}</i>                                  
                                                    </span>                           
                                                </button>      
                                            </div>
                                        </div>                           
                                    </el-collapse-item>
                                </el-collapse>

                            </transition>
                            
                        </div> 

                        <div class="ct-footer">
                            <div class="bloom-container">
                                <div class="button-container-main">
                                    <div class="button-inner">
                                        <div class="back"></div>
                                        <div class="front">
                                            <i class="iconfont icon-chuizi"></i>
                                        </div>
                                    </div>
                                    <div class="button-glass">
                                        <div class="back"></div>
                                        <div class="front"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- <i class="iconfont icon-chuizi"></i> -->
                        </div>
                    </div>          
                </div>
        </el-drawer>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { blogIndex } from '../../../api/webSetting';

export default {
    name:'LeftChouTi',
    computed:{
		...mapGetters([
			'user'
		]),
    },
    data() {
    return {    
        arr:  [{
          ro_id: "0",
          ro_name: "首页",
          route:'/',
          ro_icon:"iconfont icon-icon1",
          roList:''
        },
        {
          ro_id: "1",
          ro_name: "文章",
          route:'/',
          ro_icon:"iconfont icon-shuben",
          roList:[
          {
                ro_id: "11",
                ro_name: "分类",
                route:'/category',
                ro_icon:"iconfont icon-shujutongjifenxibingtu"
            },
            {
                ro_id: "12",
                ro_name: "标签",
                route:'/tag',
                ro_icon:"iconfont icon-jiagebiaoqian"
            }
            ]
        },
        {
          ro_id: "2",
          ro_name: "动态",
          route:'/',
          ro_icon:"iconfont icon-xiuxiankafeiyule",
          roList:[
            {
                ro_id: "13",
                ro_name: "相册",
                route:'/pictures',
                ro_icon:"iconfont icon-xiangjisheying"
            },
            {
                ro_id: "14",
                ro_name: "随笔",
                route:'/suibi',
                ro_icon:"iconfont icon-dati"
            }
            ]
        },
        // {
        //   ro_id: "2",
        //   ro_name: "友链",
        //   route:'/friendLink',
        //   ro_icon:"iconfont icon-lianjie3",               
        // },
        {
          ro_id: "3",
          ro_name: "留言",
          route:'/liuyan',
          ro_icon:"iconfont icon-benzi",
          roList:''
        },

        // {               
        //     ro_id: "13",
        //     ro_name: "相册",
        //     route:'/pictures',
        //     ro_icon:"iconfont icon-xiangjisheying"
        // },
        // {
        //     ro_id: "5",
        //     route:'/suibi',
        //     ro_name: "随笔",
        //     ro_icon:"iconfont icon-dati",
        //     roList:''
        // },
        {           
            ro_id: "7",
            route:'/toolbox',
            ro_name: "工具",
            ro_icon:"iconfont icon-shangwugongwenbao",
            roList:''
        },
        // {
        //     ro_id:'6',
        //     route:'/person',
        //     ro_name:'个人',
        //     ro_icon:'iconfont icon-iconfuzhi'
        // }
    ],
        findIndex:0,
        drawer: false, 
        winWidth:document.body.clientWidth,
        num:''
        };
        
    },
    methods:{
        topath(val){
            setTimeout(() => {
                this.$router.push({
                            path:val
                        })
                // console.log("idx:",val)
            },200)
            this.drawer=false
        },
        getGeSum(){
			blogIndex().then((res)=>{
				this.num=res.data
			})
        }
    },
    mounted(){
        window.onresize = () => {
            return (() => {
                this.winWidth=document.body.clientWidth
                
                if(this.winWidth>=880){
                    this.drawer=false
                }
                // console.log("宽度",this.winWidth)
            })();
        };
        this.getGeSum()
    },
    destroyed() {
		// 组件销毁后解绑事件
		window.onresize = null;
	}
}
</script>

<style>
.chouti{
    width: 100%;
    height: 100%;
    justify-content: center;
    /* align-items: center; */
    /* background-color: #0c9eff; */
    /* background-color:  var(--bgijianbianA); */
    background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color:	#fafafa;
	background-image:url(../../../assets/blr/blrcm.png);
    color: var(--font-color);
}
.ct-zon{
    position: relative;
    height: 100%;
    background-color: var(--bgijianbianD);
    /* background-color:var(--bgijianbianD); */
    /* background-color: #0c9eff; */
	/* background-image: linear-gradient(0deg, var(--bgijianbianA) 0%,var(--bgijianbianB) 27%, var(--bgijianbianC) 42%,var(--bgijianbianE) 93%); */
}
.ct-header{
	/* background-image: linear-gradient(0deg, var(--bgijianbianA) 0%,var(--bgijianbianB) 27%, var(--bgijianbianC) 42%,var(--bgijianbianE) 93%); */
    padding-top: 30px;
    /* padding-bottom: 20px; */
    width:100%;
    /* background-color:var(--bgijianbianA); */
    text-align: center;
    border-bottom: 1px solid  var(--font-color);
    background:var(--app-bgColor);
}
.ct-header img{
    /* margin-top: 50px; */
    height: 90px;
    width: 90px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid rgb(245, 245, 245);
	/* border: 1px solid beige; */
    /* background-color: #0c9eff; */
}

.ct-wenz{
    /* height: 60px; */
    background-color: var(--bgijianbianE);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.chouti .el-divider{
	background-color: var(--font-color);
}
.ct-lanmu i{
    color: var(--font-color);
    /* color: #4c4948; */
}
.ct-lanmu{
    padding-top: 10px;
    padding-bottom: 10px;
    /* height: 150px; */
    width:100%;
    /* background-color: var(--bgijianbianE); */
}
.ct-lanmu .el-collapse{
    border: none;
}
.ct-lanmu .el-collapse-item__header {
    padding-left: 30px;
    height: 40px;
    background-color: #00000000;
    border: none;
    line-height: 0;
    font-weight: 400;
}

.ct-lanmu .el-collapse-item__content {
    background-color:rgba(163, 163, 163, 0);
    /* padding: 10px; */
    padding: 0;
    /* background-color: #0c9eff; */
}
.ct-lanmu .el-collapse-item__wrap {
    background-color:rgba(163, 163, 163, 0);
}
.ct-lanmu .ct-lanmu-zi{
    border: none;
    width: 100%;
    height: 100%; 
    background-color:rgba(163, 163, 163, 0);
}
.ct-lanmu-but{
    height: 100%;
    width: 100%;
    padding: 10px;
    /* position: relative; */
    border: none;
    background-color: #00000000;
}
.ct-lanmu-but span{
    /* position: absolute; */
    float: left;
    margin-left: 20px;
}
.ct-lanmu .el-collapse:hover{
    background-color: #00000000;
}
.ct-lanmu .el-collapse-item__header:hover,
.ct-lanmu-but:hover{
    cursor: pointer;
    background-color:rgba(151, 151, 151, 0.425);
}

.ct-footer{
    position: absolute;
    height: 100px;
    width: 100%;
    bottom: 0;
}

.ct-footer i{
    font-size:50px;
    color: #AA2126;
    background: #fff;
    border-radius: 50% !important;
    float: right;
    padding: 0;
}

.bloom-container {
    top: 10px;
    left: 10px;
    position: relative;
    transition: all 0.2s ease-in-out;
    border: none;
    background: none;
    height: 50px;
    width: 50px;
}

.bloom-container .button-container-main {
    /* height: 100px; */
    width: 65px;
    aspect-ratio: 1;
    border-radius: 50% !important;
    overflow: hidden;
    position: relative;
    display: grid;
    place-content: center;
    border-right: 5px solid white;
    border-left: 5px solid rgba(128, 128, 128, 0.147);
    transform: rotate(-45deg);
    transition: all 0.5s ease-in-out;
}

.bloom-container .button-container-main .button-inner {
    height: 60px;
    aspect-ratio: 1;
    border-radius: 50% !important;
    position: relative;
    box-shadow: rgba(100, 100, 111, 0.5) -10px 5px 10px 0px;
    transition: all 0.5s ease-in-out;
}

.bloom-container .button-container-main .button-inner .back {
    position: absolute;
    inset: 0;
    border-radius: 50% !important;
    background: linear-gradient(60deg, rgb(170, 0, 0) 0%, rgb(170, 0, 0) 100%);
}

.bloom-container .button-container-main .button-inner .front {
    position: absolute;
    inset: 5px;
    border-radius: 50% !important;
    background: linear-gradient(60deg, rgb(170, 0, 0) 0%, rgb(170, 0, 0) 100%);
    display: grid;
    place-content: center;
}

.bloom-container .button-container-main .button-glass {
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.888) 100%);
    transform: translate(0%, -50%) rotate(0deg);
    transform-origin: bottom center;
    transition: all 0.5s ease-in-out;
}

.bloom-container .bloom {
    height: 1px;
    width: 1px;
    position: absolute;
    background: white;
}

.bloom-container .bloom1 {
    top: 10px;
    right: 20px;
    box-shadow: rgb(255, 255, 255) 0px 0px 10px 10px, rgb(255, 255, 255) 0px 0px 20px 20px;
}

.bloom-container .bloom2 {
    bottom: 10px;
    left: 20px;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 10px 10px, rgba(255, 255, 255, 0.5) 0px 0px 30px 20px;
}

.bloom-container:hover {
    transform: scale(1.1);
}

.bloom-container:hover .button-container-main .button-glass {
    transform: translate(0%, -40%);
}

.bloom-container:hover .button-container-main .button-inner .front .svg {
    opacity: 1;
    filter: drop-shadow(0 0 10px white);
}

.bloom-container:active {
    transform: scale(0.7);
}

.bloom-container:active .button-container-main .button-inner {
    transform: scale(1.2);
}
</style>